import { useState, useEffect } from 'react'
import cx from 'classnames'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
// components
import { AdminCallInfo, AdminAddCallInfoForm } from 'features/users/components'
import { Loading } from 'components'
// styles and assets
import styles from './AdminCallInfoGroup.module.scss'
// types and utils
import { UsedValuesEnum } from 'utils'
import { IUpdateCallInfo, IUpdateCallInfoTypes } from 'typings'
// state
import { useAppDispatch } from 'app/store'
import { setProfile } from 'features/users'
// services
import { updateMasterProfileApi } from 'features/users'

interface IProps {
  title: string
  groupName: string
  fields: string[]
  alreadyUsedValues: {
    [kind in UsedValuesEnum]: string[]
  }
}

const AdminCallInfoGroup = ({ title, fields, groupName, alreadyUsedValues }: IProps) => {
  // props and utils
  const dispatch = useAppDispatch()

  // local state
  const [currentFields, setCurrentFields] = useState(fields)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<false | string>(false)

  // effects
  useEffect(() => {
    if (fields !== currentFields) {
      setCurrentFields(fields)
    }
  }, [fields])

  // handlers
  const onDragEnd = async (result: any) => {
    // early return
    if (!result.destination || result.destination.index === result.source.index) return

    const prevItems = [...currentFields]
    const items = [...currentFields]
    const [reorderedItem] = items.splice(result.source.index, 1)
    items.splice(result.destination.index, 0, reorderedItem)

    const updateCallInfo: IUpdateCallInfo = {
      types: [IUpdateCallInfoTypes.reorderProps],
      path: groupName,
      updates: {
        order: items,
      },
    }

    setCurrentFields(items)
    setLoading(true)

    try {
      const updatedProfile = await updateMasterProfileApi({ updateCallInfo })
      dispatch(setProfile(updatedProfile))
    } catch (error: any) {
      setCurrentFields(prevItems)
      setError(error?.response?.data ? error.response.data : error.message)
    }
    setLoading(false)
  }

  return (
    <div className={`${styles.wrapper} noselect`}>
      <h3>{title}</h3>

      {error && <p className="text-danger mt-2 text-center">{error}</p>}

      <div className={cx(styles.fields, { inactive: loading })}>
        {loading && <Loading width="70" marginTop="0" />}

        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId={`group-${groupName}`}>
            {(provided) => (
              <ul {...provided.droppableProps} ref={provided.innerRef}>
                {currentFields.map((field, index) => {
                  const propPath = `${groupName}.${field}`

                  return (
                    <Draggable key={`${groupName}-${field}`} draggableId={`${groupName}-${field}`} index={index}>
                      {(provided) => (
                        <li {...provided.draggableProps} ref={provided.innerRef}>
                          <AdminCallInfo
                            propPath={propPath}
                            prop={field}
                            groupName={groupName}
                            dragHandleProps={provided.dragHandleProps}
                            alreadyUsedValues={alreadyUsedValues}
                          />
                        </li>
                      )}
                    </Draggable>
                  )
                })}
                {provided.placeholder}
              </ul>
            )}
          </Droppable>
        </DragDropContext>

        <AdminAddCallInfoForm groupName={groupName} alreadyUsedValues={alreadyUsedValues} />
      </div>
    </div>
  )
}

export { AdminCallInfoGroup }
