import { ErrorMessagesEnum } from 'typings'
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
// types and utils
import { ICallDeepType, CallStateEnum, IGetCalls, ISummaryPut, IPropertyGroup, IAddCallApi } from 'features/calls'
import { ErrorHandler, axios, notifyError, getAccessToken } from 'utils'
// state
import store from 'app/store'
// services
import { getCallsApi, getCallApi, updateCallSummaryApi, addCallsApi } from 'features/calls'
import { signOutApi, fetchProfile } from 'features/users'

export const getCallsAndReplace = createAsyncThunk(
  'calls/getCallsAndReplace',
  async (body: IGetCalls, { rejectWithValue }) => {
    try {
      const calls = await getCallsApi(body)
      if (body.info?.filter || body.info?.sort) {
        await store.dispatch(fetchProfile())
      }

      return calls
    } catch (error: any) {
      // Logout if unauthorized
      if (error?.response?.status === 401) {
        signOutApi()
        notifyError(ErrorMessagesEnum.NotAuthenticated)
      }

      // Set error true
      return rejectWithValue(true)
    }
  },
)

export const getCallsAndAppend = createAsyncThunk(
  'calls/getCallsAndAppend',
  async (body: IGetCalls, { rejectWithValue }) => {
    try {
      return await getCallsApi(body)
    } catch (error: any) {
      // Logout if unauthorized
      if (error?.response?.status === 401) {
        signOutApi()
        notifyError(ErrorMessagesEnum.NotAuthenticated)
      }

      // Set error true
      return rejectWithValue(true)
    }
  },
)

export const getCall = createAsyncThunk('calls/getCall', async (id: string, { rejectWithValue }) => {
  try {
    return await getCallApi(id)
  } catch (error: any) {
    // Logout if unauthorized
    if (error?.response?.status === 401) {
      signOutApi()
      notifyError(ErrorMessagesEnum.NotAuthenticated)
    }

    // Set error true
    return rejectWithValue(true)
  }
})

export const removeCall = createAsyncThunk('calls/removeCall', async (id: string, { rejectWithValue }) => {
  try {
    await axios.delete(`/calls/${id}`, { headers: { Authorization: getAccessToken() } })
  } catch (error: any) {
    ErrorHandler.handleError(error, ErrorMessagesEnum.DeleteCall)

    // Set error true
    return rejectWithValue(true)
  }
})

export const addCalls = createAsyncThunk('calls/addCalls', async (body: IAddCallApi, { rejectWithValue }) => {
  try {
    await addCallsApi(body)
  } catch (error: any) {
    // Send notifications
    ErrorHandler.handleError(error, ErrorMessagesEnum.Unknown)

    // Set error true
    return rejectWithValue(true)
  }
})

export const updateCallSummary = createAsyncThunk(
  'calls/updateCallSummary',
  async ({ summary, id }: ISummaryPut, { rejectWithValue }) => {
    try {
      await updateCallSummaryApi({ summary, id })
    } catch (error: any) {
      // Logout if unauthorized
      if (error?.response?.status === 401) {
        signOutApi()
        notifyError(ErrorMessagesEnum.NotAuthenticated)
      }

      return rejectWithValue(true)
    }
  },
)

type ResetSuccessType = 'getCallsAndAppend' | 'addCalls' | 'updateCallSummary' | 'getCall'

interface ICallsState {
  calls: ICallDeepType[]
  call: ICallDeepType | Record<string, never>
  activeQueryState: CallStateEnum
  pagination: {
    hasMore: boolean
    limit: number
    page: number
  }
  loading: {
    getCallsAndAppend: boolean
    addCalls: boolean
    getCall: boolean
    removeCall: boolean
    updateCallSummary: boolean
    getCallsAndReplace: boolean
  }
  error: {
    getCallsAndAppend: boolean | string
    addCalls: boolean | string
    getCall: boolean | string
    removeCall: boolean | string
    updateCallSummary: boolean | string
    getCallsAndReplace: boolean | string
  }
  success: {
    getCallsAndAppend: boolean
    addCalls: boolean
    getCall: boolean
    removeCall: boolean
    updateCallSummary: boolean
    getCallsAndReplace: boolean
  }
}

export const initialState = {
  calls: [],
  call: {},
  updatedSlots: [],
  activeQueryState: 'pending',
  pagination: {
    hasMore: true,
    limit: 50,
    page: 1,
  },
  loading: {
    getCallsAndAppend: false,
    addCalls: false,
    getCall: false,
    removeCall: false,
    updateCallSummary: false,
    getCallsAndReplace: false,
  },
  error: {
    getCallsAndAppend: false,
    addCalls: false,
    getCall: false,
    removeCall: false,
    updateCallSummary: false,
    getCallsAndReplace: false,
  },
  success: {
    getCallsAndAppend: false,
    addCalls: false,
    getCall: false,
    removeCall: false,
    updateCallSummary: false,
    getCallsAndReplace: false,
  },
} as ICallsState

export const callsSlice = createSlice({
  name: 'calls',
  initialState,
  reducers: {
    resetCall: (state) => {
      state.call = {}
      state.loading = initialState.loading
      state.error = initialState.error
      state.success = initialState.success
    },
    resetSuccess: (state, action: PayloadAction<ResetSuccessType>) => {
      if (state.success[action.payload]) state.success[action.payload] = false
    },
    resetCalls: (state) => {
      if (state.calls.length !== 0) state.calls = []
    },
    setActiveQueryState: (state, action: PayloadAction<CallStateEnum>) => {
      state.activeQueryState = action.payload
    },
    setPageQuery: (state, action: PayloadAction<number>) => {
      state.pagination.page = action.payload
    },
    incrementPageQuery: (state) => {
      state.pagination.page += 1
    },
    updateCallRootInfo: (state, action: PayloadAction<IPropertyGroup>) => {
      state.call.info.root = action.payload
    },
    setCall: (state, action: PayloadAction<ICallDeepType>) => {
      state.call = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCallsAndAppend.rejected, (state, action: PayloadAction<unknown, string>) => {
      state.error.getCallsAndAppend = action.payload as string
      if (state.loading.getCallsAndAppend) state.loading.getCallsAndAppend = false
    })
    builder.addCase(getCallsAndAppend.pending, (state) => {
      state.loading.getCallsAndAppend = true
      if (state.error.getCallsAndAppend) state.error.getCallsAndAppend = false
    })
    builder.addCase(getCallsAndAppend.fulfilled, (state, action) => {
      const calls = action.payload
      state.calls = [...state.calls, ...calls]

      // Used to inform infinite scroll to stop fetching more results
      if (state.pagination.hasMore === false && calls.length === state.pagination.limit) state.pagination.hasMore = true
      if (calls.length < state.pagination.limit) state.pagination.hasMore = false
      if (state.error.getCallsAndAppend) state.error.getCallsAndAppend = false
      if (state.loading.getCallsAndAppend) state.loading.getCallsAndAppend = false
    })
    builder.addCase(getCallsAndReplace.rejected, (state, action: PayloadAction<unknown, string>) => {
      state.error.getCallsAndReplace = action.payload as string
      if (state.loading.getCallsAndReplace) state.loading.getCallsAndReplace = false
    })
    builder.addCase(getCallsAndReplace.pending, (state) => {
      state.loading.getCallsAndReplace = true
      if (state.error.getCallsAndReplace) state.error.getCallsAndReplace = false
    })
    builder.addCase(getCallsAndReplace.fulfilled, (state, action) => {
      const calls = action.payload
      state.calls = [...calls]

      if (state.pagination.hasMore === false && calls.length === state.pagination.limit) state.pagination.hasMore = true
      if (calls.length < state.pagination.limit) state.pagination.hasMore = false

      if (state.error.getCallsAndReplace) state.error.getCallsAndReplace = false
      if (state.loading.getCallsAndReplace) state.loading.getCallsAndReplace = false
    })
    builder.addCase(addCalls.rejected, (state, action: PayloadAction<unknown, string>) => {
      state.error.addCalls = (action.payload as string) || 'Server error'
      if (state.loading.addCalls) state.loading.addCalls = false
      if (state.success.addCalls) state.success.addCalls = false
    })
    builder.addCase(addCalls.pending, (state) => {
      state.loading.addCalls = true
      if (state.error.addCalls) state.error.addCalls = false
      if (state.success.addCalls) state.success.addCalls = false
    })
    builder.addCase(addCalls.fulfilled, (state) => {
      if (state.error.addCalls) state.error.addCalls = false
      if (state.loading.addCalls) state.loading.addCalls = false
      if (!state.success.addCalls) state.success.addCalls = true
    })
    builder.addCase(getCall.rejected, (state, action: PayloadAction<unknown, string>) => {
      state.error.getCall = action.payload as string
      if (state.loading.getCall) state.loading.getCall = false
    })
    builder.addCase(getCall.pending, (state) => {
      state.loading.getCall = true
      if (state.error.getCall) state.error.getCall = false
    })
    builder.addCase(getCall.fulfilled, (state, action: PayloadAction<ICallDeepType>) => {
      if (state.error.getCall) state.error.getCall = false
      if (state.loading.getCall) state.loading.getCall = false
      state.call = action.payload
    })
    builder.addCase(removeCall.rejected, (state, action: PayloadAction<unknown, string>) => {
      state.error.removeCall = action.payload as string
      if (state.loading.removeCall) state.loading.removeCall = false
      if (state.success.removeCall) state.success.removeCall = false
    })
    builder.addCase(removeCall.pending, (state) => {
      state.loading.removeCall = true
      if (state.error.removeCall) state.error.removeCall = false
      if (state.success.removeCall) state.success.removeCall = false
    })
    builder.addCase(removeCall.fulfilled, (state) => {
      state.success.removeCall = true
      if (state.error.removeCall) state.error.removeCall = false
      if (state.loading.removeCall) state.loading.removeCall = false
    })
    builder.addCase(updateCallSummary.rejected, (state, action: PayloadAction<unknown, string>) => {
      state.error.updateCallSummary = action.payload as string
      if (state.loading.updateCallSummary) state.loading.updateCallSummary = false
      if (state.success.updateCallSummary) state.success.updateCallSummary = false
    })
    builder.addCase(updateCallSummary.pending, (state) => {
      state.loading.updateCallSummary = true
      if (state.error.updateCallSummary) state.error.updateCallSummary = false
      if (state.success.updateCallSummary) state.success.updateCallSummary = false
    })
    builder.addCase(updateCallSummary.fulfilled, (state) => {
      state.success.updateCallSummary = true
      if (state.error.updateCallSummary) state.error.updateCallSummary = false
      if (state.loading.updateCallSummary) state.loading.updateCallSummary = false
    })
  },
})

export const {
  resetCall,
  resetCalls,
  resetSuccess: resetCallSuccess,
  setActiveQueryState,
  updateCallRootInfo,
  setPageQuery,
  incrementPageQuery,
  setCall,
} = callsSlice.actions

export const callsReducer = callsSlice.reducer
