import jsPDF from 'jspdf'
import 'jspdf-autotable'
// types and utils
import { IEntities, sanitizeDataForCSVExport } from 'features/calls'
import { formatDateWithAMPM } from 'utils/date'
// state
import { useAppSelector } from 'app/store'

interface IProps {
  data: IEntities
}

const ExportPDF = ({ data }: IProps) => {
  // global state
  const { assignedTo, startTime, info } = useAppSelector((state) => state.calls.call) || { assignedTo: {} }

  // props and utils
  const { csvData, headers } = sanitizeDataForCSVExport(data)
  const sanitizedHeaders = headers.map((header) => ({ header: header.label, dataKey: header.key }))
  const alias = assignedTo?.alias || 'Unavailable'
  const dateConducted = formatDateWithAMPM(startTime)
  const filename = `${alias}_${dateConducted}.pdf`.replace(/\s/g, '_')
  const claimId = info?.root?.claim?.claimId.value || 'Not provided'

  // actions
  const generatePDF = () => {
    // initialize jsPDF
    const doc = new jsPDF('l', 'pt')
    // startY is basically margin-top
    ;(doc as any).autoTable(sanitizedHeaders, csvData, { startY: 70 })
    // ticket title. and margin-top + margin-left
    // we define the name of our PDF file.
    doc.text(`Initial claim ID: ${claimId}`, 40, 40)
    doc.save(filename)
  }

  return <div onClick={generatePDF}>Export as PDF</div>
}

export { ExportPDF }
