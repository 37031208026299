import cx from 'classnames'
import { useHistory } from 'react-router-dom'
import { useDialogState, Dialog, DialogDisclosure } from 'reakit/Dialog'
// styles and assets
import PhoneClose from 'assets/images/phone-close.svg'
// types and utils
import { ErrorHandler, getAccessToken, axios } from 'utils'
// state
import { useAppDispatch, useAppSelector } from 'app/store'
import { updateConversationStatus } from 'features/conversation'
// services
import { disconnect } from 'features/conversation'

const EndCallButton = () => {
  // props and utils
  const history = useHistory()
  const dialog = useDialogState({ modal: false })
  const dispatch = useAppDispatch()

  // global state
  const { endingCall } = useAppSelector((state) => state.conversation.status)
  const { _id: id } = useAppSelector((state) => state.calls.call)

  // handlers
  const endCallHandler = async (event: React.MouseEvent<HTMLButtonElement>) => {
    try {
      event.preventDefault()
      dispatch(updateConversationStatus({ endingCall: true }))
      await axios.delete(`/calls/${id}`, { headers: { Authorization: getAccessToken() } })
      history.push(`/calls/${id}/results`)
      disconnect()
    } catch (error: any) {
      ErrorHandler.handleError(error)
      dispatch(updateConversationStatus({ endingCall: false, conversationStatus: 'endCallFailed' }))
      dialog.hide()
    }
  }

  return (
    // ID used for keyboard binding in src->utils->keyboardBindings
    <>
      <DialogDisclosure
        as="button"
        disabled={endingCall}
        className="btn btn-danger btn-just-icon ml-auto"
        {...dialog}
        data-test="endCallToggler"
      >
        <img src={PhoneClose} alt="Edit icon" />
      </DialogDisclosure>

      <div className={cx({ 'modal-container': dialog.visible })}>
        <Dialog className="modal" {...dialog} aria-label="End call modal">
          <p>Are you sure you want to end the call?</p>

          <div>
            <button
              id="end-call-button"
              className="btn btn-danger mr-2"
              onClick={endCallHandler}
              disabled={endingCall}
              data-test="endCallButton"
            >
              End call
            </button>
            <button className="btn btn-secondary" onClick={dialog.hide}>
              Cancel
            </button>
          </div>
        </Dialog>
      </div>
    </>
  )
}

export { EndCallButton }
