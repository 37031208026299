import { useEffect, memo } from 'react'
import { GoMute, GoUnmute } from 'react-icons/go'
// styles and assets
import styles from './AudioButton.module.scss'
// types and utils
import {
  endAudioConnection,
  toggleAudio,
  initAudioConnection,
  setAudioVolume,
} from 'features/conversation/audioSocket'
// state
import { useAppSelector, useAppDispatch } from 'app/store'
import { updateConversationStatus } from 'features/conversation'

const AudioButton = memo(() => {
  // global state
  const { audioMuted } = useAppSelector((state) => state.conversation.status)
  const { call } = useAppSelector((state) => state.calls)
  // props and utils
  const dispatch = useAppDispatch()
  // effects
  useEffect(() => {
    return () => {
      setAudioVolume(0)
      endAudioConnection()
    }
  }, [])

  const handleClick = () => {
    if (initAudioConnection(call._id)) {
      dispatch(updateConversationStatus({ audioMuted: !audioMuted }))
      toggleAudio(audioMuted)
    }
  }

  return (
    <>
      <button type="button" className="btn btn-xs" id="start-audio" onClick={handleClick}>
        {audioMuted ? (
          <span className={`text-danger ${styles.mute}`}>
            MUTED <GoMute />
          </span>
        ) : (
          <span className={`text-success ${styles.mute}`}>
            <GoUnmute />
          </span>
        )}
      </button>
    </>
  )
})

AudioButton.displayName = 'AudioButton'

export { AudioButton }
