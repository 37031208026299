import { Link } from 'react-router-dom'
// styles and assets
// import styles from './CallDetailsAction.module.scss'
// types and utils
import { ICallDeepType } from 'features/calls'
import { isOwner } from 'utils'

interface IProps {
  call: ICallDeepType | Record<string, never>
}

const CallDetailsAction = ({ call }: IProps) => {
  return (
    <div data-test="action">
      {['active', 'transferred'].includes(call.state) && (
        <Link
          to={isOwner(call) ? `/live-calls/${call._id}` : `/calls/${call._id}`}
          className="btn btn-secondary btn-full-width"
        >
          {isOwner(call) ? 'Resume Call' : 'View Call'}
        </Link>
      )}

      {['pending', 'scheduled'].includes(call.state) && (
        <Link to={`/calls/${call._id}`} className="btn btn-secondary btn-full-width">
          Go to Call View
        </Link>
      )}

      {call.state === 'closed' && (
        <Link to={`/calls/${call._id}/results`} className="btn btn-secondary btn-full-width">
          View results
        </Link>
      )}

      {call.state === 'invalid' && (
        <button className="btn btn-invalid btn-full-width" disabled>
          Report to administrator
        </button>
      )}
    </div>
  )
}

export { CallDetailsAction }
