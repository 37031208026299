import { useContext } from 'react'
import { ThemeContext } from 'styled-components'
import { NavLink } from 'react-router-dom'
import cx from 'classnames'
import { useLocation } from 'react-router-dom'
// components
import { NavItemDropdown } from 'components'
// styles and assets
import Logout from 'assets/images/logout.svg'
import Phone from 'assets/images/phone.svg'
import Stack from 'assets/images/stack.svg'
import Graph from 'assets/images/graph.svg'
import Download from 'assets/images/download.svg'
import Logo from 'assets/new-logo.png'
import Man from 'assets/images/steering-wheel-icon.svg'
import { ReactComponent as AccountMenuIcon } from 'assets/images/ellipses.svg'
import styles from './Nav.module.scss'
// types and utils
import { PagesPathEnum } from 'typings'
// state
import { useAppSelector } from 'app/store'
// services
import { signOutApi } from 'features/users'

const Nav = () => {
  // global state
  const { firstName, lastName, role } = useAppSelector((state) => state.users.profile)
  const loading = useAppSelector((state) => state.users.loading.login)
  const error = useAppSelector((state) => state.users.error.login)

  // props and utils
  const { pathname } = useLocation()
  const theme = useContext(ThemeContext)

  // variables
  const firstNameInitial = firstName?.[0]?.toUpperCase() || 'X'
  const lastNameInitial = lastName?.[0]?.toUpperCase() || 'X'
  const isAdmin = role === 'admin'

  return (
    <nav className={styles.wrapper} data-test="main-nav">
      <ul className={styles.pages}>
        <li className={styles.brand}>
          <NavLink to="/" data-test="logoMainPage">
            <img className={styles.logo} src={Logo} alt="Logo" width="48" height="47" />
          </NavLink>
        </li>

        <li>
          <NavLink
            exact
            activeClassName={styles.active}
            // Show the link active when the pathname targets a call id
            className={cx({ [styles.active]: pathname.includes('live-calls') })}
            to={PagesPathEnum.liveCalls}
            data-test="liveCalls"
          >
            <img src={Phone} alt="Phone icon" width="16" height="16" /> Live Calls
          </NavLink>
        </li>
        <li>
          <NavLink exact activeClassName={styles.active} to={PagesPathEnum.queue} data-test={PagesPathEnum.queue}>
            <img src={Stack} alt="Stack icon" width="16" height="16" />
            Call Queue
          </NavLink>
        </li>
        <li>
          <NavLink
            exact
            activeClassName={styles.active}
            to={PagesPathEnum.analytics}
            data-test={PagesPathEnum.analytics}
          >
            <img src={Graph} alt="Graph icon" width="16" height="16" />
            Analytics
          </NavLink>
        </li>
        <li>
          <NavLink exact activeClassName={styles.active} to={PagesPathEnum.addCalls} data-test={PagesPathEnum.addCalls}>
            <img src={Download} alt="Download icon" width="16" height="16" />
            Data Handling
          </NavLink>
        </li>
        {isAdmin && (
          <li>
            <NavLink exact activeClassName={styles.active} to={PagesPathEnum.admin} data-test={PagesPathEnum.admin}>
              <img src={Man} alt="Admin icon" width="16" height="16" />
              Admin
            </NavLink>
          </li>
        )}
      </ul>

      <ul className={styles.account}>
        <li>
          <NavLink exact to={PagesPathEnum.profile} data-test={PagesPathEnum.profile} activeClassName={styles.active}>
            <span className="avatar-initial">{`${firstNameInitial} ${lastNameInitial}`}</span>
          </NavLink>
        </li>

        <NavItemDropdown icon={<AccountMenuIcon fill={theme[theme.current].colors.greyF3} />}>
          <ul>
            <li className={cx({ disabled: loading })} onClick={() => signOutApi()}>
              <img src={Logout} alt="Logout icon" width="20" height="20" />
              Logout
              {error && <span className="text-error">Could not sign you out</span>}
            </li>
          </ul>
        </NavItemDropdown>
      </ul>
    </nav>
  )
}

export { Nav }
