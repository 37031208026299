import { useState, useEffect, memo } from 'react'

type Props = {
  seconds: number
  stop?: boolean
  start: boolean
  timerClassName?: string
  callback: () => void
}

const CountdownTimer = memo(({ seconds, stop, start, timerClassName = '', callback }: Props) => {
  const [timeLeft, setTimeLeft] = useState(seconds)

  useEffect(() => {
    if (timeLeft === 0) {
      if (callback) callback()
    }

    // exit early when we reach 0
    if (!timeLeft && stop) return

    // save intervalId to clear the interval when the
    // component re-renders
    const intervalId = setInterval(() => {
      setTimeLeft((prev) => prev - 1)
    }, 1000)

    if (stop) clearInterval(intervalId)

    // clear interval on re-render to avoid memory leaks
    return () => clearInterval(intervalId)
    // add timeLeft as a dependency to re-rerun the effect
    // when we update it
  }, [timeLeft, start, stop])

  return <span className={timerClassName}>{timeLeft}</span>
})

CountdownTimer.displayName = 'CountdownTimer'

export { CountdownTimer }
