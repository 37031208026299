import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
// components
import { Layout, Loading, Error, NoContentBlock } from 'components'
import { LiveCallTabs } from 'features/calls/components'
// styles and assets
import styles from './LiveCalls.module.scss'
// types and utils
import { IGetCalls } from 'features/calls'
import { ongoingCallKeyBindings } from 'utils'
// state
import { useAppDispatch, useAppSelector } from 'app/store'
import { getCallsAndReplace, resetCalls } from 'features/calls'
import { isEmpty } from 'lodash'
import { ErrorMessagesEnum } from 'typings'

const LiveCalls = () => {
  //* global state
  const { calls } = useAppSelector((state) => state.calls)
  const error = useAppSelector((state) => state.calls.error.getCallsAndReplace)
  const { user } = useAppSelector((state) => state.users)

  //* props and utils
  const { id } = useParams<{ id: string }>()
  const dispatch = useAppDispatch()

  //* local state
  // avoid the NoContentBlock bug showing even when redux loading is true
  const [loading, setLoading] = useState(true)

  //* effects
  useEffect(() => {
    const body: IGetCalls = {
      startIndex: 0,
      limit: 4,
      state: ['active'],
      info: {
        withoutFilter: true,
        withoutSort: true,
        withoutAutomaticCalls: true,
      },
    }

    body.assignedTo = user?.username || ''
    const getCallsPromise = user?.username ? dispatch(getCallsAndReplace(body)) : undefined

    getCallsPromise?.then(() => setLoading(false))
    return () => {
      getCallsPromise?.abort()
      dispatch(resetCalls())
    }
  }, [user])

  // Add key bindings
  useEffect(() => {
    document?.addEventListener('keydown', ongoingCallKeyBindings)
    return () => {
      document?.removeEventListener('keydown', ongoingCallKeyBindings)
    }
  })

  //* variables
  // Fixes bug where the Live calls page was using the Queue state calls breaking the app
  const hasLiveCalls = !loading && !error && calls[0]?.state === 'active'
  const hasNoLiveCalls = !loading && !error && isEmpty(calls)

  return (
    <Layout>
      <div className={styles.wrapper}>
        {loading && <Loading width="100" message="Fetching your active calls" />}

        {error && (
          <Error title={ErrorMessagesEnum.SomethingWentWrong} message={ErrorMessagesEnum.ReportToAdministrator} />
        )}

        {hasLiveCalls && <LiveCallTabs tab={id === 'all' ? '' : id}>{calls}</LiveCallTabs>}

        {hasNoLiveCalls && (
          <NoContentBlock
            title="You are not currently conducting any calls"
            subTitle="All your ongoing calls will appear here"
            btnLink="/"
            btnClassName="btn btn-success btn-md"
            btnText="Go to Call Queue"
          />
        )}
      </div>
    </Layout>
  )
}

export { LiveCalls }
