import { useState, useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { isEmpty } from 'lodash'
// components
import { Layout, HorizontalTabs, CallSummary, CallTranscript, Loading, Error, NoContentBlock } from 'components'
import { Interventions } from 'features/calls/components'
// styles and assets
import styles from './PostCall.module.scss'
// types and utils
import { ErrorMessagesEnum } from 'typings'
// state
import { useAppDispatch, useAppSelector } from 'app/store'
import { getCall, resetCall } from 'features/calls'

// types
interface IParam {
  id: string
}

const PostCall = () => {
  // global state
  const loading = useAppSelector<boolean>((state) => state.calls.loading.getCall)
  const error = useAppSelector((state) => state.calls.error.getCall)
  const { call } = useAppSelector((state) => state.calls)

  // props and utils
  const { id } = useParams<IParam>()
  const history = useHistory()
  const dispatch = useAppDispatch()

  // local state
  const [activeTab, setActiveTab] = useState('call-summary')

  // effects
  useEffect(() => {
    const getCallPromise = dispatch(getCall(id))

    return () => {
      dispatch(resetCall())
      getCallPromise.abort()
    }
  }, [id])

  // Redirect if the call is not closed
  useEffect(() => {
    if (call.state === 'invalid') history.push(`/`)
    if (call.state === 'pending') history.push(`/calls/${call._id}`)
  }, [call])

  // variables
  const showCallTranscript = call?.assignedTo?.alias !== 'virtual.agent'

  return (
    <Layout>
      <div className={styles.wrapper}>
        {!isEmpty(call) && (
          <section>
            <HorizontalTabs
              title={`Claim #${call.info?.root?.claim?.claimId?.value ?? 'missing'}`}
              initialTab={activeTab}
              tabsCustomClass="border-right"
              onClickCallback={(id: string) => setActiveTab(id)}
            >
              <div title="Call summary" id="call-summary">
                <CallSummary submitCallback={() => setActiveTab('interventions')} />
              </div>

              <div title="Interventions" id="interventions">
                <Interventions isActive={activeTab === 'interventions'} />
              </div>
            </HorizontalTabs>

            <div className="p-relative">
              {showCallTranscript ? (
                <CallTranscript />
              ) : (
                <NoContentBlock title="The call transcript is currently not available for automated calls" />
              )}
            </div>
          </section>
        )}

        {loading && <Loading message="Fetching call results" width="100" />}

        {error && (
          <Error
            title={ErrorMessagesEnum.CallNotFound}
            message={ErrorMessagesEnum.ReportToAdministrator}
            btnLink="/"
            btnClassName="btn btn-ternary-reverse"
            btnText="Go to Call Queue"
          />
        )}
      </div>
    </Layout>
  )
}

export { PostCall }
