import { useState } from 'react'
import cx from 'classnames'
// components
import { ButtonWithConfirmationModal } from 'components'
// styles and assets
import styles from './AdminEditCallInfoForm.module.scss'
// types and utils
import { UsedValuesEnum } from 'utils'
import { IUpdateCallInfo, IUpdateCallInfoTypes } from 'typings'
// state
import { useAppDispatch } from 'app/store'
import { setProfile } from 'features/users'
// services
import { updateMasterProfileApi } from 'features/users'
import { ErrorHandler } from 'utils'
import { ErrorMessagesEnum } from 'typings'

interface IProps {
  field: string
  fieldData: { [key: string]: any }
  groupName: string
  loading: boolean
  alreadyUsedValues: {
    [kind in UsedValuesEnum]: string[]
  }
  setLoading: React.Dispatch<React.SetStateAction<boolean>>
}

const AdminEditCallInfoForm = ({ field, fieldData, groupName, loading, alreadyUsedValues, setLoading }: IProps) => {
  // props and utils
  const { friendlyName, slotMapping, kind, format } = fieldData
  const propNameLabel = `${groupName}-${field}-propName`
  const friendlyNameLabel = `${groupName}-${field}-friendlyName`
  const slotMappingLabel = `${groupName}-${field}-slotMapping`
  const dispatch = useAppDispatch()

  // local state
  const [data, setData] = useState({
    [propNameLabel]: field,
    [friendlyNameLabel]: friendlyName,
    [slotMappingLabel]: slotMapping,
  })

  const [isUsed, setIsUsed] = useState({
    [propNameLabel]: false,
    [friendlyNameLabel]: false,
    [slotMappingLabel]: false,
  })
  const [errors, setErrors] = useState<{ [key: string]: string }>({})
  const hasErrors = Object.keys(errors).length > 0

  // handlers
  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { id, value } = e.target
    setData((prev) => ({ ...prev, [id]: value }))

    if (errors[id]) delete errors[id]

    const changedType = id.split('-').slice(-1)[0]
    if (
      alreadyUsedValues[changedType as UsedValuesEnum]?.some(
        (usedValue) => usedValue?.toLowerCase() === value?.trim()?.toLowerCase(),
      )
    ) {
      setIsUsed((prev) => ({ ...prev, [id]: true }))
    } else {
      if (isUsed[id]) {
        setIsUsed((prev) => ({ ...prev, [id]: false }))
      }
    }
  }

  const handleSubmit = async (e: React.ChangeEvent<HTMLFormElement>) => {
    try {
      e.preventDefault()
      setLoading(true)
      if (hasErrors) setErrors({})

      const updateCallInfo: IUpdateCallInfo = { types: [], path: `${groupName}.${field}`, updates: {} }

      // Updating a prop name
      if (data[propNameLabel]?.trim() !== field) {
        updateCallInfo.types.push(IUpdateCallInfoTypes.propName)
        updateCallInfo.updates.newPropPath = `${groupName}.${data[propNameLabel]?.trim()}`
      }

      // Updating prop values
      const isFriendlyNameUpdate = data[friendlyNameLabel]?.trim() !== friendlyName
      const isSlotMappingUpdate = data[slotMappingLabel]?.trim() !== slotMapping

      if (isFriendlyNameUpdate || isSlotMappingUpdate) {
        updateCallInfo.types.push(IUpdateCallInfoTypes.values)

        if (isFriendlyNameUpdate) updateCallInfo.updates.friendlyName = data[friendlyNameLabel]?.trim()
        if (isSlotMappingUpdate) updateCallInfo.updates.slotMapping = data[slotMappingLabel]?.trim()
      }

      // No updates
      if (updateCallInfo.types.length === 0) return

      const updatedProfile = await updateMasterProfileApi({ updateCallInfo })
      dispatch(setProfile(updatedProfile))
    } catch (error: any) {
      if (error?.response?.data?.errors?.length > 0) {
        const dataPropNamesWithoutPrefix = Object.keys(data).map((field) => field.split('-')[2])
        const propsNamePrefix = `${groupName}-${field}-`

        const { hasFieldErrors, fieldsWithErrors } = ErrorHandler.getFieldErrors(
          dataPropNamesWithoutPrefix,
          error.response.data.errors,
          propsNamePrefix,
        )

        hasFieldErrors ? setErrors(fieldsWithErrors) : ErrorHandler.handleError(error, ErrorMessagesEnum.BadRequest)
      } else {
        ErrorHandler.handleError(error, ErrorMessagesEnum.Unknown)
      }
    }
    setLoading(false)
  }

  const removeProp = async () => {
    try {
      setLoading(true)

      const updateCallInfo = {
        types: [IUpdateCallInfoTypes.remove],
        path: `${groupName}.${field}`,
        updates: {},
      }

      const updatedProfile = await updateMasterProfileApi({ updateCallInfo })
      dispatch(setProfile(updatedProfile))
    } catch (error: any) {
      ErrorHandler.handleError(error, ErrorMessagesEnum.Unknown)
    }
    setLoading(false)
  }

  // variables
  //* Used to disable the submit button
  const hasChanged =
    data[propNameLabel]?.trim() !== field ||
    data[friendlyNameLabel]?.trim() !== friendlyName ||
    data[slotMappingLabel]?.trim() !== slotMapping
  const hasUsedValue = Object.keys(isUsed).some((key) => isUsed[key])
  const inactive = !hasChanged || hasUsedValue

  return (
    <form className={cx(styles.wrapper, { inactive: loading })} onSubmit={handleSubmit}>
      <div className="form-control">
        <label className="form-label" htmlFor={propNameLabel}>
          Property name
        </label>

        <p className="text-danger">(Will update all the calls in the database)</p>
        <input
          required
          className="form-input"
          type="text"
          name={propNameLabel}
          id={propNameLabel}
          defaultValue={data[propNameLabel]}
          onChange={handleChange}
        />
        {errors?.[propNameLabel] && <p className="text-danger">{errors?.[propNameLabel]}</p>}

        {data[propNameLabel]?.trim() !== field &&
          (isUsed[propNameLabel] ? (
            <p className="text-danger">&quot;{data[propNameLabel]?.trim()}&quot; is already used.</p>
          ) : (
            <p>
              Current value{' '}
              <i>
                <b>{field}</b>{' '}
              </i>
            </p>
          ))}
      </div>

      <div className="form-control">
        <label className="form-label" htmlFor={friendlyNameLabel}>
          Friendly name
        </label>
        <input
          required
          className="form-input"
          type="text"
          name={friendlyNameLabel}
          id={friendlyNameLabel}
          defaultValue={data[friendlyNameLabel]}
          onChange={handleChange}
        />
        {errors?.[friendlyNameLabel] && <p className="text-danger">{errors?.[friendlyNameLabel]}</p>}

        {data[friendlyNameLabel]?.trim() !== friendlyName &&
          (isUsed[friendlyNameLabel] ? (
            <p className="text-danger">&quot;{data[friendlyNameLabel]?.trim()}&quot; is already used.</p>
          ) : (
            <p>
              Current value{' '}
              <i>
                <b>{friendlyName}</b>{' '}
              </i>
            </p>
          ))}
      </div>

      <div className="form-control">
        <label className="form-label" htmlFor={slotMappingLabel}>
          Slot mapping
        </label>
        <input
          required
          className="form-input"
          type="text"
          name={slotMappingLabel}
          id={slotMappingLabel}
          defaultValue={data[slotMappingLabel]}
          onChange={handleChange}
        />
        {errors?.[slotMappingLabel] && <p className="text-danger">{errors?.[slotMappingLabel]}</p>}

        {data[slotMappingLabel]?.trim() !== slotMapping &&
          (isUsed[slotMappingLabel] ? (
            <p className="text-danger">&quot;{data[slotMappingLabel]?.trim()}&quot; is already used.</p>
          ) : (
            <p>
              Current value{' '}
              <i>
                <b>{slotMapping}</b>{' '}
              </i>
            </p>
          ))}
      </div>

      <div className="form-control">
        <p>
          Property Kind: <strong>{kind?.charAt(0)?.toUpperCase() + kind?.slice(1)}</strong>
        </p>
        {format && format !== kind && (
          <p>
            Special Formatting: <strong>{format}</strong>
          </p>
        )}
      </div>

      <div className={styles.buttons}>
        <ButtonWithConfirmationModal
          togglerClassName="btn btn-danger"
          modalCancelClass={'btn btn-secondary'}
          modalConfirmClass={'btn btn-danger mr-2'}
          modalCancelText="Cancel"
          modalConfirmText="Confirm"
          modalQuestionText={`This will remove "${friendlyName}" from all user configs and calls, are you sure?`}
          togglerText="Remove"
          loading={loading}
          onConfirm={removeProp}
        />

        <button className={cx('btn btn-primary', { inactive })}>Update</button>
      </div>
    </form>
  )
}

export { AdminEditCallInfoForm }
