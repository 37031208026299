import { DialogStateReturn } from 'reakit/Dialog'
import cx from 'classnames'
// styles and assets
import CheckmarkImage from 'assets/images/checked.svg'
import styles from './QuerySortActions.module.scss'
// types and utils
import { IInfoSort, SortOptionsEnum } from 'features/calls'
// state
import { useAppDispatch, useAppSelector } from 'app/store'
import { resetCalls, setPageQuery, getCallsAndReplace } from 'features/calls'

interface IProps {
  propPath: string
  dialog: DialogStateReturn
  changeActiveCallHandler: (index: number) => void
}

const QuerySortActions = ({ propPath, dialog, changeActiveCallHandler }: IProps) => {
  // global state
  const { limit, page } = useAppSelector((state) => state.calls.pagination)
  const { activeQueryState } = useAppSelector((state) => state.calls)
  const { sorting } = useAppSelector(
    (state) => state.users.profile?.configuration?.callsQuery || { columns: [], sorting: [] },
  )

  // props and utils
  const dispatch = useAppDispatch()

  // handlers
  const handleSortClick = (e: React.MouseEvent<HTMLLIElement>) => {
    e.preventDefault()

    const { id } = e.target as HTMLLIElement
    const type = id as keyof typeof SortOptionsEnum

    dispatch(resetCalls())
    // Reset the infinite scroll page number
    if (page !== 1) dispatch(setPageQuery(1))
    // Remove currently selected call because it is positionited sticky
    changeActiveCallHandler(-1)

    const sort: IInfoSort = {
      state: activeQueryState,
      path: propPath,
      sort: type,
    }

    const body = { startIndex: 0, limit, state: [activeQueryState], info: { sort } }

    dispatch(getCallsAndReplace(body))
    dialog.hide()
  }

  // variables
  const hasActiveSort: false | keyof typeof SortOptionsEnum = sorting?.[activeQueryState]?.path === propPath && sorting?.[activeQueryState]?.sort

  return (
    <ul className={styles.wrapper}>
      <li
        id={SortOptionsEnum.ascending}
        onClick={handleSortClick}
        className={cx({ [styles.active]: hasActiveSort && hasActiveSort === SortOptionsEnum.ascending })}
        data-test="filtersSortAsc"
      >
        <span>Sort A &#10230; Z</span>

        {hasActiveSort && hasActiveSort === SortOptionsEnum.ascending && (
          <img src={CheckmarkImage} alt="Arrow up" width="20" height="20" />
        )}
      </li>
      <li
        id={SortOptionsEnum.descending}
        onClick={handleSortClick}
        className={cx({ [styles.active]: hasActiveSort && hasActiveSort === SortOptionsEnum.descending })}
        data-test="filtersSortDesc"
      >
        <span>Sort Z &#10230; A</span>

        {hasActiveSort && hasActiveSort === SortOptionsEnum.descending && (
          <img src={CheckmarkImage} alt="Arrow down" width="20" height="20" />
        )}
      </li>
    </ul>
  )
}

export { QuerySortActions }
