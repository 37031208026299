import { useState } from 'react'
import cx from 'classnames'
import useImmutableSWR from 'swr'
import ReactTooltip from 'react-tooltip'
// components
import { Menu } from 'features/calls/components'
import { Loading, Error } from 'components'
// styles and assets
import styles from './CallSummaryExtractedInfo.module.scss'
// types and utils
import { ErrorMessagesEnum } from 'typings/errorTypes'
import { CallPathsEnum } from 'features/calls'
import {
  ClaimStatusEnum,
  EntityPropsEnum,
  IEntities,
  formatExtractedEntityValue,
  getClaimStatusClass,
  entities,
} from 'features/calls'
import { capitalizeFirstLetter, ErrorHandler } from 'utils'
// services
import { getExtractedInfo, API_ERROR_RETRY_COUNT, API_ERROR_RETRY_DELAY } from 'features/calls'
import { ICallDeepType } from 'features/calls/typings'

interface IProps {
  call: ICallDeepType | Record<string, never>
}

const CallSummaryExtractedInfo = ({ call }: IProps) => {
  // local state
  const [hideClipboardIcon, setHideClipboardIcon] = useState(false)
  const [isError, setIsError] = useState(false)

  // api
  const { data } = useImmutableSWR(
    // Don`t retry the API request if API_ERROR_RETRY_COUNT requests have passed
    () => (isError || call?.results ? null : `${CallPathsEnum.ivrInfoExtractor}/${call._id}`),
    (url) => getExtractedInfo(url),
    {
      // Sometimes it might take a few seconds to get the data, swr will retry retryCount times until is ready
      onErrorRetry: (error, _key, _config, revalidate, { retryCount }) => {
        if (retryCount >= API_ERROR_RETRY_COUNT) {
          if (!isError) setIsError(true)
          ErrorHandler.handleError(error, ErrorMessagesEnum.NoExtractedInfo)
        }
        //TODO Replace this with the error received from the backend when the call is not ready
        if (error.response.status === 404) {
          setTimeout(() => revalidate({ retryCount }), API_ERROR_RETRY_DELAY)
        }
      },
      revalidateOnFocus: false,
    },
  )

  // handlers
  const copyToClipboard = (e: React.MouseEvent<HTMLSpanElement>) => {
    const text = capitalizeFirstLetter(e.currentTarget.textContent || '')
    navigator.clipboard.writeText(text)
    if (!hideClipboardIcon) setHideClipboardIcon(true)
  }

  const removeClicked = () => {
    if (hideClipboardIcon) setHideClipboardIcon(false)
  }

  // early returns
  // After swr tries retryCount times we show this error message
  if (isError)
    return (
      <div className={styles.wrapper}>
        <Error
          title={ErrorMessagesEnum.ResourceNotFound}
          message={ErrorMessagesEnum.GetExtractedInfo}
          contentOffset="0"
          hideIcon
        />
      </div>
    )

  // variables
  const isAutomatedCall = call?.assignedTo?.alias === 'virtual.agent' || call?.results
  const isLoading = !isAutomatedCall && !data && !isError
  const extractedEntities = call?.results ? call?.results : data
  // temp
  const claimStatus = {
    value: extractedEntities?.[EntityPropsEnum.claimStatus]?.value || ClaimStatusEnum.unknown,
  }
  const billedAmount = extractedEntities?.[EntityPropsEnum.billedAmount]
  const planPaid = extractedEntities?.[EntityPropsEnum.planPaid]
  const topRowData = [EntityPropsEnum.billedAmount, EntityPropsEnum.planPaid]

  return (
    <section className={styles.wrapper} data-test="callSummaryExtractedInfoContainer">
      {!isLoading ? (
        <div className={styles.extractedInfo}>
          <ul className={styles.header}>
            <li>
              <p>{entities[EntityPropsEnum.claimStatus].description}</p>
              <span
                data-tip={`Copied "${claimStatus?.value || ''}"`}
                className={cx(getClaimStatusClass(claimStatus?.value || ''), {
                  [styles.hideClipboardIcon]: hideClipboardIcon,
                })}
                onClick={copyToClipboard}
                onMouseOut={removeClicked}
              >
                {claimStatus?.value || 'Unknown'}
              </span>
            </li>

            <li>
              <p>{entities[EntityPropsEnum.billedAmount].description}</p>
              <span
                data-tip={`Copied "${formatExtractedEntityValue({
                  ...billedAmount,
                  type: entities[EntityPropsEnum.billedAmount].type,
                })}"`}
                className={cx({
                  'text-secondary': billedAmount?.value === '',
                  [styles.hideClipboardIcon]: hideClipboardIcon,
                })}
                onClick={copyToClipboard}
                onMouseOut={removeClicked}
              >
                {formatExtractedEntityValue({
                  ...billedAmount,
                  type: entities[EntityPropsEnum.billedAmount].type,
                })}
              </span>
            </li>

            <li>
              <p>{planPaid?.description || 'Plan Paid'}</p>
              <span
                data-tip={`Copied "${formatExtractedEntityValue({
                  ...planPaid,
                  type: entities[EntityPropsEnum.planPaid].type,
                })}"`}
                className={cx({
                  'text-secondary': planPaid?.value === '',
                  [styles.hideClipboardIcon]: hideClipboardIcon,
                })}
                onClick={copyToClipboard}
                onMouseOut={removeClicked}
              >
                {formatExtractedEntityValue({
                  ...planPaid,
                  type: entities[EntityPropsEnum.planPaid].type,
                })}
              </span>
            </li>

            <li className={styles.menu}>
              <Menu data={extractedEntities as IEntities} />
            </li>
          </ul>

          <div className={styles.details}>
            {extractedEntities &&
              Object.keys(extractedEntities)
                ?.filter((el) => !topRowData.includes(el as EntityPropsEnum))
                ?.map((key, i) => {
                  const entity = extractedEntities[key as EntityPropsEnum]
                  const entityMap = entities[key as EntityPropsEnum]
                  return (
                    <p key={entityMap?.description || i}>
                      <span>{entityMap?.description || 'Missing'}</span>

                      <span
                        data-tip={`Copied "${formatExtractedEntityValue({ ...entityMap, ...entity })}"`}
                        onClick={copyToClipboard}
                        onMouseOut={removeClicked}
                        className={cx({
                          'text-secondary': entity?.value === '',
                          [styles.hideClipboardIcon]: hideClipboardIcon,
                        })}
                      >
                        {formatExtractedEntityValue({ ...entityMap, ...entity })}
                      </span>
                    </p>
                  )
                })}
          </div>

          <ReactTooltip delayHide={300} event="click" effect="solid" type="info" eventOff="mouseout" isCapture={true} />
        </div>
      ) : (
        <Loading marginBottom="10px" message="Extracting call results..." />
      )}
    </section>
  )
}

export { CallSummaryExtractedInfo }
