// components
import { CallDetails, CallSummaryExtractedInfo, CallSummaryForm, PostCallActions } from 'features/calls/components'
// styles and assets
import styles from './CallSummary.module.scss'
// state
import { useAppSelector } from 'app/store'

interface IProps {
  submitCallback?: () => void
}

const CallSummary = ({ submitCallback }: IProps) => {
  // global state
  const { call } = useAppSelector((state) => state.calls)

  // variables
  const showExtractedInfo = process.env.REACT_APP_ENABLE_INFO_EXTRACTOR === 'true'

  return (
    <div className={styles.wrapper}>
      <CallDetails call={call} />

      <div className={styles['call-summary']}>
        {showExtractedInfo && <CallSummaryExtractedInfo call={call} />}

        <CallSummaryForm call={call} submitCallback={submitCallback} />

        <PostCallActions call={call} />
      </div>
    </div>
  )
}

export { CallSummary }
