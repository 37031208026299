const BackendSelect = () => {
  const urlOptions = process.env.REACT_APP_DEV_API_MAIN_URL_OPTIONS
    ? process.env.REACT_APP_DEV_API_MAIN_URL_OPTIONS.split(', ')
    : []
  const wslOptions = process.env.REACT_APP_DEV_WS_URLS ? process.env.REACT_APP_DEV_WS_URLS.split(', ') : []
  const urlFriendyNames = process.env.REACT_APP_DEV_API_MAIN_URL_FRIENDLY_NAMES
    ? process.env.REACT_APP_DEV_API_MAIN_URL_FRIENDLY_NAMES.split(', ')
    : []

  if (!sessionStorage.getItem('backendUrl')) sessionStorage.setItem('backendUrl', urlOptions[0])

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { value } = e.target as HTMLSelectElement

    sessionStorage.setItem('backendUrl', value)
    sessionStorage.setItem('wsUrl', wslOptions[urlOptions.indexOf(value) || 0])
    window.location.reload()
  }

  return (
    <div className="form-control">
      <select
        className="form-input"
        onChange={handleChange}
        defaultValue={sessionStorage.getItem('backendUrl') || urlOptions[0]}
      >
        {urlOptions.map((option: string, i: number) => (
          <option key={i} value={option}>
            {urlFriendyNames[i]}
          </option>
        ))}
      </select>
    </div>
  )
}

export { BackendSelect }
