import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { last } from 'lodash'
import cx from 'classnames'
// components
import { CallNotes } from 'components'
import { CallPendingDisplay, CallStartedDisplay } from 'features/calls/components'
// styles and assets
import styles from './CallDisplay.module.scss'
// types and utils
import { ICallDeepType } from 'features/calls'
import { IConversationDeep, IConversationLocalState } from 'typings'
// state
import { useAppSelector, useAppDispatch } from 'app/store'
import { resetConversation, updateConversationStatus } from 'features/conversation'
import { resetCall } from 'features/calls'

interface IProps {
  call: ICallDeepType | Record<string, never>
  isLiveCallsPage?: true
}

const CallDisplay = ({ call, isLiveCallsPage }: IProps) => {
  // global state
  const { conversations } = useAppSelector((state) => state.conversation)
  const { callStarted, startingCall } = useAppSelector((state) => state.conversation.status)
  const lastConversation: IConversationDeep | IConversationLocalState = last(conversations)

  // props and utils
  const history = useHistory()
  const dispatch = useAppDispatch()

  // effects
  // Reset the call local state
  useEffect(() => {
    return () => {
      dispatch(resetCall())
      dispatch(resetConversation())
    }
  }, [])
  // Update conversation state on refreshes
  useEffect(() => {
    if (!callStarted && call.state === 'active')
      dispatch(updateConversationStatus({ callStarted: true, conversationStatus: 'ongoing' }))
  }, [call])

  // Check if the call is transferred or take_over is active and update state
  useEffect(() => {
    if (call.state === 'active') {
      if (lastConversation?.participants?.[0]?.partyType === 'user') {
        dispatch(updateConversationStatus({ callTransferred: true }))
      } else if (
        lastConversation?.mode === 'override' ||
        conversations.some((conversation) => conversation.items?.some((item) => item?.event?.kind === 'take_over'))
      ) {
        dispatch(
          updateConversationStatus({
            callTakenOver: true,
            conversationStatus: 'takeOver',
          }),
        )
      }
    }
  }, [lastConversation, call])

  // Redirect the call if is not in the accepted states
  useEffect(() => {
    if (['closed'].includes(call.state)) history.push(`/calls/${call._id}/results`)
    if (['invalid'].includes(call.state)) history.push(`/`)
  }, [call])

  return (
    <div className={cx(styles.wrapper, { [styles['with-section-nav']]: isLiveCallsPage })}>
      {callStarted ? (
        <CallStartedDisplay call={call} />
      ) : (
        <CallPendingDisplay call={call} startingCall={startingCall} />
      )}

      <CallNotes />
    </div>
  )
}

export { CallDisplay }
