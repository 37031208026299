import { CSVLink } from 'react-csv'
// types and utils
import { formatDateWithAMPM } from 'utils/date'
import { sanitizeDataForCSVExport, IEntities } from 'features/calls'
// state
import { useAppSelector } from 'app/store'

interface IProps {
  data: IEntities
}

const ExportCSV = ({ data }: IProps) => {
  // global state
  const { assignedTo, startTime } = useAppSelector((state) => state.calls.call) || { assignedTo: {} }

  const { csvData, headers } = sanitizeDataForCSVExport(data)

  //TODO The ivrInfoExtractor has claim id extracting functionality, ask Product Manager if we should add the default call claimId?
  // headers.unshift({ label: 'Claim ID', key: EntityPropsEnum.claimId })
  // csvData[0][EntityPropsEnum.claimId] = info?.root?.claim?.claimId.value || 'Not provided'

  // variables
  const alias = assignedTo?.alias || 'Unavailable'
  const dateConducted = formatDateWithAMPM(startTime)
  const filename = `${alias}_${dateConducted}.csv`.replace(/\s/g, '_')

  return (
    <CSVLink data={csvData} headers={headers} filename={filename}>
      Export as CSV
    </CSVLink>
  )
}

export { ExportCSV }
