import { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import cx from 'classnames'
import { useDialogState, Dialog, DialogDisclosure } from 'reakit/Dialog'
import { set, cloneDeep } from 'lodash'
// styles and assets
import styles from './AddPendingCallButton.module.scss'
// types and utils
import { ICallDeepType, IAddCall } from 'features/calls'
import { ErrorMessagesEnum } from 'typings'
import { ErrorHandler, getPath } from 'utils'
// state
import { useAppDispatch } from 'app/store'
import { resetCall } from 'features/calls/callSlice'
// services
import { addCallsApi } from 'features/calls'

interface IProps {
  call: ICallDeepType | Record<string, never>
}

const AddPendingCallButton = ({ call }: IProps) => {
  // props and utils
  const dialog = useDialogState({ modal: false })
  const dispatch = useAppDispatch()
  const history = useHistory()

  // local state
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [success, setSuccess] = useState(false)
  const [callId, setCallId] = useState('')

  // effects
  useEffect(() => {
    if (!dialog.visible) {
      if (success) setSuccess(false)
      if (error) setError(false)
    }
  }, [dialog.visible])

  // handlers
  const addCallHandler = async (event: React.MouseEvent<HTMLButtonElement>) => {
    try {
      event.preventDefault()
      setLoading(true)
      if (error) setError(false)
      if (success) setSuccess(false)

      const body: IAddCall = {
        from: call?.requestData?.from,
        to: call?.requestData?.to,
        info: { root: cloneDeep(call?.info?.root) },
      }

      if (call?.summary?.notes) body.notes = call?.summary?.notes;

      ['conductedDate', 'uploadedDate', 'assignedTo'].forEach((prop) => {
        if (getPath(body.info, prop).length) set(body.info, getPath(body.info, prop)[0], undefined)
      })

      const { data: calls }: any = await addCallsApi({ calls: [body], bulkProcessing: false })
      const newCall: ICallDeepType = calls[0].value

      setCallId(newCall._id)
      setLoading(false)
      setSuccess(true)
    } catch (error: any) {
      setLoading(false)
      setSuccess(false)
      setError(true)

      ErrorHandler.handleError(error, ErrorMessagesEnum.AddCall)
    }
  }

  const viewCallHandler = (callId: string) => {
    dispatch(resetCall())
    history.push(`/calls/${callId}`)
  }

  return (
    <>
      <DialogDisclosure
        as="button"
        className="btn btn-md btn-ternary"
        {...dialog}
        data-test="addPendingCallModalToggler"
      >
        Add back to Pending
      </DialogDisclosure>

      <div className={cx({ 'modal-container': dialog.visible })}>
        <Dialog className={cx(`modal ${styles.modal}`, { 'has-error': error })} {...dialog} aria-label="End call modal">
          <h2 className="mb-2">Add call back to Pending</h2>

          {success ? (
            <>
              <p>Call successfully added</p>

              <button onClick={() => viewCallHandler(callId)} className="btn btn-ternary mx-auto">
                View Call
              </button>
            </>
          ) : (
            <>
              <p>Are you sure you want to add this call back to Pending?</p>

              <div className={cx('mt-2', { disabled: loading })}>
                <button
                  type="button"
                  className={cx('btn btn-md btn-success mr-2', { 'btn-loading': loading || success })}
                  onClick={addCallHandler}
                  disabled={loading || success}
                  data-test="addPendingCallButton"
                >
                  {loading && 'Adding call...'}
                  {success && 'Successfully added'}
                  {!loading && !success && 'Add'}
                </button>

                <button className="btn btn-md btn-ternary" onClick={dialog.hide}>
                  Cancel
                </button>
              </div>
            </>
          )}
        </Dialog>
      </div>
    </>
  )
}

export { AddPendingCallButton }
