import { useEffect, useState, memo } from 'react'
// types and utils
import { dateToSSMS, dateToHHMMSS, dateDiff } from 'utils'

type formatTypes = 'ss:ms' | 'hh:mm:ss' | 'hh:mm:ss:ms'

interface IProps {
  startTime: string | Date
  format?: formatTypes
  //* For scale ideas
  // stop?: boolean
  // start?: boolean
  // timerClassName?: string
  // callback?: any
}

const Timer = memo(({ startTime, format }: IProps) => {
  const formatedTime = typeof startTime === 'string' ? new Date(startTime) : startTime
  const start = startTime ? formatedTime : new Date()
  const getElapsedTime = (start: Date, format?: formatTypes) => {
    return format === 'hh:mm:ss' ? dateToHHMMSS(dateDiff(new Date(), start)) : dateToSSMS(dateDiff(new Date(), start))
  }

  const [time, setTime] = useState(getElapsedTime(start, format))

  useEffect(() => {
    const updateInterval = format === 'hh:mm:ss' ? 1000 : 80
    const interval = setInterval(() => {
      setTime(getElapsedTime(start, format))
    }, updateInterval)

    return () => {
      clearInterval(interval)
    }
  }, [])

  return <>{time}</>
})

Timer.displayName = 'Timer'

export { Timer }
