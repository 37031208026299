import { useState } from 'react'
import { last } from 'lodash'
// styles and assets
import styles from './SwitchBotLink.module.scss'
// types and utils
import { EventKindEnum, IEventLocalState } from 'typings'
import { getAccessToken, createLocalStateEvent, notifyError, axios } from 'utils'
// state
import { useAppDispatch, useAppSelector } from 'app/store'
import { updateConversationStatus, addItem } from 'features/conversation'
// services
import { signOutApi } from 'features/users'
import { ErrorMessagesEnum } from 'typings/errorTypes'

interface IProps {
  id: string
}

const SwitchBotLink = ({ id }: IProps) => {
  // global state
  const { conversations, lastAgentItem } = useAppSelector((state) => state.conversation)
  const { callTakenOver } = useAppSelector((state) => state.conversation.status)

  // props and utils
  const dispatch = useAppDispatch()

  // local state
  const [transferred, setTransferred] = useState(false)
  const [loading, setLoading] = useState(false)

  // handlers
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()

    setLoading(true)

    const body = {
      command: 'replace_participant' as const,
      participantIndex: 0,
      participant: {
        bot: {
          service: 'conifer',
        },
      },
    }

    axios
      .post(`/calls/${id}`, body, { headers: { Authorization: getAccessToken() } })
      .then(() => {
        const localStateEvent: IEventLocalState = {
          kind: EventKindEnum.switch_va,
          refItem: lastAgentItem?._id || '',
        }

        dispatch(addItem(createLocalStateEvent(localStateEvent)))

        setTransferred(true)
        setLoading(false)
      })
      .catch((error: any) => {
        if (error?.response?.status === 401) {
          signOutApi()
          notifyError(ErrorMessagesEnum.NotAuthenticated)
        } else {
          notifyError(ErrorMessagesEnum.SwitchBot)
          dispatch(
            updateConversationStatus({
              transferingCall: false,
              callTransferred: false,
              conversationStatus: 'transferFailed',
            }),
          )
        }

        setTimeout(() => dispatch(updateConversationStatus({ conversationStatus: 'ongoing' })), 2500)
      })
  }

  // variables
  //TODO this is to static, discuss about making it more dynamic
  const isIvrBot = last(conversations)?.participants.some((participant: any) =>
    ['dev', 'ivr_nav', 'onlu'].includes(participant?.service?.provider?.name || ''),
  )

  const hide = transferred || !isIvrBot || callTakenOver

  return hide ? (
    <span />
  ) : loading ? (
    <span className="text-right">Transferring...</span>
  ) : (
    <span className={styles.wrapper} onClick={handleClick}>
      Switch to Claims Status VA
    </span>
  )
}

export { SwitchBotLink }
