import { memo } from 'react'
// components
import { Timer } from 'components'
import { AudioButton } from 'features/conversation/components'
// styles and assets
import Recording from 'assets/images/recording.svg'
import styles from './CallTimer.module.scss'

type Props = {
  timeCallStarted: Date
  showAudioButton: boolean
}

const CallTimer = memo(({ timeCallStarted, showAudioButton }: Props) => {
  return (
    <span className={styles.callTime}>
      {showAudioButton && <AudioButton />}
      <img src={Recording} alt="Recording icon" />
      <Timer startTime={timeCallStarted} format="hh:mm:ss" />
    </span>
  )
})

CallTimer.displayName = 'CallTimer'

export { CallTimer }
