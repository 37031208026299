import { IConversationDeep, IConversationLocalState } from '../../../typings/conversationTypes'

export enum CallPathsEnum {
  ivrInfoExtractor = 'ivrInfoExtractor',
  calls = 'calls',
}

export interface IAddCallApi {
  calls: any[]
  bulkProcessing: boolean
}

export interface ICallDeepType {
  kind: keyof typeof CallKindsEnum
  state: keyof typeof CallStateEnum
  //TODO Update when the Calls Service Schema is defined
  results: { [key: string]: any }
  conversations: (IConversationDeep | IConversationLocalState)[]
  _id: string
  createdBy: {
    _id: string
    alias: string
    firstName: string
    lastName: string
  }
  assignedTo?: ICallAssignedTo
  summary: ISummary
  info: any

  [key: string]: any
}

export interface ICallParticipantConfig {
  bot: {
    alias?: string
    service?: string
  }
  contact: {
    phoneNumber?: string
  }
  user: {
    alias: string
    phoneNumber?: string
  }
  participantIndex: number
}

export interface ICallAssignedTo {
  _id: string
  alias: string
  firstName: string
  lastName: string
}
export interface ICallConfig {
  from: ICallParticipantConfig
  to: ICallParticipantConfig

  recordCall?: boolean
  info: ICallInfo
}

export interface ICallsAPIQuery {
  startIndex: number // the starting index, default=0
  limit: number // max number of records, same as query params (override)
  //sort?: string,          // 'kind',                                 // how to order the data
  kind?: string[] // 'audio|video||api|mixed',
  state?: string[] // 'pending|scheduled|active|closed',   // any combination of those, i.e. pending|closed works as wellolf
  result?: string[] // 'invalid|success|warnings|errors',
  assignedTo?: string // '<username>',                           // if not specified assumed filtered by the logged in user
  createdBy?: string // '<username>',
}

export interface IAddCall {
  from: {
    bot?: { alias: string; service: string }
    contact?: { phoneNumber: string }
    user?: { alias: string; phoneNumber: string }
  }
  to: {
    contact: {
      phoneNumber: string
    }
  }
  info: {
    root: {
      [key: string]: any
    }
  }
  notes?: string
}

export interface ISummaryPut {
  id: string
  summary: {
    disposition?: string
    notes?: string
    interventions?: { issueKind?: keyof typeof CallInterventionIssueKindEnum; issueNote?: string }[]
  }
}

export interface ISummary {
  interventions: IIntervention[]
  notes?: string
  disposition?: CallSummaryDispositionEnum
}

export interface IIntervention {
  _id: string
  conversationId: string
  conversationItemId: string
  interventionAt: string
  friendlyName: string
  kind: keyof typeof CallInterventionKindEnum
  createdAt: string
  updatedAt: string
  issueKind?: keyof typeof CallInterventionIssueKindEnum
  issueNote?: string
}

export interface IGetCalls {
  startIndex: number
  limit: number
  state?: (keyof typeof CallStateEnum)[]
  assignedTo?: string
  info?: {
    withoutFilter?: true
    withoutSort?: true
    filter?: IInfoFilter
    sort?: IInfoSort
    withoutAutomaticCalls?: true
  }
}

export interface IInfoFilter {
  state: string
  path: string
  values?: string[]
  sort?: keyof typeof SortOptionsEnum
}
export interface IInfoSort {
  state: string
  path: string
  sort: keyof typeof SortOptionsEnum
}

export enum SortOptionsEnum {
  ascending = 'ascending',
  descending = 'descending',
}

export interface IInfoType {
  name: string
  value: string
  valueInit: string
  isHidden?: boolean
}

//===== Call Info =====//
export interface ICallInfo {
  importantFields: string[]
  root: IPropertyGroup
}

//===== Properties ======//
export interface IProperty {
  kind: keyof typeof CallInfoKindEnum
  value?: string | number
  valueInit?: string | number // the initial value of this property. this is used to enable a "revet" or check if the value changed
  format?: string
  friendlyName?: string
  slotMapping?: string
  isReadOnly?: boolean
  isHidden?: boolean
  [property: string]: string | number | boolean | undefined
}

export enum CallInfoKindEnum {
  string = 'string',
  number = 'number',
  date = 'date',
  boolean = 'boolean',
}

export interface IPropertyGroup {
  [propertyName: string]: string[] | IProperty | IProperty[] | string | IPropertyGroup | undefined
  _order?: string[] // special reserved property that signifies the order of the properties in the UI
  _friendlyName?: string //  reserved property that specifies the name to be displayed in the UI
}

export enum CallStateEnum {
  invalid = 'invalid',
  pending = 'pending',
  scheduled = 'scheduled',
  active = 'active',
  closed = 'closed',
  removed = 'removed',
}

enum CallKindsEnum {
  audio = 'audio',
  video = 'video',
  api = 'api',
  mixed = 'mixed',
}

export enum CallInterventionKindEnum {
  replace_message = 'replace_message',
  ignore_message = 'ignore_message',
  take_over = 'take_over',
  transfer_call = 'transfer_call',
}

export enum CallInterventionIssueKindEnum {
  customer_request_human = 'customer_request_human',
  customer_was_upset = 'customer_was_upset',
  virtual_agent_misunderstood_conversation = 'virtual_agent_misunderstood_conversation',
  virtual_agent_misunderstood_context = 'virtual_agent_misunderstood_context',
  non_work_tangent_started = 'non_work_tangent_started',
  virtual_agent_unresponsive = 'virtual_agent_unresponsive',
  specific_information_incorrect = 'specific_information_incorrect',
  response_unneeded = 'response_unneeded',
  conversation_on_hold = 'conversation_on_hold',
  other = 'other',
}

export enum CallSummaryDispositionEnum {
  CLMSI = 'CLMSI', //Left message with secondary
  CLMPI = 'CLMPI', //Left message with primary
  CINNA = 'CINNA', //Called insurance, no answer
  CINLB = 'CINLB', //Called insurance, line busy
  CFUCI = 'CFUCI', //Called insurance
  CCSAP = 'CCSAP', //Checked claim status via automated phone (IVR)
}
