import { useEffect } from 'react'
import cx from 'classnames'
// styles and assets
// import styles from './EditResponseActions.module.scss'
// types and utils
import { EventKindEnum } from 'typings'
import { focusAndSelect } from 'utils'
import { sendMessage } from 'features/conversation'
// state
import { useAppDispatch, useAppSelector } from 'app/store'
import { updateConversationStatus } from 'features/conversation'

const EditResponseActions = () => {
  // global state
  const { lastAgentItem } = useAppSelector((state) => state.conversation)
  const { replyMessage } = useAppSelector((state) => state.conversation.status)

  // props and utils
  const dispatch = useAppDispatch()

  // effects
  useEffect(() => {
    focusAndSelect('replyMessage')
  }, [])

  // handlers
  const sendEditedHandler = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (isValidMessage) {
      e.preventDefault()

      const message = {
        event: EventKindEnum.replace_message,
        data: {
          refItemId: lastAgentItem._id,
          messageText: replyMessage?.trim(),
        },
      }

      sendMessage(message)

      dispatch(updateConversationStatus({ conversationStatus: 'ongoing' }))
    }
  }

  const ignoreMessageHandler = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()

    const message = {
      event: EventKindEnum.ignore_message,
      data: {
        refItemId: lastAgentItem._id,
      },
    }

    sendMessage(message)

    dispatch(updateConversationStatus({ conversationStatus: 'ongoing' }))
  }

  // variables
  const isValidMessage = replyMessage?.trim().length

  return (
    <>
      <button
        className={cx('btn btn-success btn-with-icon', { disabled: !isValidMessage })}
        onClick={sendEditedHandler}
        // This is used for keyBindings() in the pages/Call/utils.ts
        id="send-new-message"
      >
        <div className="keyboardKey">↵</div>
        <span>Send</span>
      </button>
      <button
        className="btn btn-secondary btn-with-icon"
        onClick={ignoreMessageHandler}
        // This is used for keyBindings() in the pages/Call/utils.ts
        id="pass-message"
      >
        <div className="keyboardKey">P</div>
        <span>Pass</span>
      </button>
    </>
  )
}

export { EditResponseActions }
