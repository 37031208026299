// types and utils
import { EventKindEnum, IEventLocalState } from 'typings'
import { sendMessage } from 'features/conversation'
// state
import { useAppDispatch, useAppSelector } from 'app/store'
import { createLocalStateEvent } from 'utils'
import { updateConversationStatus, addItem } from 'features/conversation'

const NewCurrentClaimButton = () => {
  // global state
  const { lastAgentItem } = useAppSelector((state) => state.conversation)
  const { replyMessage } = useAppSelector((state) => state.conversation.status)

  // props and utils
  const dispatch = useAppDispatch()

  // handlers
  const newCurrentClaimHandler = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()

    const message = {
      event: EventKindEnum.new_current_claim,
      data: {
        refItemId: lastAgentItem?._id || '',
        messageText: replyMessage,
      },
    }
    sendMessage(message)

    const localStateEvent: IEventLocalState = {
      kind: EventKindEnum.new_current_claim,
      refItem: lastAgentItem?._id || '',
    }
    dispatch(addItem(createLocalStateEvent(localStateEvent)))
    dispatch(updateConversationStatus({ conversationStatus: 'claimChanged' }))
  }

  return (
    <button type="button" className="btn btn-secondary" onClick={newCurrentClaimHandler}>
      New Current Claim
    </button>
  )
}

export { NewCurrentClaimButton }
