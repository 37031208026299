import { useEffect } from 'react'
import cx from 'classnames'
// styles and assets
// import styles from './TakeOverActions.module.scss'
// types and utils
import { EventKindEnum } from 'typings'
import { sendMessage } from 'features/conversation'
import { focusAndSelect } from 'utils'
// state
import { useAppDispatch, useAppSelector } from 'app/store'
import { updateConversationStatus } from 'features/conversation'

export const TakeOverActions = () => {
  // global state
  const { replyMessage } = useAppSelector((state) => state.conversation.status)

  // props and utils
  const dispatch = useAppDispatch()

  // effects
  useEffect(() => {
    focusAndSelect('replyMessage')
  }, [])

  // handlers
  const sendMessageHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (isValidMessage) {
      event.preventDefault()

      const message = {
        event: EventKindEnum.new_message,
        data: {
          messageText: replyMessage?.trim(),
        },
      }

      sendMessage(message)
      focusAndSelect('replyMessage')

      dispatch(updateConversationStatus({ conversationStatus: 'ongoing', replyMessage: '' }))
    }
  }

  // Not needed in the current production build
  // const stopTakeOver = (event: React.MouseEvent<HTMLButtonElement>) => {
  //   console.log('stop take over')
  //   event.preventDefault()

  //   console.log('clicked')
  //   if (socket) {
  //     const message = {
  //       event: 'change_execution_mode',
  //       data: {
  //         executionMode: 'confidence_threshold',
  //       },
  //     }

  //     console.log(`replace_message event sent to socket`, message)
  //     socket.send(JSON.stringify(message))
  //   }

  //   dispatch(updateConversationStatus({ conversationStatus: 'ongoing', replyMessage: '', callTakenOver: false }))
  // }

  // variables
  const isValidMessage = replyMessage?.trim().length

  return (
    <>
      <button
        type="submit"
        className={cx('btn btn-success btn-with-icon', { disabled: !isValidMessage })}
        onClick={sendMessageHandler}
        // This is used in src/keboardBindings
        id="send-new-message"
        data-test="takeOverSendButton"
      >
        <div className="keyboardKey">↵</div>
        <span>Send</span>
      </button>
      {/* <button
        type="submit"
        className="btn btn-secondary btn-with-icon"
        onClick={stopTakeOver}
        // This is used for keyBindings() in the pages/Call/utils.ts
        id="stop-take-over"
      >
        <div className="keyboardKey">S</div>
        <span>Stop take over</span>
      </button> */}
    </>
  )
}
