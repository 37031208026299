import { useState } from 'react'
import cx from 'classnames'
// components
import { Loading } from 'components'
// styles and assets
import PlusIcon from 'assets/images/toggler-plus.svg'
import styles from './AdminAddCallInfoForm.module.scss'
// types and utils
import { IUpdateCallInfo, IUpdateCallInfoTypes, ErrorMessagesEnum } from 'typings'
import { UsedValuesEnum, ErrorHandler } from 'utils'
// state
import { useAppDispatch } from 'app/store'
import { setProfile } from 'features/users'
// services
import { updateMasterProfileApi } from 'features/users'
interface IProps {
  groupName: string
  alreadyUsedValues: {
    [kind in UsedValuesEnum]: string[]
  }
}

const AdminAddCallInfoForm = ({ groupName, alreadyUsedValues }: IProps) => {
  // props and utils
  const dispatch = useAppDispatch()
  const friendlyNameLabel = `${groupName}-friendlyName`
  const propNameLabel = `${groupName}-propName`
  const slotMappingLabel = `${groupName}-slotMapping`
  const kindLabel = `${groupName}-kind`

  const initialState = {
    [propNameLabel]: '',
    [friendlyNameLabel]: '',
    [slotMappingLabel]: '',
    [kindLabel]: 'string',
  }

  // local state
  const [data, setData] = useState(initialState)
  const [open, setOpen] = useState(false)
  const [isUsed, setIsUsed] = useState({
    [propNameLabel]: false,
    [friendlyNameLabel]: false,
    [slotMappingLabel]: false,
  })
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState<{ [key: string]: string }>({})
  const hasErrors = Object.keys(errors).length > 0

  // handlers
  const handleSubmit = async (e: React.ChangeEvent<HTMLFormElement>) => {
    try {
      e.preventDefault()
      setLoading(true)
      if (hasErrors) setErrors({})

      const updateCallInfo: IUpdateCallInfo = {
        types: [IUpdateCallInfoTypes.add],
        path: `${groupName}.${data[propNameLabel]}`,
        updates: {
          friendlyName: data[friendlyNameLabel],
          slotMapping: data[slotMappingLabel],
          kind: data[kindLabel],
        },
      }

      const updatedProfile = await updateMasterProfileApi({ updateCallInfo })
      dispatch(setProfile(updatedProfile))

      if (open) setOpen(false)
      setData(initialState)
      //TODO Remove any from errors
    } catch (error: any) {
      if (error?.response?.data?.errors?.length > 0) {
        const dataPropNamesWithoutPrefix = Object.keys(data).map((field) => field.split('-')[1])
        const propsNamePrefix = `${groupName}-`
        const { hasFieldErrors, fieldsWithErrors } = ErrorHandler.getFieldErrors(
          dataPropNamesWithoutPrefix,
          error.response.data.errors,
          propsNamePrefix,
        )
        hasFieldErrors ? setErrors(fieldsWithErrors) : ErrorHandler.handleError(error, ErrorMessagesEnum.BadRequest)
      } else {
        ErrorHandler.handleError(error, ErrorMessagesEnum.Unknown)
      }
    }
    setLoading(false)
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { id, value } = e.target
    setData((prev) => ({ ...prev, [id]: value }))
    if (errors[id]) delete errors[id]

    const changeKind = id.split('-').slice(-1)[0]
    if (
      alreadyUsedValues[changeKind as UsedValuesEnum]?.some(
        (usedValue) => usedValue?.toLowerCase() === value?.trim()?.toLowerCase(),
      )
    ) {
      setIsUsed((prev) => ({ ...prev, [id]: true }))
    } else {
      if (isUsed[id]) {
        setIsUsed((prev) => ({ ...prev, [id]: false }))
      }
    }
  }

  const handleDetailsToggle = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault()
    setOpen((prev) => !prev)
  }

  // variables
  const hasUsedValue = Object.keys(isUsed).some((key) => isUsed[key])
  const inactive = hasUsedValue

  return (
    <details className={styles.wrapper} open={open}>
      <summary onClick={handleDetailsToggle}>
        <img src={PlusIcon} alt="Plus Icon" width="40" height="40" />
      </summary>

      <form className={cx(styles.wrapper, { inactive: loading })} onSubmit={handleSubmit}>
        {loading && <Loading width="70" marginTop="0" />}

        <div className="form-control">
          <label className="form-label" htmlFor={propNameLabel}>
            Property name
          </label>

          <input
            required
            className={cx('form-input', { 'has-error': errors?.[propNameLabel] })}
            type="text"
            name={propNameLabel}
            id={propNameLabel}
            value={data[propNameLabel]}
            onChange={handleChange}
          />
          {errors?.[propNameLabel] && <p className="text-danger">{errors?.[propNameLabel]}</p>}

          {isUsed[propNameLabel] && (
            <p className="text-danger ml-2">&quot;{data[propNameLabel]?.trim()}&quot; is already used.</p>
          )}
        </div>

        <div className="form-control">
          <label className="form-label" htmlFor={friendlyNameLabel}>
            Friendly name
          </label>

          <input
            required
            className={cx('form-input', { 'has-error': errors?.[friendlyNameLabel] })}
            type="text"
            name={friendlyNameLabel}
            id={friendlyNameLabel}
            value={data[friendlyNameLabel]}
            onChange={handleChange}
          />
          {errors?.[friendlyNameLabel] && <p className="text-danger">{errors?.[friendlyNameLabel]}</p>}

          {isUsed[friendlyNameLabel] && (
            <p className="text-danger ml-2">&quot;{data[friendlyNameLabel]?.trim()}&quot; is already used.</p>
          )}
        </div>

        <div className="form-control">
          <label className="form-label" htmlFor={slotMappingLabel}>
            Slot Mapping
          </label>

          <input
            required
            className={cx('form-input', { 'has-error': errors?.[slotMappingLabel] })}
            type="text"
            name={slotMappingLabel}
            id={slotMappingLabel}
            value={data[slotMappingLabel]}
            onChange={handleChange}
          />
          {errors?.[slotMappingLabel] && <p className="text-danger">{errors?.[slotMappingLabel]}</p>}

          {isUsed[slotMappingLabel] && (
            <p className="text-danger ml-2">&quot;{data[slotMappingLabel]?.trim()}&quot; is already used.</p>
          )}
        </div>

        <div className="form-control">
          <label className="form-label" htmlFor={kindLabel}>
            Property type
          </label>

          <select
            required
            className={cx('form-input', { 'has-error': errors?.[kindLabel] })}
            name={kindLabel}
            id={kindLabel}
            value={data[kindLabel]}
            onChange={handleChange}
          >
            <option value="string">String</option>
            <option value="number">Number</option>
            <option value="date">Date</option>
          </select>

          {errors?.[kindLabel] && <p className="text-danger">{errors?.[kindLabel]}</p>}
        </div>

        <div className={styles.buttons}>
          <button type="submit" className={cx('btn btn-primary', { inactive })}>
            Add prop
          </button>
        </div>
      </form>
    </details>
  )
}

export { AdminAddCallInfoForm }
