import { EntityPropsEnum, IEntities } from 'features/calls/'

export const entities: IEntities = {
  [EntityPropsEnum.claimStatus]: {
    type: 'string',
    value: '',
    description: 'Claim Status',
  },
  [EntityPropsEnum.claimId]: {
    type: 'string',
    value: '',
    description: 'Claim Id',
  },
  [EntityPropsEnum.processedDate]: {
    type: 'date',
    value: '',
    description: 'Claim Processed Date',
  },
  [EntityPropsEnum.billedAmount]: {
    type: 'currency_amount',
    value: '',
    description: 'Claim Billed Amount',
  },
  [EntityPropsEnum.allowedAmount]: {
    type: 'currency_amount',
    value: '',
    description: 'Claim Allowed Amount',
  },
  [EntityPropsEnum.appliedDeductible]: {
    type: 'currency_amount',
    value: '',
    description: 'Claim Applied Deductible',
  },
  [EntityPropsEnum.memberResponsibleAmount]: {
    type: 'currency_amount',
    value: '',
    description: 'Member Responsible Amount',
  },
  [EntityPropsEnum.remittanceAdviceDate]: {
    type: 'date',
    value: '',
    description: 'Remittance Advice Date',
  },
  [EntityPropsEnum.planPaid]: {
    type: 'currency_amount',
    value: '',
    description: 'Plan Paid',
  },
  [EntityPropsEnum.paymentEFTNumber]: {
    type: 'string',
    value: '',
    description: 'Payment EFT Number',
  },
  [EntityPropsEnum.paymentCheckNumber]: {
    type: 'string',
    value: '',
    description: 'Payment Check Number',
  },
  [EntityPropsEnum.paymentDate]: {
    type: 'date',
    value: '',
    description: 'Payment Date',
  },
  [EntityPropsEnum.outliftAgentName]: {
    type: 'string',
    value: '',
    description: 'Outlift VA Friendly Name',
  },
  [EntityPropsEnum.callLastFourOfCallReference]: {
    type: 'string',
    value: '',
    description: 'Call Reference Number Last Four',
  },
  [EntityPropsEnum.claimDob]: {
    type: 'date',
    value: '',
    description: 'Patient Date of Birth',
  },
  [EntityPropsEnum.claimTaxId]: {
    type: 'string',
    value: '',
    description: 'Tax ID',
  },
  [EntityPropsEnum.claimServiceDate]: {
    type: 'date',
    value: '',
    description: 'Date of Service',
  },
  [EntityPropsEnum.claimReceivedDate]: {
    type: 'date',
    value: '',
    description: 'Claim Received Date',
  },
}
