export const removeXMLTags = (text: string): string => {
  if (text === null || text === '') {
    return ''
  } else {
    return text.toString().replace(/<[^>]*>/g, '')
  }
}

export const stripXMLTags = (text: string): string => {
  text = text.toString().replace(/</g, '')
  text = text.toString().replace(/\/>/g, '')
  return text
}

export const capitalizeFirstLetter = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1)
}
