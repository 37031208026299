// components
import { ChatDisplay, ChatDisplayModeToggler } from 'features/conversation/components'
import styles from './CallTranscript.module.scss'
// state
import { useAppSelector } from 'app/store'

const CallTranscript = () => {
  const { showAllMessages } = useAppSelector((state) => state.conversation)

  return (
    <div className={styles.wrapper}>
      <header>
        <h3>Call Transcript</h3>

        <ChatDisplayModeToggler />
      </header>

      <div className={styles.chatDisplayWrapper}>
        <ChatDisplay maxMessageBoxWidth="90" showAllMessages={showAllMessages} />
      </div>
    </div>
  )
}

export { CallTranscript }
