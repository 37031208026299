import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { last } from 'lodash'
// types and utils
import {
  IItem,
  ILocalStateParticipant,
  IConversationDeep,
  IConversationLocalState,
  ConversationParticipantRoleEnum,
  ConversationParticipantPartyTypeEnum,
  ConversationStateEnum,
} from '../../typings/conversationTypes'

type InitialStateType = {
  conversations: (IConversationDeep | IConversationLocalState)[]
  lastAgentItem: IItem | Record<string, never>
  showAllMessages: boolean
  status: {
    callStarted: boolean
    callTransferred: boolean
    callTakenOver: boolean
    conversationStatus: string
    replyMessage: string
    confidence: boolean | number
    startingCall: boolean
    endingCall: boolean
    transferingCall: boolean
    scrollChatTo: boolean | string
    audioMuted: boolean
  }
  loading: {
    getConversation: boolean
  }
  error: {
    getConversation: boolean | string
  }
}

export const initialConversationStatus = {
  callStarted: false,
  callTransferred: false,
  callTakenOver: false,
  conversationStatus: 'idle',
  replyMessage: '',
  confidence: false,
  startingCall: false,
  endingCall: false,
  transferingCall: false,
  scrollChatTo: false,
  audioMuted: true,
}

const initialState = {
  conversations: [],
  lastAgentItem: {},
  status: initialConversationStatus,
  showAllMessages: false,
  loading: {
    getConversation: false,
  },
  error: {
    getConversation: false,
  },
} as InitialStateType

export const conversationSlice = createSlice({
  name: 'conversation',
  initialState,
  reducers: {
    setConversations: (state, action: PayloadAction<(IConversationDeep | IConversationLocalState)[]>) => {
      state.conversations = action.payload
    },
    setLastAgentItem: (state, action: PayloadAction<IItem>) => {
      state.lastAgentItem = action.payload
    },
    toggleShowAllMessages: (state) => {
      state.showAllMessages = !state.showAllMessages
    },
    resetConversation: (state) => {
      state.conversations = []
      state.status = initialConversationStatus
    },
    addItem: (state, action: PayloadAction<IItem>) => {
      // Fix bug when addItem is called after being redirected to PostPage
      if (state.status.callStarted) {
        // If the last conversation is not closed and the call is not transferring add the item to the current conversation
        if (
          (last(state.conversations) && last(state.conversations).state === ConversationStateEnum.active) ||
          state.status.transferingCall
        ) {
          last(state.conversations).items.push(action.payload)
        } else {
          // If the call is transferred (Transfer Voice was clicked) the partyType is user
          const _id = state.status.callTransferred ? 'local-transferred-conv-id' : 'local-bot-conv-id'
          const partyType = state.status.callTransferred
            ? ConversationParticipantPartyTypeEnum.user
            : ConversationParticipantPartyTypeEnum.bot
          const role = ConversationParticipantRoleEnum.agent

          // Create a new local conversation because when the call starts we don't have it so we need to keep a local state of the chat messages
          const newLocalConversation: IConversationLocalState = {
            _id,
            state: ConversationStateEnum.active,
            localState: true,
            items: [action.payload],
            startTime: new Date().toISOString(),
            endTime: '',
            // This is used to choose the UI display for the messages
            participants: [{ partyType, role }],
          }

          state.conversations.push(newLocalConversation)
        }
      }
    },
    createNewConversation: (state, action: PayloadAction<ILocalStateParticipant>) => {
      // Close the current local state conversation if it was not transferred before any item was received
      if (last(state.conversations)) {
        last(state.conversations).endTime = new Date().toISOString()
        last(state.conversations).state = ConversationStateEnum.closed
      }
      // Create a new local conversation using the parameters config
      const { partyType, service, role } = action.payload
      const _id = `conversations-${state.conversations.length}`
      const participant: ILocalStateParticipant = {
        partyType: partyType || ConversationParticipantPartyTypeEnum.bot,
        role: role || ConversationParticipantRoleEnum.listener,
      }
      if (role) participant.role = role
      if (service) participant.service = service
      const newLocalConversation: IConversationLocalState = {
        _id,
        state: ConversationStateEnum.active,
        localState: true,
        items: [],
        startTime: new Date().toISOString(),
        endTime: '',
        // Set the first participant, used in the UI
        participants: [participant],
      }

      state.conversations.push(newLocalConversation)
    },
    updateConversationStatus: (state, action: PayloadAction<{ [key: string]: any }>) => {
      state.status = { ...state.status, ...action.payload }
    },
  },
})

export const {
  resetConversation,
  addItem,
  updateConversationStatus,
  createNewConversation,
  setConversations,
  setLastAgentItem,
  toggleShowAllMessages,
} = conversationSlice.actions

export const conversationReducer = conversationSlice.reducer
