// styles and assets
import styled from 'styled-components'
// types and utils
import { IChildrenNode } from 'typings'

interface IProps extends IChildrenNode {
  title: string,
  styles?: IStyledProps
}

const VerticalContainer = ({ title, children, styles }: IProps) => {
  return (
    <Div {...styles}>
      <h2>{title}</h2>

      {children}
    </Div>
  )
}

interface IStyledProps {
  width?: string
}

const Div = styled.div<IStyledProps>`
  border-right: 1px solid #D2D5E2;
  overflow-y: auto;
  padding-bottom: 30px;
  width: ${(props) => props.width ? props.width : null};

  h2 {
    border-bottom: 1px solid #D2D5E2;
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    padding: 15px 20px;
  }
`

export { VerticalContainer }
