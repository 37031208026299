// types and utils
import { IUser, IUserProfile } from '../typings/userTypes'

export const getAccessToken = () => sessionStorage.getItem('accessToken') || ''

export const setAccessToken = (accessToken: string) => sessionStorage.setItem('accessToken', accessToken || '')

export const setProfile = (updatedProfile: IUserProfile) => {
  sessionStorage.setItem('profile', JSON.stringify(updatedProfile))
}

export const setUser = (user: IUser) => {
  sessionStorage.setItem('currentUser', JSON.stringify(user))
}

export const getUserAndProfile = () => {
  const currentUser: IUser = JSON.parse(sessionStorage.getItem('currentUser') || '{}')
  const profile: IUserProfile = JSON.parse(sessionStorage.getItem('profile') || '{}')

  return { currentUser, profile }
}

export const removeUser = () => {
  sessionStorage.removeItem('accessToken')
  sessionStorage.removeItem('currentUser')
  sessionStorage.removeItem('profile')
}
