// styles and assets
import styles from './CallOwner.module.scss'
// types and utils
import { ICallDeepType } from 'features/calls'
import { isOwner } from 'utils'

interface IProps {
  call: ICallDeepType | Record<string, never>
}
const CallOwner = ({ call }: IProps) => {
  const owner = call?.assignedTo
  const firstNameInitial = owner?.firstName?.[0]?.toUpperCase() || 'X'
  const lastNameInitial = owner?.lastName?.[0]?.toUpperCase() || 'X'

  return (
    <div className={styles.wrapper}>
      <span className={`avatar-initial ${isOwner(call) ? 'bg-primary' : ''}`}>{`${firstNameInitial} ${lastNameInitial}`}</span>

      <div className={styles.owner}>
        <p data-test="callDetailsOwner">
          {owner?.firstName || 'Missing first name'} {owner?.lastName || 'Missing last name'}
        </p>
        <p>Owner</p>
      </div>
    </div>
  )
}

export { CallOwner }
