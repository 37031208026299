import { last } from 'lodash'
// state
import store from '../app/store'
// types and utils
import { v4 as uuidv4 } from 'uuid'
import { IItem, EventKindEnum, BotServiceEnum, ConversationItemStateEnum } from 'typings'

export const getLabelFromKind = (kind: string | undefined) => {
  switch (kind) {
    case 'replace_message':
      return 'Edited message'
    case 'transfer_call':
      return 'Transfered Call'
    case 'take_over':
      return 'Take over started'
    case 'ignore_message':
      return 'Ignored message'
    case 'switch_va':
      return 'Switched to Claims Status VA'
    case 'flag_message':
      return 'Flagged message'
    case 'new_current_claim':
      return 'Current claim changed'
    default:
      return kind
  }
}

export const createLocalStateEvent = (event: { kind: keyof typeof EventKindEnum; refItem: string }) => {
  const defaultEvent: IItem = {
    kind: 'event',
    state: ConversationItemStateEnum.pending,
    effectiveMessageIndex: -1,
    _id: uuidv4(),
    fromParticipantIndex: 2,
    messages: [],
    createdAt: new Date().toISOString(),
    updatedAt: new Date().toISOString(),
  }
  defaultEvent.event = event

  return defaultEvent
}

// Get the timeout delay depending on the current bot provider
export const getTimeoutDelay = (provider: string | undefined = undefined) => {
  const { users, conversation } = store.getState()

  // Get the participants of the current active conversation
  const { participants } = last(conversation.conversations) || {}

  // Get the bot participant and then the serviceProvider name
  const botParticipant = participants?.find((participant: any) => participant.partyType === 'bot')
  const service: string | null = provider ? provider : botParticipant?.service?.provider?.name || null

  // Get the autoFlowTimeoutMS from the profile.configuration by selecting the config with the above service name
  const timeoutDelay =
    users.profile?.configuration?.serviceProviders?.[service as BotServiceEnum]?.executionConfig?.autoFlowTimeoutMS || 2000

  return timeoutDelay
}

// Type used only for below method
interface IParticipant {
  message: IItem
  isTransferMessage: boolean
  plan: string
  agent: string
  toPhoneNumber: string
  planAgentName: string
  virtualAgentDisplay: string
}
export const getParticipantName = ({
  message,
  isTransferMessage,
  plan,
  agent,
  toPhoneNumber,
  planAgentName,
  virtualAgentDisplay,
}: IParticipant) => {
  const confidence = String(Math.round(message.messages[message.effectiveMessageIndex].confidence * 100))
  const isUserOrAgent = [0, 2].includes(message.fromParticipantIndex)
  const isUser = isTransferMessage || message.fromParticipantIndex > 1

  // If virtual agent or user
  if (isUserOrAgent) {
    // If user
    if (isUser) {
      return agent
    } else {
      return `${virtualAgentDisplay} | ${confidence}% Confidence`
    }
  } else {
    return `${planAgentName || toPhoneNumber} | ${plan}`
  }
}

export const getVirtualAgentDisplay = (participants: string[]): string => {
  let res = 'IVR Nav Virtual Agent'

  const { serviceProviders } = store.getState().users?.profile?.configuration || []

  const providerNames = Object.keys(serviceProviders) as (keyof typeof BotServiceEnum)[]
  providerNames.forEach((service) => {
    if (participants.includes(service)) {
      res = serviceProviders[service].friendlyName || 'IVR Nav Virtual Agent'
    }
  })

  return res
}
