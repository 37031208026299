import { useDialogState, Dialog, DialogDisclosure } from 'reakit/Dialog'
import cx from 'classnames'

interface IProps {
  togglerClassName: string
  togglerText: string
  loading: boolean
  modalCancelText: string
  modalConfirmText: string
  modalCancelClass: string
  modalConfirmClass: string
  modalQuestionText: string
  onConfirm: () => void
}

const ButtonWithConfirmationModal = ({
  togglerClassName,
  togglerText,
  loading,
  modalCancelClass,
  modalConfirmClass,
  modalCancelText,
  modalConfirmText,
  modalQuestionText,
  onConfirm,
}: IProps) => {
  // props and utils
  const dialog = useDialogState({ modal: false })

  // handlers
  const handleConfirm = () => {
    dialog.hide()
    onConfirm()
  }

  return (
    <>
      <DialogDisclosure
        as="button"
        disabled={loading}
        className={togglerClassName}
        {...dialog}
        data-test="modalToggler"
      >
        {togglerText}
      </DialogDisclosure>

      <div className={cx({ 'modal-container': dialog.visible })}>
        <Dialog className="modal" {...dialog} aria-label="Confirmation modal">
          <p>{modalQuestionText}</p>

          <div>
            <button
              className={modalConfirmClass}
              onClick={handleConfirm}
              disabled={loading}
              data-test="modalConfirmButton"
            >
              {modalConfirmText}
            </button>
            <button type="button" className={modalCancelClass} onClick={dialog.hide}>
              {modalCancelText}
            </button>
          </div>
        </Dialog>
      </div>
    </>
  )
}

export { ButtonWithConfirmationModal }
