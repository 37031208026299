// components
import { Layout, VerticalContainer } from 'components'
import { UserForm } from 'features/users/components'
// styles and assets
import styles from './Profile.module.scss'

const Profile = () => {
  return (
    <Layout>
      <div className={styles.wrapper}>
        <VerticalContainer title="Your Profile">
          <UserForm />
        </VerticalContainer>
      </div>
    </Layout>
  )
}

export { Profile }
