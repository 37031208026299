import { useEffect, useState } from 'react'
// components
import { Loading } from 'components'
// styles and assets
import styles from './AudioModal.module.scss'
// types and utils
import { setAudioVolume, initAudioConnection } from 'features/conversation/audioSocket'
// state
import { useAppDispatch } from 'app/store'
import { updateConversationStatus } from 'features/conversation'

interface IProps {
  hideAudioModal: () => void
  callId: string
}

const CALL_CONNECTING_TIMEOUT = 3000

const AudioModal = ({ hideAudioModal, callId }: IProps) => {
  // props and utils
  const dispatch = useAppDispatch()

  // local state
  const [showConnecting, setShowConnecting] = useState(true)

  // effects
  useEffect(() => {
    const timeOut = setTimeout(() => {
      setShowConnecting(false)
    }, CALL_CONNECTING_TIMEOUT)
    return () => {
      clearTimeout(timeOut)
    }
  }, [])

  // handlers
  const setSoundOn = () => {
    if (initAudioConnection(callId)) {
      setAudioVolume(1)

      dispatch(updateConversationStatus({ audioMuted: false }))
    }
    hideAudioModal()
  }

  const setSoundOff = () => {
    initAudioConnection(callId)
    setAudioVolume(0)
    dispatch(updateConversationStatus({ audioMuted: true }))
    hideAudioModal()
  }

  return (
    <div className={styles.wrapper}>
      {showConnecting ? (
        <div data-test="connecting">
          <Loading message="Connecting" width="50" marginBottom="0" />
        </div>
      ) : (
        <>
          <h2 data-test="listenAudio">Listen to call audio?</h2>

          <button className="btn btn-success btn-md mt-2 mb-2" onClick={setSoundOn} data-test="soundOn">
            Turn sound on
          </button>

          <button className="btn" onClick={setSoundOff} data-test="soundOff">
            Mute call audio
          </button>
        </>
      )}
    </div>
  )
}

export { AudioModal }
