import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit'
import { useDispatch, useSelector, TypedUseSelectorHook } from 'react-redux'
import { usersReducer } from 'features/users'
import { callsReducer } from 'features/calls'
import { conversationReducer } from 'features/conversation'

const store = configureStore({
  reducer: {
    users: usersReducer,
    calls: callsReducer,
    conversation: conversationReducer,
  },
})

export default store

export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>
export type AppDispatch = typeof store.dispatch
export const useAppDispatch = () => useDispatch<AppDispatch>()
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
