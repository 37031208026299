import { useState, useEffect } from 'react'
// components
import { CallSpectatorView, CallTimer, CallTransferredActions } from 'components'
import { CallActions, AudioModal, ChatDisplay, ChatDisplayModeToggler } from 'features/conversation/components'
// styles and assets
import styles from './CallStartedDisplay.module.scss'
// types and utils
import { ICallDeepType } from 'features/calls'
import { connect, disconnect } from 'features/conversation'
// state
import { useAppSelector } from 'app/store'

type Props = {
  call: ICallDeepType | Record<string, never>
}

const CallStartedDisplay = ({ call }: Props) => {
  // global state
  const { callTransferred } = useAppSelector((state) => state.conversation.status)
  const { showAllMessages } = useAppSelector((state) => state.conversation)
  const { alias: curUsername } = useAppSelector((state) => state.users.profile)
  const { alias: initiatorUsername } = useAppSelector((state) => state.calls.call?.assignedTo || { alias: '' })

  // local state
  const [showAudioModal, setShowAudioModal] = useState(true)

  // effects
  // Connect and set the socket if call started
  useEffect(() => {
    //* removed extra precaution in order to attempt to fix bug where the connection is not firing for some users
    // if (callStarted && !messagesSocket.socket) {
    console.log('Connecting messages socket')
    connect(call._id)
    // }

    return () => {
      // disconnect sockect on component unmount
      disconnect()
    }
  }, [])

  // handlers
  const hideAudioModal = () => setShowAudioModal(false)

  // variables
  const timeCallStarted = call.state === 'active' ? new Date(call.startTime) : new Date()
  const plan = call.info?.root?.callInformation?.plan.value || 'Missing'

  return (
    <div className={styles.wrapper}>
      <div className={styles.callDetails}>
        <h1>
          {plan} <ChatDisplayModeToggler />
        </h1>

        <div>
          <CallTimer timeCallStarted={timeCallStarted} showAudioButton={!showAudioModal} />
        </div>
      </div>

      <div className={styles.chat}>
        {showAudioModal && <AudioModal callId={call._id} hideAudioModal={hideAudioModal} />}

        <div className={styles.messagesContainer}>
          <ChatDisplay showAllMessages={showAllMessages} />
        </div>

        {/** Show the actions only if this is the initatior of the call */}
        {curUsername === initiatorUsername ? (
          /** If the call is transferred show TransferredCall view */
          callTransferred ? (
            <CallTransferredActions />
          ) : (
            <CallActions />
          )
        ) : (
          <CallSpectatorView />
        )}
      </div>
    </div>
  )
}

export { CallStartedDisplay }
