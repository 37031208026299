import { useState, useEffect } from 'react'
import cx from 'classnames'
// styles and assets
import styles from './CallNotesForm.module.scss'
// types and utils
import { ErrorMessagesEnum } from 'typings'
import { getAccessToken, scrollElementToBottom, focus, axios, ErrorHandler } from 'utils'
// state
import { useAppSelector } from 'app/store'

interface IProps {
  disabled?: boolean
}

export const TIMER_TIMEOUT = 1500
export const SUCCESS_TIMEOUT = 1000

// For adding cancelling functionality
const CancelToken = axios.CancelToken
let cancel: any

const CallNotesForm = ({ disabled }: IProps) => {
  // global state
  const { summary, _id } = useAppSelector((state) => state.calls.call)

  // local state
  const [notes, setNotes] = useState<string>(summary?.notes || '')
  const [timers, setTimers] = useState<{ autosave: number | null; success: number | null }>({
    autosave: null,
    success: null,
  })
  const initialState = { saved: false, loading: false }
  const [state, setState] = useState(initialState)
  const [error, setError] = useState<boolean>(false)

  useEffect(() => {
    scrollElementToBottom('add-note')
  })

  // handlers
  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (e.target.value !== notes) {
      setNotes(e.target.value)
      if (error) setError(false)

      // Cancel ongoing timer, axios request
      timers.autosave && window?.clearTimeout(timers.autosave)
      if (cancel !== undefined) {
        cancel()
      }

      // Update local state
      setState((prev) => ({
        ...prev,
        saved: false,
        loading: false,
      }))

      // Set new timer for saving the updated notes
      const timeout = window.setTimeout(handleSave, TIMER_TIMEOUT, e.target.value)
      setTimers((prev) => ({ ...prev, autosave: timeout }))
    }
  }

  const handleSave = async (notes: string) => {
    // Set loading true
    setState((prev) => ({
      ...prev,
      saved: false,
      loading: true,
    }))
    if (error) setError(false)

    try {
      timers.success && window?.clearTimeout(timers.success)

      await axios.put(
        `/calls/${_id}`,
        { summary: { notes } },
        {
          headers: { Authorization: getAccessToken() },
          cancelToken: new CancelToken(function executor(c) {
            cancel = c
          }),
        },
      )

      setState((prev) => ({
        ...prev,
        saved: true,
        loading: false,
      }))

      focus('add-note')

      const successTimout = window.setTimeout(
        () =>
          setState((prev) => ({
            ...prev,
            saved: false,
          })),
        SUCCESS_TIMEOUT,
      )

      setTimers((prev) => ({ ...prev, success: successTimout }))
    } catch (error: any) {
      // If the request was not cancelled by typping
      if (!axios.isCancel(error)) {
        setError(true)
        setState(initialState)
        ErrorHandler.handleError(error, `${ErrorMessagesEnum.SavingNotes} ${ErrorMessagesEnum.ReportToAdministrator}`)
      }
    }
  }

  return (
    <div className={styles['textarea-container']}>
      <div className={styles.label}>
        {state.saved && 'Saved'}
        {state.loading && 'Saving...'}
      </div>

      <textarea
        className={cx('form-input', { [styles.hasError]: error })}
        name="add-note"
        id="add-note"
        value={notes}
        onChange={handleChange}
        placeholder="Click here to type notes"
        readOnly={disabled}
        data-test="callNotesTextarea"
      ></textarea>
    </div>
  )
}

export { CallNotesForm }
