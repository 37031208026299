import cx from 'classnames'
// styles and assets
import styles from './QueueFilters.module.scss'
// types and utils
import { CallStateEnum } from 'features/calls'
// state
import { useAppDispatch, useAppSelector } from 'app/store'
import { setActiveQueryState, setPageQuery } from 'features/calls'

interface IProps {
  setInitialCallsLoaded: React.Dispatch<React.SetStateAction<boolean>>
  changeActiveCallHandler: (index: number) => void
}

const QueueFilters = ({ setInitialCallsLoaded, changeActiveCallHandler }: IProps) => {
  // global state
  const { activeQueryState } = useAppSelector((state) => state.calls)
  const initialCallsLoading = useAppSelector((state) => state.calls.loading.getCallsAndReplace)
  const loadingMoreCalls = useAppSelector((state) => state.calls.loading.getCallsAndAppend)

  // props and utils
  const dispatch = useAppDispatch()

  // handlers
  const handleClick = (newTab: CallStateEnum) => {
    if (activeQueryState !== newTab) {
      // Reseting initial calls loaded for infinite scroll not to dispatch a new request
      setInitialCallsLoaded(false)
      // Reset pagination
      dispatch(setPageQuery(1))
      // Reset active call
      changeActiveCallHandler(0)
      // Set the new filter (pending | closed | active)
      dispatch(setActiveQueryState(newTab))
    }
  }

  return (
    <div className={cx(styles.wrapper, { inactive: initialCallsLoading || loadingMoreCalls })}>
      <ul>
        <li
          className={cx({ [styles.active]: activeQueryState === 'pending' })}
          onClick={() => handleClick(CallStateEnum.pending)}
        >
          Pending
        </li>
        <li
          className={cx({ [styles.active]: activeQueryState === 'active' })}
          onClick={() => handleClick(CallStateEnum.active)}
        >
          Active
        </li>
        <li
          className={cx({ [styles.active]: activeQueryState === 'closed' })}
          onClick={() => handleClick(CallStateEnum.closed)}
        >
          Completed
        </li>
        {/* <li
          className={cx({ [styles.active]: activeQueryState === 'removed' })}
          onClick={() => handleClick(CallStateEnum.removed)}
        >
          Removed
        </li> */}
      </ul>

      <div className={`${styles.container} ${styles.filters}`}>
        {/* Commented out for later implementation */}
        {/* <div className={styles.search}>
          <img src={SearchIcon} alt="Search Icon" />
          <input type="text" placeholder="Search calls" />
        </div> */}
      </div>
    </div>
  )
}

export { QueueFilters }
