import { CallInterventionKindEnum, SortOptionsEnum, CallStateEnum } from '../features/calls/typings/callTypes'
import { BotServiceEnum, ConversationModeEnum } from './conversationTypes'

export interface IUser {
  username: string
  given_name: string
  family_name: string
  email: string
  phone_number: string
  phone_number_verified: boolean
  email_verified: boolean
}

export interface IUserConfiguration {
  _id: string
  templates: {
    dispositions: IConfigurationDispositions
    interventions: IConfigurationInterventions
    call_info: IConfigurationCallInfos
  }
  callsQuery: {
    columns: IConfigurationCallsQueryColumn[]
    filters: { [key in CallStateEnum]: { path: string; values: (string | number)[] } }
    sorting: { [key in CallStateEnum]: { path: string; sort: keyof typeof SortOptionsEnum } }
  }
  calls: {
    defaultBotService: string
    autoStartCall: boolean
    autoStartDelay: 3
  }
  serviceProviders: {
    [k in BotServiceEnum]: {
      executionConfig: IConfigurationExecutionConfig
      friendlyName: string
    }
  }
  interface: {
    theme: string
    mainContainer: { width: string; height: string }
    buttons: {
      nav: { color: string; textAlign: string }
      callSummary: { color: string; backgroundColor: string }
    }
  }
  createdAt: string
  updatedAt: string
}

export interface IConfigurationExecutionConfig {
  mode: keyof typeof ConversationModeEnum
  confidenceThreshold: number
  autoFlowTimeoutMS: 2000
  maxTimeoutMS: 10000
}

export interface IConfigurationCallsQueryColumn {
  path: string
  width?: string
  ordered?: string
}

export interface IConfigurationDispositions {
  _default: IDisposition[]
  [k: string]: IDisposition[]
}

export interface IConfigurationCallInfos {
  _default: IConfigurationInfo
  [k: string]: IConfigurationInfo
}

export interface IConfigurationInfo {
  importantFields: string[]
  _order: string[]
  root: {
    [k: string]: any
  }
}

export interface IConfigurationInterventions {
  _default: ConfigurationInterventionType
  [k: string]: ConfigurationInterventionType
}

export type ConfigurationInterventionType = {
  [k in CallInterventionKindEnum]: ConfigurationInterventionTemplateType
}

export interface IDisposition {
  value: string
  friendlyName: string
}

export type ConfigurationInterventionTemplateType = {
  question: string
  issueKindOptions: Array<{
    value: string
    friendlyName: string
  }>
}

export interface IUserProfile {
  _id: string
  alias: string
  firstName: string
  lastName: string
  role: keyof typeof UserRoleEnum
  emails: { main: string }
  phones: { main: string; transfer?: string }
  createdAt: string
  updatedAt: string
  configuration: IUserConfiguration
}

export interface ILoginResponse {
  username: string
  given_name: string
  family_name: string
  attributes: {
    given_name: string
    family_name: string
    email: string
    email_verified: boolean
    phone_number: string
    phone_number_verified: boolean
    role?: string
    transfer_phone?: string
  }
  signInUserSession: {
    accessToken: { jwtToken: string }
    idToken: { jwtToken: string }
    refreshToken: { token: string }
  }
}

export interface ILoginCredentials {
  usernameField: string
  password: string
}

export interface IUpdateUserRequest {
  role?: string
  phones?: { transfer: string }
  configuration?: {
    template?: {
      columns: string[]
    }
    callsQuery?: {
      columns: IConfigurationCallsQueryColumn[]
    }
  }
  updateCallInfo?: IUpdateCallInfo
}

export interface IUpdateCallInfo {
  types: (keyof typeof IUpdateCallInfoTypes)[]
  path: string
  updates: {
    friendlyName?: string
    kind?: string
    slotMapping?: string
    newPropPath?: string
    order?: string[]
    isHidden?: boolean
    importantFields?: string[]
  }
}

export enum IUpdateCallInfoTypes {
  values = 'values',
  propName = 'propName',
  remove = 'remove',
  add = 'add',
  reorderProps = 'reorderProps',
  changeImportantFields = 'changeImportantFields',
}

export enum UserRoleEnum {
  admin = 'admin',
  supervisor = 'supervisor',
  agent = 'agent',
}

// Enum to scale with future resetSuccess/resetError actions on the userSlice
export enum ResetSuccessEnum {
  updateProfile = 'updateProfile',
}

export enum ResetErrorEnum {
  login = 'login',
}
