import cx from 'classnames'
import { isEmpty } from 'lodash'
// components
import { LoadingAnimation, CountdownTimer } from 'components'
import {
  EndCallButton,
  TransferCallButton,
  TakeOverActions,
  EditResponseActions,
  NewCurrentClaimButton,
  SwitchBotLink,
} from 'features/conversation/components'
// styles and assets
import styles from './CallActions.module.scss'
import { TextareaContainer } from './CallActions.styled'
// types and utils
import { EventKindEnum, IEventLocalState, IEventMessage, IItem } from 'typings'
import { getStatusClass, getConfidenceClass, getStatusMessage, getStatusColor } from './CallActions.utils'
import { focusAndSelect } from 'utils'
import { clearItemTimeout, sendMessage } from 'features/conversation'
import { createLocalStateEvent } from 'utils/conversationProcessor'
// state
import { useAppDispatch, useAppSelector } from 'app/store'
import { updateConversationStatus, addItem } from 'features/conversation'

const CallActions = () => {
  // global state
  const { lastAgentItem } = useAppSelector((state) => state.conversation)
  const { callStarted, conversationStatus, replyMessage, confidence, transferingCall, callTakenOver } = useAppSelector(
    (state) => state.conversation.status,
  )
  const { _id: id } = useAppSelector((state) => state.calls.call)

  // props and utils
  const dispatch = useAppDispatch()

  // handlers
  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    dispatch(updateConversationStatus({ replyMessage: e.target.value }))
  }

  const editResponseHandler = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()

    clearItemTimeout()

    dispatch(updateConversationStatus({ conversationStatus: 'editing' }))

    const message: IEventMessage = {
      event: EventKindEnum.editing_message,
      data: {
        refItemId: lastAgentItem._id,
        messageText: replyMessage,
      },
    }

    sendMessage(message)
  }

  const takeOverHandler = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()

    const message = {
      event: EventKindEnum.take_over,
      data: {
        refItemId: lastAgentItem._id,
      },
    }

    clearItemTimeout()
    sendMessage(message)

    const localStateEvent: IEventLocalState = {
      kind: EventKindEnum.take_over,
      refItem: lastAgentItem?._id || '',
    }

    dispatch(addItem(createLocalStateEvent(localStateEvent)))
    dispatch(updateConversationStatus({ replyMessage: '' }))

    focusAndSelect('replyMessage')
  }

  const approveResponseHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()

    // Stop the timeout for adding the item and immediately add the item
    clearItemTimeout()
    if (!isEmpty(lastAgentItem)) dispatch(addItem(lastAgentItem as IItem))

    const message = {
      event: EventKindEnum.approve_message,
      data: {
        refItemId: lastAgentItem._id,
      },
    }
    sendMessage(message)

    dispatch(updateConversationStatus({ conversationStatus: 'ongoing' }))
  }

  const countDownHandler = () => {
    const message = {
      event: EventKindEnum.new_message,
      data: {
        messageText: replyMessage,
      },
    }

    sendMessage(message)

    dispatch(updateConversationStatus({ conversationStatus: 'ongoing' }))
  }

  const handleKeyboardPress = (event: React.MouseEvent<HTMLButtonElement>) => {
    const element = event.target as HTMLButtonElement
    const keyPressed = element.id.split('-')[1]

    const message = {
      event: EventKindEnum.new_message,
      data: {
        messageText: keyPressed,
        messageAction: {
          name: 'senddtmf',
          params: { digits: keyPressed },
        },
      },
    }
    sendMessage(message)

    dispatch(updateConversationStatus({ conversationStatus: 'ongoing' }))
  }

  // variables
  const keys = [1, 2, 3, 4, 5, 6, 7, 8, 9, 0, '#', '*']

  return (
    <div className={styles.callActions}>
      <div className={styles.statusContainer}>
        <span>
          {callStarted && typeof confidence === 'number' && confidence >= 0 && (
            <span className={cx({ inactive: conversationStatus !== 'prep' })}>
              Confidence <span className={`${styles.confidence} ${getConfidenceClass(confidence)}`}>{confidence}%</span>
            </span>
          )}
        </span>

        <span className={`${styles.status} ${getStatusClass(conversationStatus)}`}>
          <LoadingAnimation
            width="20"
            marginBottom="5px"
            marginRight="0"
            marginLeft="0"
            bgColor={getStatusColor(conversationStatus)}
          />{' '}
          {getStatusMessage(conversationStatus)}{' '}
          {conversationStatus === 'editing' && <CountdownTimer seconds={30} start={true} callback={countDownHandler} />}
        </span>

        <SwitchBotLink id={id} />
      </div>

      <div
        className={cx(styles.textareaContainer, {
          disabled: !['editing', 'prep'].includes(conversationStatus) && !callTakenOver,
        })}
      >
        <TextareaContainer prep={conversationStatus === 'prep'}>
          <textarea
            name="replyMessage"
            id="replyMessage"
            value={
              callStarted
                ? replyMessage
                : 'Edit response button will be active only when low confidence message occurs.'
            }
            onChange={handleChange}
            data-test="chatTextarea"
          ></textarea>
        </TextareaContainer>

        <div className={styles.keyboard}>
          {keys.map((key) => (
            <button type="button" id={`keyboard-${key}`} key={`keyboard-${key}`} onClick={handleKeyboardPress}>
              {key}
            </button>
          ))}
        </div>
      </div>

      <div className={styles.actions}>
        <div className={cx({ inactive: transferingCall })}>
          {callTakenOver ? (
            <TakeOverActions />
          ) : (
            <>
              {' '}
              {conversationStatus === 'editing' ? (
                <EditResponseActions />
              ) : (
                <>
                  <button
                    className="btn btn-secondary btn-with-icon"
                    disabled={conversationStatus !== 'prep'}
                    onClick={editResponseHandler}
                    // This is used for keyBindings() in the pages/Call/utils.ts
                    id="edit-response"
                  >
                    <div className="keyboardKey">Ｅ</div>
                    <span>Edit Response</span>
                  </button>

                  <button
                    className="btn btn-success btn-with-icon"
                    disabled={conversationStatus !== 'prep'}
                    onClick={approveResponseHandler}
                    // This is used for keyBindings() in the pages/Call/utils.ts
                    id="approve-response"
                  >
                    <div className="keyboardKey">A</div>
                    <span>Approve</span>
                  </button>
                </>
              )}
              {conversationStatus !== 'editing' && (
                <button
                  className="btn btn-secondary btn-with-icon"
                  onClick={takeOverHandler}
                  id="take-over"
                  data-test="takeOverButton"
                >
                  <span>Take Over</span>
                </button>
              )}
            </>
          )}
        </div>

        <div className={styles.actions}>
          {callTakenOver && <NewCurrentClaimButton />}

          {conversationStatus !== 'editing' && <TransferCallButton />}

          <EndCallButton />
        </div>
      </div>
    </div>
  )
}

export { CallActions }
