import store from '../app/store'
import { ICallDeepType } from '../features/calls/typings/callTypes'

export const isOwner = (call?: ICallDeepType | Record<string, never>) => {
  const { alias: curUsername } = store.getState().users?.profile
  const { alias: initiatorUsername } = (call ? call?.assignedTo : store.getState().calls?.call?.assignedTo) || {
    alias: '',
  }

  return curUsername === initiatorUsername
}
