import { Dispatch, SetStateAction, useState, useEffect } from 'react'
import cx from 'classnames'
import { isEmpty, get } from 'lodash'
// components
import { Loading } from 'components'
// styles and assets
import SearchIcon from 'assets/images/search-icon.svg'
import CloseIcon from 'assets/images/close.svg'
import styles from './QueryFilterByValues.module.scss'
// types and utils
import { SHOW_EMPTY_CELLS, formatValue } from 'utils'
// state
import { useAppSelector } from 'app/store'
import { ErrorMessagesEnum } from 'typings'

interface IProps {
  fields: {
    value: string
    selected: boolean
  }[]
  propPath: string
  loading: boolean
  error: string | boolean
  isAllDeselected: boolean
  dialogVisible: boolean
  setFields: Dispatch<SetStateAction<{ value: string; selected: boolean }[]>>
}

const QueryFilterByValues = ({
  propPath,
  fields,
  loading,
  error,
  isAllDeselected,
  dialogVisible,
  setFields,
}: IProps) => {
  // global state
  const { profile } = useAppSelector((state) => state.users)
  const { _default: info } = profile?.configuration?.templates?.call_info || { _default: {} }
  const prop = get(info, propPath, {})

  // local state
  const [searchQuery, setSearchQuery] = useState<false | string>(false)
  const [displayFields, setDisplayFields] = useState(fields)

  // effects
  useEffect(() => {
    if (searchQuery) {
      setDisplayFields(
        [...fields].filter((field) =>
          field?.value?.toString()?.trim().toLowerCase().includes(searchQuery.trim().toLowerCase()),
        ),
      )
    } else if (!isEmpty(fields)) {
      setDisplayFields(fields)
    }

    return () => setDisplayFields([])
  }, [fields, searchQuery])

  useEffect(() => {
    if (!dialogVisible && searchQuery) setSearchQuery(false)
  }, [dialogVisible, searchQuery])

  // actions
  const handleSelectAll = () => {
    setFields((prev) =>
      [...prev].map((field) => {
        if (!field.selected) field.selected = true

        return field
      }),
    )
  }

  const handleClearAll = () => {
    if (!isAllDeselected) {
      setFields((prev) =>
        [...prev].map((field) => {
          if (field.selected) field.selected = false

          return field
        }),
      )
    }
  }

  const handleToggleSelection = (value: string | number) => {
    const index = fields.findIndex((field) => field.value === value)

    setFields((prev) => {
      const res = [...prev]
      res[index] = { ...res[index], selected: !res[index].selected }
      return res
    })
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target
    if (value.trim().length > 0) {
      setSearchQuery(value)
    } else {
      setSearchQuery(false)
    }
  }

  // variables
  const noValueSelected = fields.length > 0 && fields.every((field) => !field.selected)
  const noResultsFound = displayFields.length === 0 && !error

  return (
    <ul className={styles.wrapper} data-test="filterByValues">
      <li>
        <div>
          <h2>Filter by values</h2>

          <div className={styles.container}>
            <ul>
              <li onClick={handleSelectAll} data-test="filterValuesSelectAll">
                Select All
              </li>
              <li>-</li>
              <li onClick={handleClearAll} data-test="filterValuesClear">
                Clear
              </li>
            </ul>

            <div className={`${styles.form} form-control`}>
              <input
                className="form-input"
                type="search"
                name="searchFilterValue"
                id="searchFilterValue"
                onChange={handleChange}
                data-test="searchFilterValue"
                value={searchQuery || ''}
              />

              {searchQuery && searchQuery.trim() && (
                <img
                  data-test="clearSearchInput"
                  src={CloseIcon}
                  className={styles['close']}
                  alt="Search"
                  width="12"
                  height="12"
                  onClick={() => setSearchQuery(false)}
                />
              )}
              <img src={SearchIcon} className={styles['search']} alt="Search" width="16" height="16" />
            </div>

            {noValueSelected && (
              <p className={styles['no-value-selected']} data-test="clearMessage">
                Select at least one option
              </p>
            )}

            <ul className={styles.values} data-test="filterValues">
              {error && <p className="text-error mt-3">{ErrorMessagesEnum.Unknown}</p>}

              {loading ? (
                <Loading width="50" marginBottom="10px" message="Loading values..." />
              ) : noResultsFound ? (
                <p className="text-center mt-2 mb-2">No entries found</p>
              ) : (
                displayFields.map((field, i) => {
                  let value = ''
                  if (field.value === SHOW_EMPTY_CELLS) {
                    value = '[Empty rows]'
                  } else {
                    value = formatValue({ ...prop, value: field.value })
                  }

                  return (
                    <li
                      key={i}
                      className={cx({
                        [styles.selected]: field.selected,
                        'text-italic': field.value === SHOW_EMPTY_CELLS,
                      })}
                      onClick={() => handleToggleSelection(field.value)}
                      data-test={`filterValue${field.selected ? 'Selected' : ''}`}
                    >
                      {value}
                    </li>
                  )
                })
              )}
            </ul>
          </div>
        </div>
      </li>
    </ul>
  )
}

export { QueryFilterByValues }
