import { Link } from 'react-router-dom'
// styles and assets
import ConversationIcon from 'assets/images/conversation.svg'
import styles from './NoContentBlock.module.scss'

interface IProps {
  title?: string
  subTitle?: string
  imgSrc?: string
  btnLink?: string
  btnClassName?: string
  btnText?: string
  className?: string
}

const NoContentBlock = ({ title, subTitle, imgSrc, btnLink, btnClassName, btnText, className }: IProps) => {
  return (
    <div className={`${className ? className : ''} ${styles.wrapper}`}>
      <section>
        <h3 className="mb-4">{title}</h3>
        <div className="mb-2">
          <img src={imgSrc || ConversationIcon} alt="Action Icon" />
        </div>
        <p className="mb-4">{subTitle}</p>

        {btnLink && btnClassName && btnText && (
          <Link to={btnLink} className={btnClassName}>
            {btnText}
          </Link>
        )}
      </section>
    </div>
  )
}

export { NoContentBlock }
