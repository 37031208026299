import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
// types and utils
import {
  ILoginCredentials,
  IUpdateUserRequest,
  IUser,
  IUserProfile,
  ResetErrorEnum,
  ResetSuccessEnum,
  ErrorMessagesEnum
} from 'typings'
import { ErrorHandler } from 'utils'
// services
import { loginApi, updateProfileApi, fetchProfileApi } from 'features/users'

export const login = createAsyncThunk(
  'users/login',
  async ({ usernameField, password }: ILoginCredentials, { rejectWithValue }) => {
    try {
      return await loginApi({ usernameField, password })
    } catch (error: any) {
      // If wrong credentials display the error on the login form
      if (error?.code === 'NotAuthorizedException') return rejectWithValue(true)
      // For other errors show notifications
      ErrorHandler.handleError(error, `${ErrorMessagesEnum.LoadProfile} ${ErrorMessagesEnum.ReportToAdministrator}`)
    }
  },
)

export const updateProfile = createAsyncThunk(
  'users/updateProfile',
  async (updates: IUpdateUserRequest, { rejectWithValue }) => {
    try {
      return await updateProfileApi(updates)
    } catch (error: any) {
      ErrorHandler.handleError(error, `${ErrorMessagesEnum.LoadProfile} ${ErrorMessagesEnum.ReportToAdministrator}`)
      return rejectWithValue(true)
    }
  },
)

export const fetchProfile = createAsyncThunk('users/fetchProfile', async (_, { rejectWithValue }) => {
  try {
    return await fetchProfileApi()
  } catch (error: any) {
    return rejectWithValue(error?.response?.data ? error.response.data : error.message)
  }
})

interface IUserState {
  user: IUser | Record<string, never>
  profile: IUserProfile | Record<string, never>
  loading: {
    login: boolean
    updateProfile: boolean
  }
  error: {
    login: boolean | string
    updateProfile: boolean | string
    fetchProfile: boolean | string
  }
  success: {
    updateProfile: boolean
  }
}

const initialState = {
  user: {},
  profile: {},
  loading: {
    login: false,
    updateProfile: false,
  },
  error: {
    login: false,
    updateProfile: false,
  },
  success: {
    updateProfile: false,
  },
} as IUserState

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserSession: (state, action) => {
      const { currentUser, profile } = action.payload
      state.user = currentUser
      state.profile = profile
    },
    logout: (state) => {
      state.user = {}
      state.profile = {}
    },
    resetSuccess: (state, action: PayloadAction<ResetSuccessEnum>) => {
      if (state.success[action.payload]) state.success[action.payload] = false
    },
    resetError: (state, action: PayloadAction<ResetErrorEnum>) => {
      if (state.error[action.payload]) state.error[action.payload] = false
    },
    updateLocalStateUser: (state, action) => {
      state.user = { ...state.user, ...action.payload }
    },
    setProfile: (state, action: PayloadAction<IUserProfile>) => {
      state.profile = { ...action.payload }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(login.rejected, (state, action) => {
      state.error.login = action.payload as string
      if (state.loading.login) state.loading.login = false
    })
    builder.addCase(login.pending, (state) => {
      state.loading.login = true
      if (state.error.login) state.error.login = false
    })
    builder.addCase(login.fulfilled, (state, action) => {
      if (action.payload) state.user = action.payload
      if (state.error.login) state.error.login = false
      if (state.loading.login) state.loading.login = false
    })
    builder.addCase(fetchProfile.fulfilled, (state, action) => {
      state.profile = action.payload
      if (state.error.fetchProfile) state.error.fetchProfile = false
    })
    builder.addCase(fetchProfile.rejected, (state, action) => {
      state.error.fetchProfile = action.payload as string
    })
    builder.addCase(updateProfile.rejected, (state, action) => {
      state.error.updateProfile = action.payload as string
      if (state.loading.updateProfile) state.loading.updateProfile = false
      if (state.success.updateProfile) state.success.updateProfile = false
    })
    builder.addCase(updateProfile.pending, (state) => {
      state.loading.updateProfile = true
      if (state.error.updateProfile) state.error.updateProfile = false
      if (state.success.updateProfile) state.success.updateProfile = false
    })
    builder.addCase(updateProfile.fulfilled, (state, action) => {
      state.profile = { ...action.payload }
      state.success.updateProfile = true
      if (state.error.updateProfile) state.error.updateProfile = false
      if (state.loading.updateProfile) state.loading.updateProfile = false
    })
  },
})

export const { setUserSession, logout, resetSuccess: resetUserSuccess, updateLocalStateUser, setProfile, resetError } = userSlice.actions

export const usersReducer = userSlice.reducer
