import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { Router } from 'react-router-dom'
// components
import Theme from './components/Theme'
import App from './App'
// styles
import './index.scss'
// types and utils
import { browserHistory } from 'utils'
// state
import store from './app/store'

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Theme>
        <Router history={browserHistory}>
          <App />
        </Router>
      </Theme>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
)
