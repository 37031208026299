// components
import { Nav } from 'components'
// styles
import styles from './Layout.module.scss'

type Props = {
  children: React.ReactNode
}

const Layout = ({ children }: Props) => {
  return (
    <div className={styles.wrapper}>
      <Nav />
      <main>
        <div className="fixed-layout">{children}</div>
      </main>
    </div>
  )
}

export { Layout }
