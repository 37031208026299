import styled, { css, keyframes } from 'styled-components'
import { getTimeoutDelay } from '../../../../utils/conversationProcessor'

const loadingBar = keyframes`
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
`

export const TextareaContainer = styled.div<{
  readonly prep: boolean
}>`
  width: 100%;
  height: 100%;
  position: relative;

  ${({ prep }) =>
    prep &&
    css`
      :before {
        z-index: 1;
        content: '';
        position: absolute;
        top: 0.5px;
        left: 0;
        right: 0;
        width: 100%;
        height: 3px;
        background: rgba(235, 87, 87, 0.43);
        border-radius: 5px 5px 0 0;
      }

      :after {
        z-index: 2;
        content: '';
        position: absolute;
        top: 0.5px;
        left: 0;
        right: 0;
        animation: ${loadingBar} ${getTimeoutDelay() / 1000}s linear;
        height: 3px;
        background: #eb5757;
        border-radius: 5px 5px 0 0;
      }
    `}
`
