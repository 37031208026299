import { useState } from 'react'

export const useModal = () => {
  const [isShown, setIsShown] = useState<{ [k: string]: boolean }>({})
  const toggle = (propPath: string) => setIsShown((prev) => ({ ...prev, [propPath]: !prev[propPath] }))

  return {
    isShown,
    toggle,
  }
}
