import cx from 'classnames';

import './Label.scss';

type Props = { text: string };

const Label = ({ text }: Props) => {
    return <span className={cx(`label label-${text}`)}>{text}</span>;
};

export { Label }
