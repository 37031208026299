import { Fragment } from 'react'
import cx from 'classnames'
import { get } from 'lodash'

// components
import { CallListFilters } from 'features/calls/components'
// styles
import styles from './CallListThead.module.scss'
// types and utils
import { queryStateColumnsFilter } from 'features/calls/utils/callUtils'
// state
import { useAppSelector } from 'app/store'

interface IProps {
  hasActiveModal: boolean
  toggleCallListColumnSelectionModal: () => void
  changeActiveCallHandler: (index: number) => void
  setHasActiveModal: React.Dispatch<React.SetStateAction<boolean>>
}

const CallListThead = ({
  hasActiveModal,
  changeActiveCallHandler,
  toggleCallListColumnSelectionModal,
  setHasActiveModal,
}: IProps) => {
  // global state
  const { filters, columns } = useAppSelector(
    (state) => state.users.profile?.configuration?.callsQuery || { filters: {}, columns: [] },
  )
  const { templates } = useAppSelector((state) => state.users.profile.configuration)
  const { activeQueryState } = useAppSelector((state) => state.calls)

  return (
    <div className="thead">
      <div className="tr">
        <div className="th" data-test="callListTableTh">
          STATUS
        </div>

        {columns
          ?.filter((column) => queryStateColumnsFilter(column.path, activeQueryState))
          .map((column) => {
            const template = templates?.call_info?._default || {}
            const callInfoObj = get(template, column.path, { friendlyName: 'Missing' })
            const { friendlyName } = callInfoObj

            const hasFilter = filters?.[activeQueryState]?.path === column.path
            return (
              <Fragment key={column.path}>
                <div
                  className={cx('th text-capitalize', styles.cell, { [styles.active]: hasFilter })}
                  data-test="callListTableTh"
                >
                  <div className="content" title={friendlyName}>
                    {friendlyName}
                  </div>

                  <CallListFilters
                    propPath={column.path}
                    hasActiveFilter={hasFilter}
                    hasActiveModal={hasActiveModal}
                    setHasActiveModal={setHasActiveModal}
                    changeActiveCallHandler={changeActiveCallHandler}
                  />
                </div>
              </Fragment>
            )
          })}

        <div className="th">
          <div
            className="toggler"
            onClick={toggleCallListColumnSelectionModal}
            id="selection-toggler"
            data-test="selectionToggler"
          >
            <svg width="4" height="20" viewBox="0 0 4 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="2" cy="2" r="2" />
              <circle cx="2" cy="10" r="2" />
              <circle cx="2" cy="18" r="2" />
            </svg>
          </div>
        </div>
      </div>
    </div>
  )
}

export { CallListThead }
