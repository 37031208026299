// styles and assets
import styles from './CallSpectatorView.module.scss'
// state
import { useAppSelector } from 'app/store'

const CallSpectatorView = () => {
  // global state
  const { firstName, lastName } = useAppSelector(
    (state) => state.calls.call?.assignedTo || { firstName: 'First name', lastName: 'Last name' },
  )

  // variables
  const firstNameInitial = firstName[0].toUpperCase()

  return (
    <div className={styles.wrapper}>
      <div>
        <p>
          <span className="avatar-initial mr-2">{firstNameInitial}</span>
          <b>
            {firstName} {lastName}
          </b>{' '}
          is currently monitoring this call
        </p>
      </div>
    </div>
  )
}

export { CallSpectatorView }
