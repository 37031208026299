// types and utils
import { CallStateEnum } from 'features/calls'

interface IObjGeneric {
  [key: string]: any
}

export const convertCallInfoToTemplate = (data: IObjGeneric, template: any) => {
  const root: IObjGeneric = {}
  template?._order?.forEach((group: string) => {
    root[group] = {}

    template[group]?._order?.forEach((prop: any) => {
      root[group][prop] = { value: data[prop] }
    })
  })

  return { root }
}

export const queryStateColumnsFilter = (path: string, activeQueryState: CallStateEnum) => {
  const hideOnPendingQueryState = ['assignedTo', 'conductedDate']
  const hideOnActiveQueryState = ['conductedDate']

  if (activeQueryState === 'pending' && hideOnPendingQueryState.some((item) => path.includes(item))) {
    return false
  }

  if (activeQueryState === 'active' && hideOnActiveQueryState.some((item) => path.includes(item))) {
    return false
  }

  return true
}

export const convertRootToFormData = (root: { [k: string]: any }) => {
  // props and utils
  const groups = root?._order

  const fields = groups
    .map((group: string) => {
      return root[group]?._order
    })
    .flat()
  return fields
    .map((field: string) => ({ [field]: '' }))
    .reduce((cur: { [key: string]: string }, next: { [key: string]: string }) => {
      return { ...cur, ...next }
    }, {})
}

export const convertRootToUploadData = (root: { [key: string]: any }) => {
  return root?._order
    .map((group: string) => {
      const res: { [key: string]: string } = {}
      root[group]?._order.forEach((prop: string) => {
        const propFriendlyName = root?.[group]?.[prop]?.friendlyName
        res[propFriendlyName?.toLowerCase()] = prop
      })
      return res
    })
    .reduce((cur: { [key: string]: string }, next: { [key: string]: string }) => {
      return { ...cur, ...next }
    }, {})
}
