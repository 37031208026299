import { PCMPlayer } from './utils/pcm-player'
import { decode } from './utils/lib_mulaw'
import { getAccessToken, getWsUrl, notifyError } from 'utils'
import { ErrorMessagesEnum } from 'typings'

export let streamMedia: any
let playerInbound: any
let playerOutbound: any

export const getStreamMedia = () => streamMedia

export const initAudioConnection = (id: string) => {
  if (streamMedia) {
    streamMedia.close()
    streamMedia = null
  }

  const socketURLInbound = `${getWsUrl()}/calls/${String(id)}/audio`

  // console.log('>>>> stream url: ' + socketURLInbound)
  if (!socketURLInbound) {
    return
  }

  try {
    // instantiate two pcm players that will play the audio streams through the browser
    playerInbound = new PCMPlayer({
      encoding: '16bitInt',
      channels: 1,
      sampleRate: 8000,
      flushingTime: 1000,
    })
    playerOutbound = new PCMPlayer({
      encoding: '16bitInt',
      channels: 1,
      sampleRate: 8000,
      flushingTime: 1000,
    })

    // connect to the websocket of the inbound call audio
    streamMedia = new WebSocket(socketURLInbound, ['access_token', getAccessToken() || ''])
    streamMedia.binaryType = 'arraybuffer'

    streamMedia.addEventListener('open', function () {
      // console.log('stream opened' + JSON.stringify(event))
    })

    streamMedia.addEventListener('error', function () {
      // console.log('---- error: ' + JSON.stringify(error, null, 2)) //.data.length)
    })

    streamMedia.addEventListener('message', function (msg: { data: any}) {
      // parse the ArrayBuffer
      const strData = new TextDecoder('utf8').decode(msg.data)
      const obj = JSON.parse(strData)

      // play the PCM payload
      const payload = obj.media && obj.media.payload
      if (payload !== undefined && payload.length > 0) {
        // decode the audio (base64 => mulaw => 16bit)
        const data = decode(Buffer.from(payload, 'base64'))

        // need to split the tracks or else the outbound track is choppy.
        if (obj.media.track === 'inbound') {
          playerInbound.feed(data)
        } else {
          playerOutbound.feed(data)
        }
      }
    })

    // when connection is closed (ie call ends)
    streamMedia.addEventListener('close', function (event: Event) {
      console.log('audio stream closed or call ended', event)
    })

    return true
  } catch (error) {
    console.error('something broke' + error)
    notifyError(ErrorMessagesEnum.ConnectAudio)

    return false
  }
}

export const toggleAudio = (isMuted: boolean) => {
  playerInbound?.toggleAudio(isMuted)
  playerOutbound?.toggleAudio(isMuted)
}

export const setAudioVolume = (volume: number) => {
  playerInbound?.volume(volume)
  playerOutbound?.volume(volume)
}

// export const resumeAudioContext = () => {
//   playerInbound?.resumeContext()
//   playerOutbound?.resumeContext()
// }

export const isConnected = () => !!streamMedia

export const endAudioConnection = () => {
  // console.log('safely closing connection')
  if (streamMedia) streamMedia.close()
}
