import { isEmpty } from 'lodash'
// components
import { Layout, VerticalContainer } from 'components'
import { CallUploadForm, CallForm } from 'features/calls/components'
// styles and assets
import styles from './DataHandling.module.scss'
// state
import { useAppSelector } from 'app/store'

const DataHandling = () => {
  // global state
  const { profile } = useAppSelector((state) => state.users)

  return (
    <Layout>
      <div className={styles.wrapper}>
        {!isEmpty(profile) && (
          <>
            <VerticalContainer title="Upload Call Queue File" styles={{ width: 'clamp(20vw, 777px, 40vw)' }}>
              <CallUploadForm />
            </VerticalContainer>

            <VerticalContainer title="Add call to queue">
              <CallForm />
            </VerticalContainer>
          </>
        )}
      </div>
    </Layout>
  )
}

export { DataHandling }
