import { useState, useEffect } from 'react'
import cx from 'classnames'
import { useDialogState, Dialog, DialogDisclosure } from 'reakit/Dialog'
// components
import { QueryFilterModal } from 'features/calls/components'
// styles and assets
import ArrowUp from 'assets/images/polygon_up.svg'
import ArrowDown from 'assets/images/polygon_down.svg'
import Filter from 'assets/images/filter.svg'
import FilterActive from 'assets/images/filter_active.svg'
import styles from './CallListFilters.module.scss'
// types and utils
import { SortOptionsEnum } from 'features/calls'
// state
import { useAppSelector } from 'app/store'
// services
import {getUniqueFilterValuesApi} from 'features/calls'

interface IProps {
  propPath: string
  hasActiveFilter: boolean
  hasActiveModal: boolean
  changeActiveCallHandler: (index: number) => void
  setHasActiveModal: React.Dispatch<React.SetStateAction<boolean>>
}

const CallListFilters = ({
  propPath,
  hasActiveModal,
  hasActiveFilter,
  changeActiveCallHandler,
  setHasActiveModal,
}: IProps) => {
  // global state
  const { activeQueryState } = useAppSelector((state) => state.calls)
  const { filters, sorting } = useAppSelector((state) => state.users.profile.configuration.callsQuery)

  // props and utils
  const dialog = useDialogState({ modal: false })
  const getModalSide = (xPosition: number) => {
    if (xPosition > 900) {
      return 'left'
    } else {
      return 'right'
    }
  }

  // local state
  const [curModalSide, setCurModalSide] = useState('left')
  const [fields, setFields] = useState<any>([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<boolean>(false)

  // effects
  useEffect(() => {
    const getFields = async () => {
      try {
        if (error) setError(false)
        setLoading(true)

        const uniqueValues: (string | number)[] = await getUniqueFilterValuesApi({
          path: propPath,
          state: activeQueryState,
        })

        const updatedFields = uniqueValues.map((value) => ({
          value,
          selected: filters?.[activeQueryState]?.path === propPath && filters?.[activeQueryState]?.values?.includes(value),
        }))

        setFields([...updatedFields])
      } catch (error: any) {
        setError(true)
        if (fields.length) setFields([])
      }
      setLoading(false)
    }

    if (dialog.visible) {
      getFields()
      if (!hasActiveModal) setHasActiveModal(true)
    } else {
      if (hasActiveModal) setHasActiveModal(false)
      if (loading) setLoading(false)
      if (error) setError(false)
    }
  }, [dialog.visible])

  // handlers
  const handleTogglerClick = (e: React.MouseEvent<HTMLSpanElement>) => {
    const modalSide = getModalSide(e.clientX)
    if (curModalSide !== modalSide) {
      setCurModalSide(modalSide)
    }
  }

  // variables
  // check if has an active sort
  const hasActiveSort: false | keyof typeof SortOptionsEnum = sorting?.[activeQueryState]?.path === propPath && sorting?.[activeQueryState]?.sort

  return (
    <div className={styles.wrapper}>
      <DialogDisclosure
        className={cx({ [styles.active]: dialog.visible, [styles['has-filter']]: hasActiveFilter })}
        id="transfer-voice"
        as="span"
        data-test="showFilters"
        onClick={handleTogglerClick}
        {...dialog}
      >
        <img src={hasActiveFilter ? FilterActive : Filter} alt="Filter icon" width="20" height="20" />

        <div className={styles['active-sort-label']} data-test="sortActive">
          {hasActiveSort && !dialog.visible &&
            (hasActiveSort === SortOptionsEnum.ascending ? (
              <img src={ArrowUp} alt="Arrow up" width="15s" height="15s" />
            ) : (
              <img src={ArrowDown} alt="Arrow Down" width="15s" height="15s" />
            ))}
        </div>
      </DialogDisclosure>

      <Dialog
        className={`${styles.modal} ${styles[curModalSide]}`}
        {...dialog}
        aria-label="Filters modal"
        id="filter-modal"
      >
        <QueryFilterModal
          dialog={dialog}
          propPath={propPath}
          changeActiveCallHandler={changeActiveCallHandler}
          fields={fields}
          setFields={setFields}
          loading={loading}
          error={error}
        />
      </Dialog>
    </div>
  )
}

export { CallListFilters }
