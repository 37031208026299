import { useState } from 'react'
// styles and assets
import styles from './NavItemDropdown.module.scss'

interface IProps {
  icon: JSX.Element
  children: JSX.Element[] | JSX.Element
}

const NavItemDropdown = ({ icon, children }: IProps) => {
  const [open, setOpen] = useState(false)

  const onMouseEnter = () => {
    setOpen(true)
  }

  const onMouseLeave = () => {
    setOpen(false)
  }

  return (
    <li className={styles.wrapper} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      <span>{icon}</span>

      {open && <div className={styles.dropdown}> {children}</div>}
    </li>
  )
}

export { NavItemDropdown }
