import { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import cx from 'classnames'
import { useDialogState, Dialog, DialogDisclosure } from 'reakit/Dialog'
import { cloneDeep } from 'lodash'
import { set } from 'lodash'
// styles and assets
import styles from './AddFollowUpButton.module.scss'
// types and utils
import { ICallDeepType } from 'features/calls'
import { ErrorMessagesEnum } from 'typings'
import { getPath, ErrorHandler } from 'utils'
// state
import { useAppDispatch } from 'app/store'
import { resetCall } from 'features/calls'
// services
import { addCallsApi } from 'features/calls'

interface IProps {
  call: ICallDeepType | Record<string, never>
}

const AddFollowUpButton = ({ call }: IProps) => {
  // local state
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [success, setSuccess] = useState(false)
  const [callId, setCallId] = useState('')

  // props and utils
  const dialog = useDialogState({ modal: false })
  const dispatch = useAppDispatch()
  const history = useHistory()

  // local state
  const [data, setData] = useState({
    phoneNumber: call.requestData?.to?.contact?.phoneNumber || '',
    provider: call?.info?.root?.callInformation?.provider?.value || '',
  })

  // effects
  useEffect(() => {
    if (!dialog.visible) {
      if (success) setSuccess(false)
      if (error) setError(false)
    }
  }, [dialog.visible])

  // handlers
  const addCallHandler = async (event: React.ChangeEvent<HTMLFormElement>) => {
    try {
      event.preventDefault()
      setLoading(true)
      if (error) setError(false)
      if (success) setSuccess(false)

      const { phoneNumber, provider } = data

      const body = {
        from: call.requestData.from,
        to: {
          contact: {
            phoneNumber,
          },
        },
        info: cloneDeep(call.info),
      }

      //TODO Implement getPath
      body.info.root.callInformation.phoneNumber.value = phoneNumber
      body.info.root.callInformation.provider.value = provider;

      ['conductedDate', 'uploadedDate', 'assignedTo'].forEach((prop) => {
        if (getPath(body.info, prop).length) set(body.info, getPath(body.info, prop)[0], undefined)
      })

      const { data: calls }: any = await addCallsApi({ calls: [body], bulkProcessing: false })
      const followUpCall: ICallDeepType = calls[0].value

      setCallId(followUpCall._id)
      setLoading(false)
      setSuccess(true)
    } catch (error: any) {
      setLoading(false)
      setSuccess(false)
      setError(true)
      ErrorHandler.handleError(error, ErrorMessagesEnum.AddCall)
    }
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (success) setSuccess(false)
    if (error) setError(false)

    setData((prev) => ({ ...prev, [e.target.id]: e.target.value }))
  }

  const viewCallHandler = (callId: string) => {
    dispatch(resetCall())
    history.push(`/calls/${callId}`)
  }

  return (
    <>
      <DialogDisclosure as="button" className="btn btn-md btn-ternary" {...dialog} data-test="addFollowUpModalToggler">
        Add Follow Up Call
      </DialogDisclosure>

      <div className={cx({ 'modal-container': dialog.visible })}>
        <Dialog className={cx(`modal ${styles.modal}`, { 'has-error': error })} {...dialog} aria-label="End call modal">
          <h2 className="mb-2">Add a follow up call</h2>

          {success ? (
            <>
              <p>Follow up call successfully added</p>

              <button onClick={() => viewCallHandler(callId)} className="btn btn-ternary mx-auto">
                View Call
              </button>
            </>
          ) : (
            <>
              <p>Update the following fields if neccesary</p>

              <form onSubmit={addCallHandler}>
                <div className="form-control" data-test="phoneNumber">
                  <label className="form-label" htmlFor="phoneNumber">
                    Phone Number
                  </label>
                  <input
                    required
                    onChange={handleChange}
                    className="form-input form-input-md"
                    type="text"
                    name="phoneNumber"
                    id="phoneNumber"
                    value={data.phoneNumber}
                  />
                </div>

                <div className="form-control">
                  <label className="form-label" htmlFor="provider">
                    Provider Name
                  </label>
                  <input
                    required
                    onChange={handleChange}
                    className="form-input form-input-md"
                    type="text"
                    name="provider"
                    id="provider"
                    value={data.provider}
                  />
                </div>

                <div className={cx('mt-2', { disabled: loading })}>
                  <button
                    type="submit"
                    className={cx('btn btn-md btn-success mr-2', { 'btn-loading': loading || success })}
                    disabled={loading || success}
                    data-test="addFollowUpButton"
                  >
                    {loading && 'Adding call...'}
                    {success && 'Successfully added'}
                    {!loading && !success && 'Add'}
                  </button>

                  <button type="button" className="btn btn-md btn-ternary" onClick={dialog.hide}>
                    Cancel
                  </button>
                </div>
              </form>
            </>
          )}
        </Dialog>
      </div>
    </>
  )
}

export { AddFollowUpButton }
