// types and utils
import { ErrorMessagesEnum, IError } from 'typings'
import { Notification } from 'utils'
// services
import { userApi } from 'features/users'

export class ErrorHandler {
  public static handleError = (error: any, message?: string) => {
    switch (error?.response?.status) {
      case 404:
        Notification.notifyError(message || ErrorMessagesEnum.NotFound)
        break
      case 401:
        userApi.signOutApi()
        Notification.notifyError(ErrorMessagesEnum.NotAuthenticated)
        break
      case 400:
        Notification.notifyError(message || ErrorMessagesEnum.BadRequest)
        break
      default:
        Notification.notifyError(ErrorMessagesEnum.Unknown)
    }
  }

  public static notifyErrors = (errors: string[], message?: string) => {
    const content = (
      <>
        <p>{message || ErrorMessagesEnum.BadRequestWithMultipleErrors}</p>
        <ul>
          {errors.map((error) => (
            <p key={error} className="text-bold">
              - {error}
            </p>
          ))}
        </ul>
      </>
    )

    Notification.notifyError(content)
  }

  public static getFieldErrors = (fields: string[], errors: IError[], keyPrefix?: string) => {
    const fieldsWithErrors: { [key: string]: string } = {}
    const fieldErrors = errors.filter((error) =>
      fields.some((field) => {
        const hasError = error?.source?.pointer?.includes(field)

        if (hasError) {
          const fieldKey = `${keyPrefix || ''}${field}`
          fieldsWithErrors[fieldKey] = error.title
          return true
        }
        return false
      }),
    )
    const hasFieldErrors = fieldErrors.length > 0

    return { hasFieldErrors, fieldsWithErrors }
  }

  public static getUniqueErrors = (errors: IError[]) => {
    return errors
      .filter((error, index, self) => index === self.findIndex((t) => t.title === error.title))
      .map((error) => error.title)
  }
}
