import { useState, useEffect } from 'react'
import cx from 'classnames'
import { isEmpty } from 'lodash'
// components
// To use when addign 2fa phone number change functionality
// import { BsExclamationOctagon, BsCheckCircle } from 'react-icons/bs'
// styles and assets
import styles from './UserForm.module.scss'
// types and utils
import { ResetSuccessEnum } from 'typings'
// state
import { useAppDispatch, useAppSelector } from 'app/store'
import { updateProfile, resetUserSuccess } from 'features/users'

const UserForm = () => {
  // global state
  const { user, profile } = useAppSelector((state) => state.users)
  const loading = useAppSelector((state) => state.users.loading.updateProfile)
  const error = useAppSelector((state) => state.users.error.updateProfile)
  const success = useAppSelector((state) => state.users.success.updateProfile)
  // props and utils
  const dispatch = useAppDispatch()
  // local state
  const [data, setData] = useState({
    family_name: '',
    given_name: '',
    phone_number: '',
    email: '',
    transfer_phone: '',
  })
  const [isChanged, setIsChanged] = useState(false)

  // effects
  useEffect(() => {
    if (!isEmpty(user)) {
      setData({
        family_name: user.family_name,
        given_name: user.given_name,
        phone_number: user.phone_number,
        email: user.email,
        transfer_phone: profile?.phones?.transfer || user.phone_number,
      })
    }
  }, [user])
  // handlers
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target
    setData({ ...data, [name]: name === 'transfer_phone' ? value.replace(/\s/g, '') : value.trim() })
    if (!isChanged) setIsChanged(true)
  }

  // Updates the profile in the database and local state
  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    dispatch(updateProfile({ phones: { transfer: data.transfer_phone } }))
    setTimeout(() => dispatch(resetUserSuccess(ResetSuccessEnum.updateProfile)), 3000)
  }
  // variables
  const { family_name, given_name, phone_number, email, transfer_phone } = data

  return (
    <form className={styles.form} onSubmit={handleSubmit} data-test="profileForm">
      <div className="form-control">
        <label className="form-label" htmlFor="family_name">
          Email
        </label>
        <input
          type="text"
          className="form-input"
          placeholder="Email"
          name="email"
          id="email"
          data-test="emailProfile"
          defaultValue={email ? email.trim() : ''}
          disabled
        />
      </div>

      <div className="form-control">
        <label className="form-label" htmlFor="phone_number">
          Account phone number
        </label>
        <div className="form-input">
          <input
            type="text"
            placeholder="Phone number"
            name="phone_number"
            id="phone_number"
            data-test="accountPhoneProfile"
            maxLength={15}
            defaultValue={phone_number ? phone_number.trim() : ''}
            disabled
          />
        </div>
      </div>

      <div className="form-control">
        <label className="form-label" htmlFor="family_name">
          Family Name
        </label>
        <input
          type="text"
          className="form-input"
          placeholder="Family Name"
          name="family_name"
          id="family_name"
          data-test="familyNameProfile"
          defaultValue={family_name ? family_name.trim() : ''}
          onChange={handleChange}
          disabled
        />
      </div>

      <div className="form-control">
        <label className="form-label" htmlFor="given_name">
          Given Name
        </label>
        <input
          type="text"
          className="form-input"
          placeholder="Given name"
          name="given_name"
          id="given_name"
          data-test="givenNameProfile"
          defaultValue={given_name ? given_name.trim() : ''}
          onChange={handleChange}
          disabled
        />
      </div>

      <div className="form-control">
        <label className="form-label" htmlFor="transfer_phone">
          Phone Number for Transfers
        </label>
        <div className="form-input">
          <input
            type="text"
            placeholder="Phone number"
            name="transfer_phone"
            id="transfer_phone"
            maxLength={15}
            defaultValue={transfer_phone ? transfer_phone.trim() : ''}
            onChange={handleChange}
            data-test="transferPhoneProfile"
          />
        </div>

        {transfer_phone !== profile?.phones?.transfer && (
          <p className="ml-2">
            Current number: <strong>{profile?.phones?.transfer}</strong>
          </p>
        )}
      </div>

      {error && <p className="text-danger mb-2">{error}</p>}
      <button
        data-test="updateTransferPhoneBtn"
        type="submit"
        className={cx('btn btn-success btn-md mb-4', {
          'btn-loading': loading || success,
          inactive: !isChanged || transfer_phone === profile?.phones?.transfer,
        })}
      >
        {loading && 'Updating...'}
        {success && 'Successfully updated'}
        {!loading && !success && 'Update phone'}
      </button>
    </form>
  )
}

export { UserForm }
