import { lazy, Suspense } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import { isEmpty } from 'lodash'
// components
const Login = lazy(() => import('./Login'))
import { Loading } from 'components'
// pages
import { Admin, LiveCalls, Queue, Call, DataHandling, Analytics, PostCall, Profile } from 'pages'
// types and utils
import { getUserAndProfile } from 'utils'
// state
import { useAppDispatch, useAppSelector } from 'app/store'
import { setUserSession } from 'features/users'

const Router = () => {
  // global state
  const { profile, user } = useAppSelector((state) => state.users)

  // props and utils
  const dispatch = useAppDispatch()
  const loggedIn = sessionStorage.getItem('accessToken') && sessionStorage.getItem('profile')
  if (loggedIn && (isEmpty(profile) || isEmpty(user))) {
    const { currentUser, profile } = getUserAndProfile()
    dispatch(setUserSession({ currentUser, profile }))
  }

  return (
    <Suspense fallback={<Loading />}>
      <Switch>
        {/* Sign in page */}
        <Route path="/login">{loggedIn ? <Redirect to="/" /> : <Login />}</Route>

        {/* Admin routes */}
        <Route exact path="/admin">
          {loggedIn && profile?.role === 'admin' ? <Admin /> : <Redirect to="/login" />}
        </Route>

        {/* Signed in routes */}
        {!loggedIn && <Redirect to="/login" />}
        <Route exact path="/data" component={DataHandling} />
        <Route exact path="/profile" component={Profile} />
        <Route exact path="/analytics" component={Analytics} />
        <Route exact path="/live-calls/:id" component={LiveCalls} />
        <Route exact path="/calls/:id/results" component={PostCall} />
        <Route exact path="/calls/:id" component={Call} />
        <Route path="/" component={Queue} />
      </Switch>
    </Suspense>
  )
}

export default Router
