interface IAwsConfig {
  region?: string
  userPoolId?: string
  userPoolWebClientId?: string
}

export const awsConfig: IAwsConfig = {
  region: process.env.REACT_APP_region,
  userPoolId: process.env.REACT_APP_userPoolId,
  userPoolWebClientId: process.env.REACT_APP_userPoolWebClientId,
}

export default { awsConfig }
