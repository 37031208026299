// components
import { Layout, NoContentBlock } from 'components'
// styles and assets
import AnalyticsIcon from 'assets/images/analytics.svg'
// import styles from './Analytics.module.scss'

const Analytics = () => {
  return (
    <Layout>
      <NoContentBlock
        title="Analytics page coming soon"
        subTitle="See your call performance with virtual agents"
        imgSrc={AnalyticsIcon}
        btnLink="/"
        btnClassName="btn btn-success btn-md"
        btnText="View Call Queue"
      />
    </Layout>
  )
}

export { Analytics }
