import { Menu as ReactMenu, MenuItem, MenuButton, MenuHeader, MenuDivider } from '@szhsin/react-menu'
// components
import { ExportCSV, ExportPDF } from 'features/calls/components'
// styles and assets
import { ReactComponent as EllipsesIcon } from 'assets/images/ellipses.svg'
import { colorGrey32 } from 'styles/variables'
// types and utils
import { IEntities } from 'features/calls'
interface IProps {
  data: IEntities
}

const Menu = ({ data }: IProps) => {
  return (
    <ReactMenu
      menuButton={
        <MenuButton>
          {/*TODO Use theme here instead of importing a constant */}
          <EllipsesIcon fill={colorGrey32} />
        </MenuButton>
      }
      align="start"
      direction="left"
      transition
    >
      <MenuItem className="inactive">Edit</MenuItem>
      <MenuDivider />
      <MenuHeader>Export</MenuHeader>
      <MenuItem>
        <ExportCSV data={data} />
      </MenuItem>
      <MenuItem>
        <ExportPDF data={data} />
      </MenuItem>
      <MenuDivider />
      <MenuHeader>Process</MenuHeader>
      <MenuItem className="inactive">Send to API</MenuItem>
    </ReactMenu>
  )
}

export { Menu }
