import { useState, useEffect, useContext } from 'react'
import { isEmpty } from 'lodash'
import { ThemeContext } from 'styled-components'
import cx from 'classnames'
// Components
import { Timer, Loading, Error } from 'components'
import { CallDisplay, CallDetails } from 'features/calls/components'
// styles and assets
import styles from './LiveCallTabs.module.scss'
// types and utils
import { ICallDeepType } from 'features/calls'
import { ErrorMessagesEnum } from 'typings'
// state
import { useAppSelector, useAppDispatch } from 'app/store'
import { getCall, resetCall } from 'features/calls'

interface IProps {
  children: ICallDeepType[]
  tab?: string
}

const LiveCallTabs = ({ tab, children }: IProps) => {
  // global state
  const { call, calls } = useAppSelector((state) => state.calls)
  const loading = useAppSelector((state) => state.calls.loading.getCall)
  const error = useAppSelector((state) => state.calls.error.getCall)
  const theme = useContext(ThemeContext)

  // props and utils
  const dispatch = useAppDispatch()

  // local state
  const [activeTab, setActiveTab] = useState(tab || children[0]?._id)

  // effects
  useEffect(() => {
    const getCallPromise = calls[0].state === 'active' ? dispatch(getCall(activeTab)) : null

    return () => {
      dispatch(resetCall())
      getCallPromise?.abort()
    }
  }, [activeTab, calls])

  //* variables
  const errorBgColor = theme?.[theme?.current]?.colors?.white

  return (
    <div className={styles.wrapper}>
      <nav className={styles.nav}>
        <ul>
          {[...children]
            // Show calls who started earlier first
            .sort((a, b) => a.startTime && b.startTime && -a.startTime.localeCompare(b.startTime))
            .map((call: ICallDeepType) => {
              return (
                <li
                  key={call._id}
                  className={cx({ [styles.active]: call._id === activeTab })}
                  onClick={() => setActiveTab(call._id)}
                >
                  <span>
                    <span className={`${styles.status}`} />
                    {call.info?.root?.callInformation?.plan.value || 'Missing'}
                  </span>

                  <span>{true && <Timer startTime={call?.startTime || new Date()} format="hh:mm:ss" />}</span>
                </li>
              )
            })}
        </ul>
      </nav>

      <div className={styles.callDisplay}>
        {loading && <Loading width="100" message="Fetching your active calls" />}

        {error && (
          <Error
            title={ErrorMessagesEnum.CallNotFound}
            message={ErrorMessagesEnum.ReportToAdministrator}
            btnLink="/"
            bgColor={errorBgColor}
            btnClassName="btn btn-ternary-reverse"
            btnText="Go to Call Queue"
          />
        )}

        {!isEmpty(call) && (
          <>
            <CallDetails call={call} showHeader={true} hasPageNav={true} />

            <CallDisplay call={call} isLiveCallsPage={true} />
          </>
        )}
      </div>
    </div>
  )
}

export { LiveCallTabs }
