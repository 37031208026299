// components
import { AddPendingCallButton, AddFollowUpButton, TakeOwnershipButton } from 'features/calls/components'
// styles and assets
import styles from './PostCallActions.module.scss'
// types and utils
import { ICallDeepType } from 'features/calls'
// state
import { isEmpty } from 'lodash'

interface IProps {
  call: ICallDeepType | Record<string, never>
}

const PostCallActions = ({ call }: IProps) => {
  return (
    <div className={styles.wrapper}>
      <h3 className="mb-2">Actions</h3>
      {!isEmpty(call) && (
        <div className={styles.actions}>
          <AddFollowUpButton call={call} />
          <AddPendingCallButton call={call} />
          <TakeOwnershipButton call={call} />
        </div>
      )}
    </div>
  )
}

export { PostCallActions }
