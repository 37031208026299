// styles and assets
import styles from './ChatEvent.module.scss'
// types and utils
import { IItem } from 'typings'
import { getLabelFromKind, dateDiff, dateToHHMMSS } from 'utils'

interface IProps {
  item: IItem
  callStartTime: string
}

const ChatEvent = ({ item, callStartTime }: IProps) => {
  // Avoid unnecessary renders and logic for performance
  if (
    ![
      'take_over',
      'replace_message',
      'transfer_call',
      'ignore_message',
      'flag_message',
      'switch_va',
      'new_current_claim',
    ].includes(item.event?.kind || '')
  ) {
    return <></>
  }

  // variables
  const kind = item.event?.kind
  const eventTime = dateToHHMMSS(dateDiff(new Date(item.createdAt), new Date(callStartTime)))

  return (
    <p className={styles.wrapper} data-test={kind}>
      <span>
        {getLabelFromKind(kind)} @ {eventTime}
      </span>
      {/* Used for the scroll offset */}
      <span className={styles.scrollToSpan} id={item._id} />
    </p>
  )
}

export { ChatEvent }
