/* ------------------------------------------------------------ *\
	Variables
\* ------------------------------------------------------------ */

// $font-roboto: 'Roboto', sans-serif;

// $font-size-base: 15px;
// $line-height-base: 1.5;

export const topnavHeight = '61px'
export const queueNavHeight = '50px'
export const queueTheadHeight = '49px'
// export const fixedLayoutHeight = 'calc(100vh - #{$topnav-height})'
// export const livecalls-nav-height =  '45px';
// export const call-actions-height =  '223.16px';

// $responsive-sm: 1023px;
// $responsive-md: 1200px;
// $responsive-lg: 1350px;

// $tablet-portrait: '(max-width: #{$responsive-sm}) ';
// $small-desktop: '(max-width: #{$responsive-md}) ';
// $large-desktop: '(max-width: #{$responsive-lg}) ';
// $retina: '(min-resolution: 2dppx) ';

export const colorWhite = '#fff'
export const colorGrey32 = '#32325d'
export const colorGreyE4 = '#e4e4e4'
export const colorGreyF3 = '#f3f4fa'
export const colorGreyD2 = '#d2d5e2'

// $color-blue-1C: #1c5289;
// $color-blue-00: #007bc0;
// $color-blue-6B: #6bb2da;
// $color-blue-F0: #f0f7fb;

// $color-purple-92: #9298bb;

// $color-cream-FF: #ff978a;
// $color-cream-F2: #f2994a;

// $color-green-62: #62b08f;

// $bg-primary: #2d9cdb;
// $bg-danger: #eb5757;
// $bg-success: #27ae60;
// $bg-secondary: #5e72e4;
// $bg-white: #e5e5e5;
