import { flatten, isEmpty } from 'lodash'
// types and utils
import { IProperty } from '../features/calls/typings/callTypes'
import { formatDateSeparator, formatDateWithAMPM } from './date'

// local types
export enum UsedValuesEnum {
  propName = 'propName',
  slotMapping = 'slotMapping',
  friendlyName = 'friendlyName',
}

// constants
const SEPARATOR = '/'

export const numberWithCommas = (value: string) => {
  const parts = value.toString().split('.')
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  return parts.join('.')
}

export const formatCurrency = (value: string) => {
  // Empty values '' return NaN so is better to catch them here
  if (isNaN(+value) || isEmpty(value)) return value

  return `$${numberWithCommas(parseFloat(value).toFixed(2))}`
}

export const formatValue = (callInfo: IProperty, separator?: string) => {
  const { value, kind, format } = callInfo

  if (value === undefined) return ''

  const valueAsString = value.toString()
  
  switch (kind) {
    case 'date':
      return format === 'dateWithTime'
        ? formatDateWithAMPM(valueAsString, separator || SEPARATOR)
        : formatDateSeparator(valueAsString, separator || SEPARATOR)

    case 'number':
      return format === 'currency' ? formatCurrency(valueAsString) : valueAsString
    default:
      return valueAsString
  }
}

export const flattenInfoRootByKind = (root: any, kind: keyof typeof UsedValuesEnum) => {
  const groups = root?._order || []
  switch (kind) {
    case UsedValuesEnum.propName:
      return flatten(
        groups.map((group: string) => {
          return root[group]?._order
        }),
      )
    case UsedValuesEnum.friendlyName:
    case UsedValuesEnum.slotMapping:
      return flatten(
        groups.map((group: string) => {
          return root[group]._order.map((propName: string) => root[group][propName][kind])
        }),
      )
    default:
      return []
  }
}

export const flattenInfoRootEditableProps = (root: any) => ({
  propName: flattenInfoRootByKind(root, UsedValuesEnum.propName),
  friendlyName: flattenInfoRootByKind(root, UsedValuesEnum.friendlyName),
  slotMapping: flattenInfoRootByKind(root, UsedValuesEnum.slotMapping),
})
