import styled from 'styled-components'
import { queueNavHeight, topnavHeight, queueTheadHeight } from 'styles/variables'

export const Table = styled.div<{ columns: number; isOverFlowHidden: boolean; blurred: boolean }>`
  height: calc(100vh - ${queueNavHeight} - ${topnavHeight});
  line-height: 19px;
  margin-bottom: 10px;
  overflow: ${({ isOverFlowHidden }) => (isOverFlowHidden ? 'hidden' : 'auto')};
  display: grid;
  grid-auto-columns: 1fr;
  grid-template-rows: minmax(min-content, max-content) 1fr;
  text-align: left;
  position: relative;

  .tr {
    display: grid;
    grid-template-columns: repeat(${({ columns }) => columns}, minmax(175px, 1fr));
    grid-auto-rows: 1fr;
    width: 100%;
    position: relative;
    background: #fff;
    min-height: 50px;
    cursor: pointer;
    border-bottom: 1px solid #d2d5e2;

    &.selected {
      font-weight: 400;
      background: #f0f7fb;
      position: sticky;
      top: calc(${queueTheadHeight} + 1px);
      bottom: 0;
      z-index: 1;
    }

    &:not(.selected):hover {
      opacity: 0.9;
      background: lighten(#e4e4e4, 5%);
    }

    .th,
    .td {
      display: flex;
      align-items: center;
      text-align: left;
      padding: 5px 10px;
      border-right: 1px solid #d2d5e266;
      text-overflow: ellipsis;
      white-space: nowrap;

      &:first-child {
        padding-left: 20px;
        margin-right: 0;
        display: flex;
      }
    }

    .th .content {
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .td {
      display: inline-block;
      vertical-align: middle;
      overflow: hidden;
      line-height: 49px;
    }

    .th:last-child {
      width: 30px;
      margin-left: auto;
    }
  }

  .thead .tr {
    cursor: default;

    &:hover {
      background: #f3f4fa;
      opacity: 1;
    }
  }

  .thead {
    position: sticky;
    top: 0;
    z-index: 2;
    font-weight: bold;
    // Added because the backdrop-filter on the CallListColumnSelectionModal was affecting the Drag and Drop
    filter: ${({ blurred }) => (blurred ? 'blur(3px)' : 'none')};

    .tr {
      background: #f3f4fa;
      font-size: 14px;
      line-height: 16px;
      color: rgba(50, 50, 93, 0.58);
    }

    .tr .th:nth-child(1),
    .tr .th:nth-child(2) {
      position: sticky;
      background: #f3f4fa;
      left: 0;
      top: 0;
      z-index: 2;
    }

    .tr .th:nth-child(2) {
      left: 160px;
    }

    .tr .th:last-child {
      position: sticky;
      background: transparent;
      right: 0;
      top: 0;
      justify-content: flex-end;
    }
  }

  .tbody {
    position: relative;
    // Added because the backdrop-filter on the CallListColumnSelectionModal was affecting the Drag and Drop
    filter: ${({ blurred }) => (blurred ? 'blur(3px)' : 'none')};

    .tr {
      .td:nth-child(1),
      .td:nth-child(2) {
        position: sticky;
        left: 0;
        background: #fff;
      }

      .td:nth-child(2) {
        left: 160px;
      }

      &.selected {
        .td:nth-child(1):before {
          content: '';
          position: absolute;
          left: 0;
          top: -1px;
          bottom: -1px;
          width: 10px;
          background: #eb5757;
          opacity: 0.5;
          z-index: -1;
        }

        &.pending .td:nth-child(1):before {
          background: #2d9cdb;
        }

        &.closed .td:nth-child(1):before {
          background: #27ae60;
        }

        .td:nth-child(1),
        .td:nth-child(2) {
          background: #f0f7fb;
        }
      }

      &:not(.selected):hover .td:nth-child(1) {
        opacity: 0.9;
        background: lighten(#e4e4e4, 5%);
      }
    }
  }

  .toggler {
    padding: 5px 20px;
    cursor: pointer;
    border-radius: 3px;
    background: #f3f4fa;
    position: relative;
    right: -8px;
    transform: scale(1.1);

    svg {
      fill: rgba(50, 50, 93, 0.58);
      pointer-events: none;
    }
  }

  .callList-bottom-container {
    position: relative;
    max-width: 500px;
    position: sticky;
    left: 33vw;
    width: 300px;
  }
`
