import { useState, useEffect } from 'react'
import cx from 'classnames'
// styles and assets
import Toggler from 'assets/images/toggler.svg'
import AngleRight from 'assets/images/angle_right.svg'
import TogglerPlus from 'assets/images/toggler-plus.svg'
import styles from './CallInfo.module.scss'
// types and utils
import { IProperty } from 'features/calls'
import { formatValue } from 'utils'
// state
import { useAppDispatch, useAppSelector } from 'app/store'
import { updateConversationStatus } from 'features/conversation'

type Props = {
  rows: IProperty[]
  title: string
  callState: string
}

const CallInfo = ({ title, rows, callState }: Props) => {
  // global state
  const { callTakenOver, conversationStatus } = useAppSelector((state) => state.conversation.status)
  // props and utils
  // Some groups should start closed
  const initialOpenState = !['FOLLOW UP', 'SELF'].includes(title)
    ? true
    : // Some groups be closes by default on closed and active calls
    ['active', 'closed'].includes(callState) && ['FOLLOW UP'].includes(title)
    ? true
    : false
  const dispatch = useAppDispatch()

  // local state
  const [open, setOpen] = useState(initialOpenState)
  const [interventionMode, setInterventionMode] = useState(false)

  // effects
  useEffect(() => {
    if (callTakenOver && !interventionMode) setInterventionMode(true)

    if (!callTakenOver && interventionMode) setInterventionMode(false)
  }, [callTakenOver])

  useEffect(() => {
    if (['editing'].includes(conversationStatus) && !interventionMode) setInterventionMode(true)

    if (!callTakenOver && !['editing'].includes(conversationStatus) && interventionMode) setInterventionMode(false)
  }, [conversationStatus])

  // handlers
  const toggleOpen = () => setOpen((prevOpen) => !prevOpen)

  const handleClick = (message: string | number | undefined) => {
    if (interventionMode) {
      dispatch(updateConversationStatus({ replyMessage: message }))
    }
  }

  return (
    <details className={styles.claim} open={initialOpenState}>
      <summary onClick={toggleOpen}>
        <h3>
          {title} <img src={open ? Toggler : TogglerPlus} alt="Toggler" />
        </h3>
      </summary>
      <ul className={cx({ [styles['intervention-mode']]: interventionMode })}>
        {rows?.map((row, i) => {
          // early returns
          if (row.isHidden) return null

          const value = formatValue(row)
          return (
            <li key={`title${i}`}>
              <div onClick={() => row.value !== 'Unavailable' && handleClick(value)}>
                <strong className="text-capitalize">{row.friendlyName}</strong>
                <span className={cx({ [styles.unavailable]: row.value === 'Unavailable' })}>{value}</span>
              </div>

              {row.value !== row.valueInit && (
                <div className={`fade-in ${styles['previous-value']}`} onClick={() => handleClick(value)}>
                  <span>
                    <img src={AngleRight} alt="Angle right icon" /> Previously
                  </span>
                  {/* Replace value with valueInit in order to use the same format function */}
                  <span>{formatValue({ ...row, value: row.valueInit })}</span>
                </div>
              )}
            </li>
          )
        })}
      </ul>
    </details>
  )
}

export { CallInfo }
