import { useState } from 'react'
// components
import { Layout } from 'components'
import { CallDetails, QueueFilters } from 'features/calls/components'
import { CallsGrid } from 'pages/Queue'
// styles
import styles from './Queue.module.scss'
// state
import { useAppSelector } from 'app/store'

const Queue = () => {
  // global state
  const { calls } = useAppSelector((state) => state.calls)
  // local state
  const [activeIndex, setActiveIndex] = useState<number>(0)
  const [initialCallsLoaded, setInitialCallsLoaded] = useState(false)
  // handlers
  const changeActiveCall = (index: number) => {
    setActiveIndex(index)
  }

  return (
    <Layout>
      <div className={styles.wrapper}>
        <section>
          <QueueFilters changeActiveCallHandler={changeActiveCall} setInitialCallsLoaded={setInitialCallsLoaded} />

          <CallsGrid
            activeIndex={activeIndex}
            changeActiveCallHandler={changeActiveCall}
            initialCallsLoaded={initialCallsLoaded}
            setInitialCallsLoaded={setInitialCallsLoaded}
          />
        </section>

        <CallDetails call={calls[activeIndex]} showButton={true} showHeader={true} />
      </div>
    </Layout>
  )
}

export { Queue }
