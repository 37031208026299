// components
import { Layout } from 'components'
import { AdminEditCallInfo } from 'features/users/components'

const Admin = () => {
  return (
    <Layout>
      <AdminEditCallInfo />
    </Layout>
  )
}

export { Admin }
