// import styles from './ConfirmationModal.module.scss'

interface IProps {
  onConfirm: (propPath: string) => Promise<void>
  onCancel: (propPath: string) => void
  propPath: string
  message: string
  visible: boolean
}

const ConfirmationModal = ({ onConfirm, onCancel, visible, message, propPath }: IProps) => {
  return visible ? (
    <div className="modal-container">
      <div className="modal" aria-label="Remove column modal">
        <p className="mb-2">{message}</p>

        <div>
          <button
            id="end-call-button"
            className="btn btn-secondary mr-2"
            onClick={() => onCancel(propPath)}
            data-test="removeColumnButton"
          >
            Cancel
          </button>
          <button className="btn btn-success" onClick={() => onConfirm(propPath)}>
            Ok
          </button>
        </div>
      </div>
    </div>
  ) : null
}

export { ConfirmationModal }
