import ReconnectingWebSocket from 'reconnecting-websocket'
import { set, cloneDeep } from 'lodash'
// types and utils
import { IMessage, IEvent, IEventEnum, ErrorMessagesEnum, IItem, IEventMessage } from 'typings'
import { browserHistory, removeXMLTags, notifyError, getWsUrl, getTimeoutDelay, getAccessToken } from 'utils'
// state
import store from 'app/store'
import { addItem, setLastAgentItem, updateConversationStatus, createNewConversation } from 'features/conversation'
import { updateCallRootInfo } from 'features/calls'

// local state
let itemTimeout: number | null
// Called when the 3 seconds timeout finishes
const handleMessage = (item: IItem) => {
  const { conversationStatus } = store.getState().conversation.status

  if (conversationStatus === 'editing') {
    itemTimeout && window.clearTimeout(itemTimeout)
  } else {
    store.dispatch(addItem(item))
    store.dispatch(updateConversationStatus({ conversationStatus: 'ongoing' }))
  }
}

export let socket: ReconnectingWebSocket | null = null

/** Init socket connection and listeners */
export const connect = (id: string) => {
  try {
    console.log('events WS >>', `${getWsUrl()}/calls/${id}/events`)
    console.log(`'access_token', storage.getAccessToken()`, 'access_token', getAccessToken())
    socket = new ReconnectingWebSocket(`${getWsUrl()}/calls/${id}/events`, ['access_token', getAccessToken()])

    initiateListeners(id)
  } catch (error: any) {
    console.log(error?.response)
    notifyError(ErrorMessagesEnum.MessagesSocketClosed)
  }
}

/** Disconnect socket */
export const disconnect = () => {
  if (socket && ![WebSocket.CLOSED, WebSocket.CLOSING].includes(socket.readyState)) {
    console.log('closing socket on component unmount')
    socket.close()
  }
}

export const initiateListeners = (id: string) => {
  if (!socket) return

  socket.onmessage = (request) => {
    // console.log('Websocket event received!')

    const result: IEvent = JSON.parse(request.data)
    const { event, data: items } = result

    if (event === 'item_added') {
      items.forEach((item: IItem) => {
        if (socket && ![WebSocket.CLOSED, WebSocket.CLOSING].includes(socket.readyState)) {
          if (item.kind === 'message') {
            if (item.fromParticipantIndex === 1) {
              store.dispatch(addItem(item))
            } else {
              const { conversationStatus, callTakenOver, callTransferred } = store.getState().conversation.status
              // If the items is sent by the driver or higher participant (like admin) add the item directly
              // Fix bug where the call is transferred but not showing the fromParticipantIndex === 0 items
              if (item.fromParticipantIndex >= 2 || (callTransferred && item.fromParticipantIndex === 0)) {
                store.dispatch(addItem(item))
                store.dispatch(updateConversationStatus({ conversationStatus: 'ongoing' }))
                // If the call is taken over or the message is aborted don't add id to the UI
              } else if (
                (!['aborted'].includes(item.state) && !callTakenOver && !callTransferred) ||
                item.metadata?.doNotAbort
              ) {
                store.dispatch(setLastAgentItem(item))

                const msgConfidence = Math.round(item.messages[item.effectiveMessageIndex].confidence * 100)
                const isEditable = +msgConfidence < 90

                //* Should I accept incoming messages when I am editing a message?
                if (conversationStatus !== 'editing') {
                  const replyMessage = removeXMLTags(item.messages[item.effectiveMessageIndex].text)
                  store.dispatch(
                    updateConversationStatus({
                      conversationStatus: 'prep',
                      replyMessage,
                      confidence: msgConfidence,
                    }),
                  )

                  if (isEditable) {
                    /** getTimeoutDelay() checks the last(state.conversation.conversations) participants.bot.provider and selects the timeout from the state.users.profile.serviceProviders, has a default 2000ms if the config does not exist */
                    itemTimeout = window.setTimeout(handleMessage, getTimeoutDelay(), item)
                  } else {
                    store.dispatch(addItem(item))
                    store.dispatch(updateConversationStatus({ conversationStatus: 'ongoing' }))
                  }
                }
              }
            }
          } else if (item.kind === 'event') {
            // console.log('new event', item)
          } else {
            // console.log(`item with kind not 'message' or 'event'`, item)
          }
        }
      })
    }

    if (event === IEventEnum.call_info_updated) {
      const newInfo = cloneDeep(store.getState().calls.call.info.root)

      // update the call info props values with the new received values
      items.forEach((item: { [path: string]: { value: string; valueOld: string } }) => {
        Object.keys(item).forEach((path) => {
          const fullPath = `${path}.value`
          set(newInfo, fullPath, item[path].value)
        })
      })

      store.dispatch(updateCallRootInfo(newInfo))
    }

    // Used for take over and call transferred
    if (event === IEventEnum.conversation_transferred) {
      const { service, partyType, role } = items?.[0]?.participants?.[0] || {}
      store.dispatch(createNewConversation({ service, partyType, role }))
    }

    if (event === IEventEnum.call_ended) {
      console.log('call_ended event received, closing connection and redirecting to post call page')
      socket?.close()
      socket = null

      store.dispatch(
        updateConversationStatus({
          conversationStatus: 'idle',
          callStarted: false,
          replyMessage: 'Call ended',
          endingCall: 'true',
        }),
      )

      browserHistory.push(`/calls/${id}/results`)
    }
  }

  socket.onopen = () => {
    // console.log('messages web socket connected')
  }

  socket.onclose = (event: any) => {
    console.log(`socket.onclose event >>`, event)

    if (store.getState().conversation.status.callStarted) {
      store.dispatch(
        updateConversationStatus({
          conversationStatus: 'disconnected',
          replyMessage: 'Connection lost. Please refresh the browser to try again or contact the administrator',
        }),
      )

      notifyError(ErrorMessagesEnum.MessagesSocketClosed)
    }
  }
}

/** Clear timeout of sending the current pending item */
export const clearItemTimeout = () => {
  itemTimeout && window?.clearTimeout(itemTimeout)
  itemTimeout = null
}

/** Send a new event/message */
export const sendMessage = (message: IEventMessage | IMessage) => {
  // console.log(`sending an ${message.event} event`, message.data)

  socket?.send(JSON.stringify(message))
}