import { useEffect, useState } from 'react'
import { isEmpty } from 'lodash'
// components
import { CallListColumnSelectionModal, CallListTbody } from 'features/calls/components'
import { CallListThead } from 'features/calls/components'
// styles
import { Table } from './CallsGrid.styled'
// types and utils
import { IGetCalls, queryStateColumnsFilter } from 'features/calls'
// state
import { useAppDispatch, useAppSelector } from 'app/store'
import { getCallsAndReplace, resetCalls } from 'features/calls'

interface IProps {
  activeIndex: number
  initialCallsLoaded: boolean
  setInitialCallsLoaded: React.Dispatch<React.SetStateAction<boolean>>
  changeActiveCallHandler: (index: number) => void
}

export const CallsGrid = ({
  activeIndex,
  initialCallsLoaded,
  setInitialCallsLoaded,
  changeActiveCallHandler,
}: IProps) => {
  // global state
  const { limit } = useAppSelector((state) => state.calls.pagination)
  const { activeQueryState, calls } = useAppSelector((state) => state.calls)
  const { callsQuery } = useAppSelector(
    (state) =>
      state.users.profile?.configuration || {
        callsQuery: {},
      },
  )

  // props and utils
  const dispatch = useAppDispatch()

  // local state
  const [showCallListColumnSelectionModal, setShowCallListColumnSelectionModal] = useState(false)
  // Used to make overflow: hidden when a filter modal is active
  const [hasActiveModal, setHasActiveModal] = useState(false)

  // effects
  useEffect(() => {
    const body: IGetCalls = {
      startIndex: 0,
      limit,
      state: [activeQueryState],
    }

    const getCallsPromise = dispatch(getCallsAndReplace(body))
    getCallsPromise.then(() => {
      setInitialCallsLoaded(true)
    })

    return () => {
      dispatch(resetCalls())
      getCallsPromise?.abort()
    }
  }, [activeQueryState])

  useEffect(() => {
    setHasActiveModal(showCallListColumnSelectionModal ? true : false)
  }, [showCallListColumnSelectionModal])

  // handlers
  const toggleCallListColumnSelectionModal = () => {
    // Remove currently selected call because it is positionited sticky
    changeActiveCallHandler(-1)

    setShowCallListColumnSelectionModal((prev) => !prev)
  }

  const hideSelectionModal = () => {
    setShowCallListColumnSelectionModal(false)
  }

  // variables
  const totalTableColumns = () => {
    let total =
      callsQuery?.columns?.filter((column) => queryStateColumnsFilter(column.path, activeQueryState)).length || 0
    // add the status and toggler extra columns
    total += 2

    return total
  }

  return (
    <Table
      columns={totalTableColumns()}
      isOverFlowHidden={hasActiveModal}
      blurred={showCallListColumnSelectionModal}
      id="calllist-table"
      data-test="calllist"
    >
      {showCallListColumnSelectionModal && <CallListColumnSelectionModal hideSelectionModal={hideSelectionModal} />}

      {!isEmpty(callsQuery) && (
        <CallListThead
          toggleCallListColumnSelectionModal={toggleCallListColumnSelectionModal}
          changeActiveCallHandler={changeActiveCallHandler}
          setHasActiveModal={setHasActiveModal}
          hasActiveModal={hasActiveModal}
        />
      )}

      <CallListTbody
        active={activeIndex}
        calls={calls}
        changeActiveCallHandler={changeActiveCallHandler}
        initialCallsLoaded={initialCallsLoaded}
      />
    </Table>
  )
}
