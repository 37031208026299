import { Auth } from 'aws-amplify'
//* types and utils
import { ILoginCredentials, ILoginResponse, IUpdateUserRequest, IUserProfile, ErrorMessagesEnum } from 'typings'
import { axios, notifyError, setUser, setProfile, getAccessToken, setAccessToken, removeUser } from 'utils'
// state
import store from 'app/store'
import { logout } from 'features/users'

export const loginApi = async ({ usernameField, password }: ILoginCredentials) => {
  const user: ILoginResponse = await Auth.signIn(usernameField, password)
  const {
    username,
    attributes: { email, email_verified, family_name, given_name, phone_number, phone_number_verified },
  } = user
  const currentUser = {
    username,
    email,
    email_verified,
    family_name,
    given_name,
    phone_number,
    phone_number_verified,
  }

  setAccessToken(user?.signInUserSession?.accessToken?.jwtToken)
  setUser(currentUser)
  await fetchProfileApi()

  return currentUser
}

export const updateProfileApi = async (updates: IUpdateUserRequest) => {
  const { data: updatedProfile }: { data: IUserProfile } = await axios.put(`/user`, updates, {
    headers: { Authorization: getAccessToken() },
  })

  setProfile(updatedProfile)

  return updatedProfile
}

export const updateMasterProfileApi = async (updates: IUpdateUserRequest) => {
  const { data: updatedProfile }: { data: IUserProfile } = await axios.put(`/admin`, updates, {
    headers: { Authorization: getAccessToken() },
  })

  setProfile(updatedProfile)

  return updatedProfile
}

export const fetchProfileApi = async () => {
  /** Getting user configuration */
  const { data: profile }: { data: IUserProfile } = await axios.get(`/user`, {
    headers: { Authorization: getAccessToken() },
  })

  /** Update session storage user profile */
  setProfile(profile)

  return profile
}

export const signOutApi = async () => {
  try {
    await Auth.signOut()
    removeUser()
    store.dispatch(logout())
  } catch (error: any) {
    notifyError(ErrorMessagesEnum.SignOut)
  }
}

export const userApi = { loginApi, updateProfileApi, updateMasterProfileApi, fetchProfileApi, signOutApi }
