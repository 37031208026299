import styles from './FormGroup.module.scss'

type Props = {
  children: React.ReactNode
  title: string
}

const FormGroup = ({ title, children }: Props) => {
  return (
    <div className={styles.wrapper}>
      <h3>{title}</h3>
      {children}
    </div>
  )
}

export { FormGroup }