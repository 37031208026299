import { useState, useEffect } from 'react'
import cx from 'classnames'
import { useDialogState, Dialog, DialogDisclosure } from 'reakit/Dialog'
// components
import { LoadingAnimation } from 'components'
// styles and assets
import styles from './TakeOwnershipButton.module.scss'
// types and utils
import { ICallDeepType } from 'features/calls'
import { ErrorMessagesEnum } from 'typings'
import { ErrorHandler } from 'utils'
// state
import { useAppDispatch, useAppSelector } from 'app/store'
// services
import { callsApi, setCall } from 'features/calls'

interface IProps {
  call: ICallDeepType | Record<string, never>
}

export const TakeOwnershipButton = ({ call }: IProps) => {
  // global state
  const { profile } = useAppSelector((state) => state.users)
  
  // props and utils
  const dialog = useDialogState({ modal: false })
  const dispatch = useAppDispatch()

  // local state
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [success, setSuccess] = useState(false)

  // effects
  useEffect(() => {
    if (!dialog.visible) {
      if (success) setSuccess(false)
      if (error) setError(false)
    }
  }, [dialog.visible])

  // handlers
  const takeOwnershipHandler = async (event: React.MouseEvent<HTMLButtonElement>) => {
    try {
      event.preventDefault()
      setLoading(true)
      if (error) setError(false)
      if (success) setSuccess(false)

      const updatedCall = await callsApi.takeOwnership(call?._id)

      setLoading(false)
      setSuccess(true)
      dispatch(setCall(updatedCall))
    } catch (error: any) {
      setLoading(false)
      setSuccess(false)
      setError(true)

      ErrorHandler.handleError(error, `${ErrorMessagesEnum.TransferOwnership} ${ErrorMessagesEnum.Unknown}`)
    }
  }

  // variables
  const isCurrentUserCallOwner = call?.assignedTo?._id === profile?._id

  return (
    <>
      <DialogDisclosure
        as="button"
        className="btn btn-md btn-ternary"
        {...dialog}
        data-test="takeOwnershipModalToggler"
        disabled={isCurrentUserCallOwner}
      >
        {isCurrentUserCallOwner ? 'You own this call' : 'Take Ownership'}
      </DialogDisclosure>

      <div className={cx({ 'modal-container': dialog.visible })}>
        <Dialog className={cx(`modal ${styles.modal}`, { 'has-error': error })} {...dialog} aria-label="End call modal">
          <h2 className="mb-2">Take Ownership of Call</h2>

          {success ? (
            <>
              <p>You are now the owner of this call.</p>

              <button onClick={dialog.hide} data-test="closeOwnershipModal" className="btn btn-ternary mx-auto">
                Close
              </button>
            </>
          ) : (
            <>
              <p className="text-left">Assign ownership of this call to you.</p>
              <p className="text-left">
                This will enable you to edit the call notes and disposition and remove the previous owner from editing
                rights.
              </p>

              <div className={cx('mt-2', { disabled: loading })}>
                <button
                  type="button"
                  className={cx('btn btn-md btn-success mr-2', { 'btn-loading': loading || success })}
                  onClick={takeOwnershipHandler}
                  disabled={loading || success}
                  data-test="takeOwnershipButton"
                >
                  {loading && <LoadingAnimation marginBottom="0" width="30" bgColor="#fff" />}
                  {success && 'Successfully added'}
                  {!loading && !success && 'Confirm'}
                </button>

                <button className="btn btn-md btn-ternary" onClick={dialog.hide}>
                  Cancel
                </button>
              </div>
            </>
          )}
        </Dialog>
      </div>
    </>
  )
}
