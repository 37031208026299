import { useState, useEffect } from 'react'
import cx from 'classnames'
// styles and assets
import styles from './HorizontalTabs.module.scss'

type Props = {
  children: JSX.Element[] | JSX.Element
  title?: string
  tabsCustomClass?: string
  navCustomClass?: string
  contentCustomClass?: string
  initialTab: string
  onClickCallback?: (id: string) => void
}

const HorizontalTabs = ({
  children,
  title,
  initialTab,
  tabsCustomClass,
  navCustomClass,
  contentCustomClass,
  onClickCallback,
}: Props) => {
  // props and utils
  const childrenItems = Array.isArray(children) ? children : children === undefined ? [] : [children]
  // local state
  const [activeTab, setActiveTab] = useState(initialTab)
  // effects
  useEffect(() => {
    if (initialTab) setActiveTab(initialTab)
  }, [initialTab])
  // acions
  const onClickTabItem = (tab: string) => {
    setActiveTab(tab)
  }

  return (
    <div className={cx(styles.wrapper, tabsCustomClass)}>
      <div className={cx(styles.header, navCustomClass)}>
        <h1 title={title}>{title}</h1>
        <nav>
          <ul>
            {childrenItems.map((child) => {
              const { id, title } = child.props

              return (
                <li
                  key={title}
                  className={cx(styles.link, { [styles.active]: activeTab === id })}
                  onClick={() => {
                    onClickTabItem(id)
                    if (onClickCallback) onClickCallback(id)
                  }}
                  data-test={title}
                >
                  {title}
                </li>
              )
            })}
          </ul>
        </nav>
      </div>

      <div className={contentCustomClass}>
        {childrenItems.map((child) => (
          <div key={child.props.title} className={cx(styles.tab, { [styles.active]: activeTab === child.props.id })}>
            {child}
          </div>
        ))}
      </div>
    </div>
  )
}

export { HorizontalTabs }
