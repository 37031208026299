import cx from 'classnames'
import { isEmpty } from 'lodash'
// components
import { Label, NoContentBlock } from 'components'
import { CallDetailsAction, CallDetailsBlocks, CallOwner, CallClosedDetails } from 'features/calls/components'
// styles and assets
import PapersImg from 'assets/images/papers.svg'
import styles from './CallDetails.module.scss'
// types and utils
import { ICallDeepType } from 'features/calls'
// state
import { useAppSelector } from 'app/store'

type Props = {
  call: ICallDeepType | Record<string, never>
  showButton?: true
  showHeader?: true
  hasPageNav?: true
}

const CallDetails = ({ call, showButton, showHeader, hasPageNav }: Props) => {
  // global state
  const { callStarted } = useAppSelector((state) => state.conversation.status)

  // variables
  const claimId = call?.info?.root?.claim?.claimId?.value || 'Missing'

  return (
    <aside className={styles.wrapper}>
      {isEmpty(call) ? (
        <>
          {showHeader && (
            <div className={styles.header}>
              <h2 className="text-center">No call selected</h2>
            </div>
          )}

          <NoContentBlock subTitle="Select a claim to see detailed information" imgSrc={PapersImg} />
        </>
      ) : (
        <>
          {showHeader && (
            <div className={styles.header}>
              <h2 title={claimId}>
                Claim <span>#{claimId}</span>
              </h2>

              {!callStarted && <Label text={call.state} />}
            </div>
          )}

          <div
            className={cx(styles.details, {
              [styles['without-header']]: !showHeader,
              [styles['without-button']]: !showButton,
              [styles['has-page-nav']]: hasPageNav,
            })}
            id="call-details"
          >
            {call.assignedTo && <CallOwner call={call} />}

            {call.state === 'closed' && <CallClosedDetails call={call} />}

            <CallDetailsBlocks call={call} />
          </div>

          {showButton && (
            <div className={styles.buttonContainer}>
              <CallDetailsAction call={call} />
            </div>
          )}
        </>
      )}
    </aside>
  )
}

export { CallDetails }
