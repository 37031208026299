export enum PagesPathEnum {
  queue = '/',
  calls = '/calls',
  liveCalls = '/live-calls/all',
  liveCall= '/live-calls',
  analytics = '/analytics',
  addCalls = '/data',
  admin = '/admin',
  profile = '/profile',
}
