import { EventKindEnum } from 'typings'

export interface IEventMessage {
  event: EventKindEnum
  data: {
    refItemId?: string
    messageText?: string
    messageAction?: {
      name: string
      params: { digits: string }
    }
  }
}

export interface IEventCallInfoUpdate {
  [key: string]: {
    valueOld: string
    value: string
  }
}

export interface IEvent {
  event: IEventEnum
  data: any
}

export enum IEventEnum {
  item_added = 'item_added',
  item_aborted = 'item_aborted',
  item_sent = 'item_sent',
  slots_updated = 'slots_updated',
  call_ended = 'call_ended',
  transfer_call = 'transfer_call',
  call_info_updated = 'call_info_updated',
  item_pending_user_input = 'item_pending_user_input',
  conversation_transferred = 'conversation_transferred',
}
