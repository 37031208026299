import axios from 'axios'
// types and utils
// import { getAccessToken } from '../../utils/storage'
import { getApiUrl } from './envVariables'

// Set default axios
axios.defaults.baseURL = getApiUrl()
// axios.defaults.headers.common['accessToken'] = getAccessToken()

export { axios }
