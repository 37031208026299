import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import cx from 'classnames'
import { isEmpty } from 'lodash'
// styles and assets
import ConversationIcon from 'assets/images/conversation.svg'
import styles from './CallPendingDisplay.module.scss'
// types and utils
import { ICallDeepType } from 'features/calls'
import { ErrorMessagesEnum } from 'typings'
import { getAccessToken, notifyError, axios } from 'utils'
// state
import { useAppDispatch, useAppSelector } from 'app/store'
import { updateConversationStatus } from 'features/conversation'
import { removeCall } from 'features/calls'
// services
import { signOutApi } from 'features/users'

type Props = {
  call: ICallDeepType | Record<string, never>
  startingCall: boolean
}

const CallPendingDisplay = ({ call, startingCall }: Props) => {
  // global state
  const loadingRemoveCall = useAppSelector((state) => state.calls.loading.removeCall)
  const successRemoveCall = useAppSelector((state) => state.calls.success.removeCall)
  const { _id } = useAppSelector((state) => state.calls.call)

  // props and utils
  const history = useHistory()
  const dispatch = useAppDispatch()

  // effects
  useEffect(() => {
    if (successRemoveCall) history.push('/')
  }, [successRemoveCall])

  useEffect(() => {
    if (['removed', 'invalid'].includes(call.state)) history.push('/')
    if (['closed'].includes(call.state)) history.push(`/calls/${call._id}/results`)
  }, [])

  // handlers
  const startCallHandler = () => {
    dispatch(updateConversationStatus({ startingCall: true }))

    axios
      .post(`/calls/${_id}`, {}, { headers: { Authorization: getAccessToken() } })
      .then(() => {
        history.push(`/live-calls/${_id}`)
      })
      .catch((error) => {
        dispatch(updateConversationStatus({ startingCall: false }))

        if (error?.response?.status === 401) {
          signOutApi()
          notifyError(ErrorMessagesEnum.NotAuthenticated)
        } else notifyError(ErrorMessagesEnum.StartCall)
      })
  }

  const removeCallHandler = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    dispatch(removeCall(call._id))
  }

  // variables
  const phoneNumber = call?.requestData?.to?.contact?.phoneNumber

  return (
    <div className={styles.wrapper}>
      {!isEmpty(call) && (
        <>
          <h3>
            {call.info?.root?.callInformation?.plan.value ?? <span className="text-danger">Plan info missing</span>}
          </h3>

          <div>
            <img src={ConversationIcon} alt="Conversation Icon" />
          </div>

          <h3 className="mb-2 mt-2">{phoneNumber ?? <span className="text-danger">Phone number missing</span>}</h3>

          <div>
            <button
              className={cx('btn btn-md', { 'btn-success': !startingCall, 'btn-loading': startingCall })}
              type="button"
              onClick={startCallHandler}
              disabled={startingCall}
              data-test="startCallButton"
            >
              {startingCall ? 'Starting call...' : 'Start Call'}
            </button>
          </div>

          <div className={styles['remove-call']}>
            <button
              className="btn btn-md btn-ternary-reverse"
              type="button"
              onClick={removeCallHandler}
              disabled={startingCall}
              data-test="removeCallButton"
            >
              {loadingRemoveCall ? 'Removing call...' : 'Remove from Queue'}
            </button>
          </div>
        </>
      )}
    </div>
  )
}

export { CallPendingDisplay }
