// components
import { AdminCallInfoGroup } from 'features/users/components'
// styles and assets
import styles from './AdminEditCallInfo.module.scss'
// types and utils
import { flattenInfoRootEditableProps } from 'utils'
// state
import { useAppSelector } from 'app/store'

const AdminEditCallInfo = () => {
  // global state
  const { profile } = useAppSelector((state) => state.users)
  const { root } = profile?.configuration?.templates?.call_info?._default || { root: {} }
  const groups = root?._order || []

  // early returns
  if (groups.length === 0) return <></>

  // variables
  const alreadyUsedValues = flattenInfoRootEditableProps(root)

  return (
    <div className={styles.wrapper}>
      {groups.map((group: string) => {
        const groupOb = root[group]
        const groupFriendlyName = groupOb?._friendlyName?.toUpperCase() || 'Missing friendly name'
        const groupProps = groupOb?._order || []

        return (
          <AdminCallInfoGroup
            key={group}
            title={groupFriendlyName}
            fields={groupProps}
            groupName={group}
            alreadyUsedValues={alreadyUsedValues}
          />
        )
      })}
    </div>
  )
}

export { AdminEditCallInfo }
