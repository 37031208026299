import { useState, useEffect, Dispatch, SetStateAction } from 'react'
// styles and assets
import ScrollDownIcon from 'assets/images/scroll-down-arrow.svg'
import styles from './ScrollToBottomButton.module.scss'
// types and utils
import { scrollElementToBottom } from 'utils'

interface IProps {
  isAutoScroll: boolean
  setIsAutoScroll: Dispatch<SetStateAction<boolean>>
}

export const ScrollToBottomButton = ({ isAutoScroll, setIsAutoScroll }: IProps) => {
  // local state
  const [isAutoScrollInitiated, setIsAutoScrollInitiated] = useState(false)

  // effects
  useEffect(() => {
    const chatEl = document.getElementById('chat') as HTMLDivElement

    const handleScroll = () => {
      const isScrollCloseToBottom = chatEl.scrollHeight - chatEl.scrollTop <= chatEl.clientHeight + 300

      if (isScrollCloseToBottom) {
        if (!isAutoScrollInitiated) {
          setIsAutoScrollInitiated(true)
        }
        if (!isAutoScroll) {
          setIsAutoScroll(true)
        }
      }

      if (isAutoScrollInitiated && !isScrollCloseToBottom) {
        if (isAutoScroll) {
          setIsAutoScroll(false)
        }
      }
    }

    chatEl?.addEventListener('scroll', handleScroll)
    return () => {
      chatEl?.removeEventListener('scroll', handleScroll)
    }
  }, [isAutoScrollInitiated, isAutoScroll])

  // handlers
  const handleScrollBottomClick = () => {
    scrollElementToBottom('chat')
  }

  if (isAutoScroll) return <></>

  return (
    !isAutoScroll && (
      <button className={styles.scrollBottom} onClick={handleScrollBottomClick} data-test="scrollBottom">
        <img src={ScrollDownIcon} alt="Scroll down icon" width="16" height="16" />
        Scroll to bottom
      </button>
    )
  )
}