// components
import { EndCallButton, NewCurrentClaimButton } from 'features/conversation/components'
// styles and assets
import styles from './CallTransferredActions.module.scss'
// state
import { useAppSelector } from 'app/store'

const CallTransferredActions = () => {
  const { transfer, main } = useAppSelector((state) => state.users.profile.phones)
  return (
    <div className={styles.wrapper} data-test="callTransferredActions">
      <div>
        <p>Call Transferred to {transfer || main} - Virtual Agent is offline</p>
      </div>

      <div className={styles.buttons}>
        <NewCurrentClaimButton />
        <EndCallButton />
      </div>
    </div>
  )
}

export { CallTransferredActions }
