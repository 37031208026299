import { mapValues, cloneDeep } from 'lodash'
// types and utils
import {
  ClaimExtractedInfoEnum,
  ClaimStatusEnum,
  EntityPropsEnum,
  IConversationExtractedInfo,
  IEntities,
  IEntity,
  entities,
} from 'features/calls'
import { formatCurrency } from 'utils/callInfoFormatter'
import { formatDateSeparator } from 'utils'

export const formatExtractedInfoApiResponse = (data?: IConversationExtractedInfo[]) => {
  if (!data) return

  const res = cloneDeep(entities)

  data?.forEach((item) => {
    item.entities.forEach((entity) => {
      if (entity?.entityId) {
        res[entity.entityId as EntityPropsEnum].value = entity.value
      }
    })
  })

  return res
}

export const getClaimStatusClass = (value: ClaimStatusEnum) => {
  switch (value) {
    case ClaimStatusEnum.paid:
      return 'text-success'
    case ClaimStatusEnum.denied:
      return 'text-danger'
    case ClaimStatusEnum.processing:
      return 'text-primary'
    default:
      return 'text-invalid'
  }
}

export const sanitizeDataForCSVExport = (data: IEntities) => {
  const csvData = [mapValues(data, (field: IEntity) => field.value)]

  const headers = Object.keys(data).reduce((cur, nextKey) => {
    const nextProp = data[nextKey as EntityPropsEnum]
    if (nextProp) {
      cur.push({ label: nextProp.description, key: nextKey })
    }

    return cur
  }, <{ label: string; key: string }[]>[])

  return { csvData, headers }
}

//TODO Merge this functionality with the callInfoFormatter
export const formatExtractedEntityValue = (entity?: IEntity) => {
  if (entity?.value) {
    switch (entity.type) {
      case ClaimExtractedInfoEnum.currency_amount:
        return formatCurrency(entity?.value)
      case ClaimExtractedInfoEnum.date:
        return formatDateSeparator(entity?.value)
      default:
        return entity?.value
    }
  } else {
    return '-'
  }
}
