// types and utils
import { ICallDeepType, IGetCalls, ISummaryPut, IAddCallApi } from 'features/calls'
import { isOwner, axios, getAccessToken } from 'utils'

export const getCallsApi = async (body: IGetCalls) => {
  const { data }: { data: ICallDeepType[] } = await axios.post(`/calls/query`, body, {
    headers: { Authorization: getAccessToken() },
  })

  return data
}

export const getCallApi = async (id: string) => {
  const { data } = await axios.get(`/calls/${id}`, { headers: { Authorization: getAccessToken() } })

  return data
}

export const addCallsApi = async (body: IAddCallApi) => {
  const call: ICallDeepType = await axios.post(`/calls`, body, {
    headers: { Authorization: getAccessToken() },
  })
  return call
}

export const updateCallSummaryApi = async ({ summary, id }: ISummaryPut) => {
  const body = { summary }

  await axios.put(`/calls/${id}`, body, { headers: { Authorization: getAccessToken() } })
}

export const sortByFilterApi = (body: IGetCalls, calls: ICallDeepType[]) => {
  if (body?.state?.[0] === 'closed') {
    return calls
      .filter((call) => call.endTime)
      .sort((a, b) => {
        if (a.endTime && b.endTime) {
          return -a.endTime.localeCompare(b.endTime)
        }
        return 1
      })
  } else if (body?.state?.[0] === 'active') {
    return (
      calls
        .filter((call) => call.assignedTo)
        // Show calls who started earlier first
        .sort((a, b) => a.startTime && b.startTime && -a.startTime.localeCompare(b.startTime))
        // Show owned calls first
        .sort((a, b) => {
          if (isOwner(a)) return -1
          else if (isOwner(b)) return 1
          return 0
        })
    )
  }
  return calls
}

export const getUniqueFilterValuesApi = async (body: any) => {
  const { data }: { data: any[] } = await axios.post(`/calls/query/filters`, body, {
    headers: { Authorization: getAccessToken() },
  })

  return data
}

export const takeOwnership = async (id: string) => {
  const { data }: { data: ICallDeepType } = await axios.put(
    `/calls/${id}/take-ownership`,
    {},
    { headers: { Authorization: getAccessToken() } },
  )
  return data
}

export const callsApi = {
  getCallsApi,
  getCallApi,
  addCallsApi,
  sortByFilterApi,
  updateCallSummaryApi,
  getUniqueFilterValuesApi,
  takeOwnership,
}
