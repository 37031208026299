import { useEffect, useState, Dispatch, SetStateAction } from 'react'
import { DialogStateReturn } from 'reakit/Dialog'
import cx from 'classnames'
// components
import { QueryFilterByValues } from 'components'
import { QuerySortActions } from 'features/calls/components'
// styles and assets
import styles from './QueryFilterModal.module.scss'
// types and utils
import { IGetCalls, IInfoFilter } from 'features/calls'
// state
import store, { useAppDispatch, useAppSelector } from 'app/store'
import { getCallsAndReplace, resetCalls, setPageQuery } from 'features/calls'

interface IProps {
  propPath: string
  fields: {
    value: string
    selected: boolean
  }[]
  dialog: DialogStateReturn
  loading: boolean
  error: string | boolean
  setFields: Dispatch<SetStateAction<{ value: string; selected: boolean }[]>>
  changeActiveCallHandler: (index: number) => void
}

const QueryFilterModal = ({ dialog, propPath, fields, loading, error, setFields, changeActiveCallHandler }: IProps) => {
  // global state
  const { limit } = useAppSelector((state) => state.calls.pagination)
  const { activeQueryState } = useAppSelector((state) => state.calls)

  // props and utils
  const dispatch = useAppDispatch()

  // local state
  const [disabled, setDisabled] = useState(false)

  // effects
  let getCallsPromise: any
  useEffect(() => {
    // Cancel request when component unmounts
    getCallsPromise?.abort()
  }, [])

  useEffect(() => {
    if (fields.length > 0) {
      if (fields.every((field) => !field.selected) && !disabled) {
        setDisabled(true)
      } else if (disabled) {
        setDisabled(false)
      }
    }
  }, [fields])

  // handlers
  const handleFilterClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()

    const values = [...fields].filter((field) => field.selected).map((field) => field.value)
    const isRemoveFilter = fields.length === values.length

    const filter: IInfoFilter = {
      state: activeQueryState,
      values: isRemoveFilter ? [] : values,
      path: propPath,
    }

    const body: IGetCalls = {
      startIndex: 0,
      limit,
      state: [activeQueryState],
      info: { filter },
    }

    if (store.getState().calls.calls.length > 0) dispatch(resetCalls())
    if (store.getState().calls.pagination.page !== 1) dispatch(setPageQuery(1))
    getCallsPromise = dispatch(getCallsAndReplace(body))

    dialog.hide()
  }

  return (
    <div className={`${styles.wrapper} noselect`} data-test="filtersModal">
      <QuerySortActions propPath={propPath} changeActiveCallHandler={changeActiveCallHandler} dialog={dialog} />

      <QueryFilterByValues
        fields={fields}
        propPath={propPath}
        setFields={setFields}
        loading={loading}
        error={error}
        dialogVisible={dialog.visible}
        isAllDeselected={disabled}
      />

      <div className={styles.actions}>
        <button className="btn btn-ternary-reverse" onClick={dialog.hide}>
          Cancel
        </button>
        <button
          className={cx('btn btn-success mr-2', { inactive: disabled })}
          id="apply-filter"
          data-test="applyFilter"
          onClick={handleFilterClick}
        >
          <span>OK</span>
        </button>
      </div>
    </div>
  )
}

export { QueryFilterModal }
