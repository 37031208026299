export enum EntityPropsEnum {
  claimStatus = 'claimStatus',
  billedAmount = '07a0254b-5b9c-4157-8cfb-b1bd03dfc71f',
  claimId = '503a66b4-55e5-4082-8ccd-9d7c7a98c69a',
  processedDate = 'ff9010ac-1372-420b-a942-4f5a8674a1e6',
  allowedAmount = '515db051-30ab-4ae0-8226-d410b84bee4b',
  appliedDeductible = '40e8f987-2639-4a7e-b584-82f190268afd',
  remittanceAdviceDate = 'f98d7022-b8ba-4961-b7b5-125049201c7f',
  planPaid = 'c8802885-1079-4a4f-938e-c26ca93f35bc',
  memberResponsibleAmount = 'dd8fa598-c457-4096-9500-b6ef7cfcf2c3',
  paymentEFTNumber = 'b50dc236-ac71-4192-a46d-62815984137c',
  paymentCheckNumber = 'c7394868-0adb-4787-bc4e-546123c684ab',
  paymentDate = '88f2b249-17bc-426b-a16c-d53effce9431',
  outliftAgentName = '5014aa16-9cd7-4d1e-babc-036eb91ab87c',
  callLastFourOfCallReference = 'ba7d99cc-4770-4390-82a7-9be373ff11d0',
  claimDob = '6ff8705f-4106-46ce-955a-14357a354045',
  claimTaxId = '6a0d139c-498d-46e1-94cf-fc4b7210aec6',
  claimServiceDate = 'e6b2ded2-3621-4e71-8529-fba5b54e2a37',
  claimReceivedDate = '128af8d7-5e3f-405c-85ca-71a4803a22f2',
}

export interface IConversationExtractedInfo {
  conversation_id: string
  entities: IConversationExtractedInfoEntity[]
}

export interface IConversationExtractedInfoEntity {
  entityId: string
  value: string
  confidence: number
  entityTypeId: string
  description: string
}

export type IEntities = {
  [key in EntityPropsEnum]: IEntity
}

export interface IEntity {
  type: string
  value?: string
  description: string
}

export enum ClaimStatusEnum {
  paid = 'paid',
  processing = 'processing',
  denied = 'denied',
  unknown = 'unknown',
}

export enum ClaimExtractedInfoEnum {
  currency_amount = 'currency_amount',
  string = 'string',
  date = 'date',
}
