/** Used only in development mode when REACT_APP_ENV='development' */
export const getApiUrl = () => {
  // Used to add a select box for the backendURL if REACT_APP_ENV='development'
  const urlOptions = process.env.REACT_APP_DEV_API_MAIN_URL_OPTIONS
    ? process.env.REACT_APP_DEV_API_MAIN_URL_OPTIONS.split(', ')
    : []

  const apiUrl =
    process.env.REACT_APP_ENV === 'development'
      ? sessionStorage.getItem('backendUrl') || urlOptions[0]
      : process.env.REACT_APP_API_MAIN_URL
  return apiUrl
}

/** Used only in development mode when REACT_APP_ENV='development' */
export const getWsUrl = () => {
  // urls of the websockets
  const wsOptions = process.env.REACT_APP_DEV_WS_URLS ? process.env.REACT_APP_DEV_WS_URLS.split(', ') : []

  const wsUrl =
    process.env.REACT_APP_ENV === 'development'
      ? sessionStorage.getItem('wsUrl') || wsOptions[0]
      : process.env.REACT_APP_WS_URL
  return wsUrl
}
