import { useState } from 'react'
import { useParams } from 'react-router-dom'
import cx from 'classnames'
// styles and assets
import styles from './InterventionForm.module.scss'
// types and utils
import { ConfigurationInterventionTemplateType, ErrorMessagesEnum } from 'typings'
import { CallInterventionIssueKindEnum } from 'features/calls'
import { isOwner, notifyError } from 'utils'
// state
import { useAppSelector } from 'app/store'
// services
import { updateCallSummaryApi } from 'features/calls'
import { signOutApi } from 'features/users'

type ParamTypes = {
  id: string
}

type Props = {
  type: string
  time: string
  issueKind: keyof typeof CallInterventionIssueKindEnum | undefined
  issueNote: string | undefined
  interventionId: string
  tabIndex: number
  lastTab: boolean
  itemId: string
  template: ConfigurationInterventionTemplateType
  submitCallback?: (tabIndex: number, itemId: string) => void
}

const InterventionForm = ({
  type,
  time,
  issueKind,
  issueNote,
  interventionId,
  tabIndex,
  lastTab,
  itemId,
  template,
  submitCallback,
}: Props) => {
  // global state
  const { interventions } = useAppSelector((state) => state.calls.call.summary)

  // props and utils
  const { id } = useParams<ParamTypes>()

  // local state
  const [data, setData] = useState({
    issueKind,
    issueNote,
  })
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [success, setSuccess] = useState(false)

  // handlers
  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setData((prev) => ({ ...prev, [e.target.name]: e.target.value }))
    if (error) setError(false)
    if (success) setSuccess(false)
    if (loading) setLoading(false)
  }

  const handleCheckmarkClick = (value: keyof typeof CallInterventionIssueKindEnum) => {
    setData((prev) => ({ ...prev, issueKind: value }))
    if (error) setError(false)
    if (success) setSuccess(false)
    if (loading) setLoading(false)
  }

  const handleSubmit = async (e: React.ChangeEvent<HTMLFormElement>) => {
    try {
      e.preventDefault()

      setLoading(true)
      if (error) setError(false)

      const updates = data.issueKind === CallInterventionIssueKindEnum.other ? data : { issueKind: data.issueKind }
      const updated =
        interventions?.map((intervention) => {
          return intervention._id === interventionId ? { ...intervention, ...updates } : intervention
        }) || []

      await updateCallSummaryApi({ summary: { interventions: updated }, id })
      // Go to the next intervention in the list this is not the last one
      if (!lastTab && submitCallback) submitCallback(tabIndex + 1, itemId)
      // Reset success state
      setLoading(false)
      setSuccess(true)
      setTimeout(() => setSuccess(false), 1000)
    } catch (error: any) {
      setLoading(false)
      setError(true)
      setSuccess(false)

      if (error?.response?.status === 401) {
        signOutApi()
        notifyError(ErrorMessagesEnum.NotAuthenticated)
      } else {
        notifyError(ErrorMessagesEnum.SaveCallInterventionNote)
      }
    }
  }

  // variables
  const isDisabled =
    !data.issueKind ||
    (data.issueKind === 'other' && (!data.issueNote || data.issueNote?.trim().length === 0)) ||
    (data.issueKind === issueKind && data.issueNote === issueNote)

  return (
    <div className={styles.wrapper}>
      <h3>
        {type} @ {time}
      </h3>

      <form onSubmit={handleSubmit} className={cx({ disabled: !isOwner() })}>
        {!isOwner() && (
          <p className="text-danger text-left mb-2">Only the owner of the call can submit feedback.</p>
        )}
        <h4 className="h3 mb-2">
          {template?.question || 'What cause this event? (specific question missing on the template)'}
        </h4>

        <div className={styles.options}>
          {template?.issueKindOptions?.map((option: { value: string; friendlyName: string }) => (
            <div key={option.value} className={styles['radio-container']}>
              <input
                type="radio"
                name="issueKind"
                id={`${option.value}-${interventionId}`}
                onChange={handleChange}
                value={option.value || ''}
                checked={data.issueKind === option.value}
                data-test="option"
              />
              <span
                className={styles.checkmark}
                onClick={() => handleCheckmarkClick(option.value as keyof typeof CallInterventionIssueKindEnum)}
                data-test="optionLabel"
              />
              <label htmlFor={`${option.value}-${interventionId}`}>{option?.friendlyName || ''}</label>
            </div>
          ))}
        </div>

        {data.issueKind === 'other' && (
          <div className="form-control mt-2">
            <label htmlFor="issueNote" className="form-label">
              Please tell us more
            </label>

            <textarea
              name="issueNote"
              id={`issueNote-${interventionId}`}
              className="form-input"
              value={data.issueNote || ''}
              onChange={handleChange}
            ></textarea>
          </div>
        )}

        <button
          type="submit"
          className={cx('btn btn-secondary btn-md mt-2', {
            'btn-loading': loading || success || !isOwner(),
            inactive: isDisabled,
            'has-error': error,
          })}
          data-test="submitTechnicalNote"
        >
          {loading && 'Saving note...'}
          {success && 'Successfully saved'}
          {!loading && !success && 'Save'}
        </button>
      </form>
    </div>
  )
}

export { InterventionForm }
