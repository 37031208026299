import React, { useState, memo } from 'react'
import cx from 'classnames'
// styles and assets
import { FcCheckmark } from 'react-icons/fc'
import { BsXCircleFill } from 'react-icons/bs'
import styles from './EditableInput.module.scss'

type Props = {
  name: string
  value: string
}

type CurValueType = {
  value: string
  tempValue: string
}

const EditableInput = memo(({ name, value }: Props) => {
  const [focused, setFocused] = useState(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [curValue, setCurValue] = useState<CurValueType>({
    value: value || 'not found',
    tempValue: value || 'not found',
  })

  const updateSlot = async () => {
    if (curValue.value.length > 0) {
      setLoading(true)

      //* API endpoint call
      setTimeout(() => {
        setLoading(false)
        setCurValue((prev) => ({ tempValue: prev.value, value: prev.value }))
        setFocused(false)
      }, 1000)
    }
  }

  const onFocus = () => {
    setFocused(true)
    setCurValue((prev) => ({ tempValue: prev.value, value: prev.value }))
  }

  const handleCancel = () => {
    setFocused(false)
    setCurValue((prev) => ({ tempValue: prev.value, value: prev.tempValue }))
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCurValue((prev) => ({ ...prev, value: e.target.value }))
  }

  const handleKeyDown = (e: React.KeyboardEvent<HTMLElement>) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      updateSlot()
      // Fix typescript error
      const input = e.target as HTMLElement
      input.blur()
    }
  }

  return (
    <div className={`${styles.fadeIn} ${styles.info}`} onKeyDown={handleKeyDown}>
      <label htmlFor="agent_name">{name}</label>
      <div className={cx(styles.formControl, { [styles.focused]: focused })}>
        <input
          type="text"
          id={name}
          name={name}
          value={loading ? 'Updating...' : curValue.value}
          onFocus={onFocus}
          onChange={handleChange}
          className={cx({ [styles.updating]: loading })}
        />
        {focused ? (
          !loading && (
            <>
              <button className="btn btn-xs text-danger" onClick={handleCancel}>
                <BsXCircleFill color="#EB5757" />
              </button>{' '}
            </>
          )
        ) : (
          <FcCheckmark />
        )}
      </div>
    </div>
  )
})

EditableInput.displayName = 'EditableInput'

export { EditableInput }
