// styles and assets
import styles from './CallActions.module.scss'

export const getStatusClass = (status: string) => {
  switch (status) {
    case 'prep':
      return styles.prep
    case 'ongoing':
      return styles.ongoing
    case 'disconnected':
    case 'transferFailed':
    case 'endCallFailed':
    case 'phoneNotVerified':
      return styles.disconnected
    case 'transfering':
    case 'takeOver':
      return styles.transfering
    case 'editing':
      return styles.editing
    default:
      return styles.idle
  }
}

export const getStatusColor = (status: string) => {
  switch (status) {
    case 'ongoing':
      return '#27AE60'
    case 'prep':
      return '#5E72E4'
    case 'disconnected':
    case 'transferFailed':
    case 'endCallFailed':
    case 'phoneNotVerified':
      return '#EB5757'
    case 'editing':
    case 'takeOver':
      return '#5E72E4'
    default:
      return '#32325D'
  }
}

export const getConfidenceClass = (confidence: boolean | number) => {
  if (confidence >= 75) return styles.high
  else if (confidence >= 50) return styles.medium
  else return styles.low
}

export const getStatusMessage = (status: string) => {
  const statusMessages: { [key: string]: string } = {
    idle: 'Waiting for the call to begin',
    prep: 'Prepping',
    ongoing: 'Listening',
    disconnected: 'Connection lost, try refreshing the page.',
    transfering: 'Transferring call',
    transferFailed: 'Transfer failed, resuming call...',
    endCallFailed: 'End call failed, resuming call...',
    phoneNotVerified: 'Please verify you phone to transfer calls, resuming call...',
    takeOver: 'Take over mode',
    editing: 'Waiting on user response:',
    claimChanged: 'Current claim changed',
  }

  return statusMessages[status]
}
