import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { parse } from 'papaparse'
import cx from 'classnames'
import { isEmpty } from 'lodash'
// styles and assets
import styles from './CallUploadForm.module.scss'
// types and utils
import { ErrorMessagesEnum, IError } from 'typings/errorTypes'
import { ErrorHandler, objectKeysToLowerCase } from 'utils'
import { convertCallInfoToTemplate, convertRootToUploadData } from 'features/calls'
// state
import { useAppSelector } from 'app/store'
// services
import { addCallsApi } from 'features/calls'

enum formFieldEnum {
  csvFile = 'csv-file',
  bulkProcessing = 'bulk-processing',
}
interface FormValues {
  [formFieldEnum.csvFile]: any
  [formFieldEnum.bulkProcessing]: boolean
}

const CallUploadForm = () => {
  // global state
  const { profile } = useAppSelector((state) => state.users)
  const { root } = profile?.configuration?.templates?.call_info?._default || { root: {} }

  // props and utils
  const {
    register,
    handleSubmit,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues: {
      [formFieldEnum.bulkProcessing]: true,
    },
  })
  const fields = convertRootToUploadData(root)

  // local state
  const [data, setData] = useState<{ [key: string]: string }[]>([])
  const [loading, setLoading] = useState(false)
  const [apiError, setApiError] = useState(false)
  const [success, setSuccess] = useState(false)

  // handlers
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // Reset success from previous uploads
    if (success) setSuccess(false)
    if (apiError) setApiError(false)
    if (errors?.[formFieldEnum.csvFile]) clearErrors(formFieldEnum.csvFile)

    const file = e.target.files![0]
    if (!['text/plain', 'text/csv', 'application/vnd.ms-excel'].includes(file?.type)) {
      setError(formFieldEnum.csvFile, {
        type: 'validate',
        message: 'Only valid .csv and .txt files accepted',
      })

      // Empty the uploaded file because it is not a valid type
      const target = e.target as HTMLInputElement
      target.value = ''
    } else {
      clearErrors(formFieldEnum.csvFile)

      parse(file, {
        complete: (result) => {
          if (result.data.length) {
            setData(result.data as { [key: string]: string }[])
          } else {
            setError(formFieldEnum.csvFile, {
              type: 'validate',
              message: 'The CSV file is empty',
            })
          }
        },
        skipEmptyLines: true,
        header: true,
        transformHeader: (h) => {
          return h.trim()
        },
      })
    }
  }

  const onSubmit = async (formData: FormValues) => {
    try {
      if (!isEmpty(data)) {
        setLoading(true)
        if (apiError) setApiError(false)
        if (success) setSuccess(false)

        const body = [...data].map((item) => {
          const newObject: { [key: string]: string } = {}
          const lowerCaseKeysItem = objectKeysToLowerCase(item)

          Object.keys(fields).forEach((prop: string) => {
            if (lowerCaseKeysItem?.[prop]) {
              newObject[fields?.[prop]] = lowerCaseKeysItem[prop]
            }
          })

          return {
            from: {
              bot: {
                alias: 'Marvin',
                service: profile?.configuration?.calls?.defaultBotService || 'onlu',
              },
            },
            to: {
              contact: {
                phoneNumber: newObject.phoneNumber,
              },
            },
            // If no root template is defined, just send the form data. otherwise format it
            info: root ? convertCallInfoToTemplate(newObject, root) : data,
          }
        })

        await addCallsApi({ calls: body, bulkProcessing: formData[formFieldEnum.bulkProcessing] })

        setSuccess(true)
        setLoading(false)
        setTimeout(() => {
          setSuccess(false)
          // Empty file upload input
          const target = document.getElementById(formFieldEnum.csvFile) as HTMLInputElement
          if (target) target.value = ''
          setData([])
        }, 600)
      } else {
        setError(formFieldEnum.csvFile, {
          type: 'validate',
          message: 'No file selected',
        })
      }
    } catch (error: any) {
      setLoading(false)
      setSuccess(false)
      setApiError(true)

      // Show errors in the UI only if they are related to the CSV file upload
      const uiErrors = error?.response?.data?.errors?.filter(
        (error: IError) => typeof error.source?.pointer === 'string' && error.source?.pointer?.includes('root'),
      )
      if (uiErrors.length) {
        const uniqueErrors = ErrorHandler.getUniqueErrors(uiErrors)
        ErrorHandler.notifyErrors(uniqueErrors, ErrorMessagesEnum.UploadCalls)
      } else {
        ErrorHandler.handleError(error, ErrorMessagesEnum.AddCalls)
      }
    }
  }

  return (
    <form
      className={`form ${styles.form}`}
      onSubmit={handleSubmit(onSubmit)}
      encType="multipart/form-data"
      acceptCharset="UTF-8"
    >
      <input
        data-test="fileUpload"
        type="file"
        id={formFieldEnum.csvFile}
        className="custom-file-input"
        {...register(formFieldEnum.csvFile)}
        onChange={handleChange}
        accept="text/plain,.csv,"
      />

      <fieldset data-bulk-processing-container>
        <label htmlFor={formFieldEnum.bulkProcessing}>
          <input type="checkbox" id={formFieldEnum.bulkProcessing} {...register(formFieldEnum.bulkProcessing)} />
          <span className={styles.checkmark}></span>
          Automatically call supported payors with Virtual Agent to get claims status
        </label>
      </fieldset>

      <button
        data-test="uploadFileBtn"
        type="submit"
        className={cx('btn btn-success btn-md', {
          'btn-loading': loading || success,
          'has-error': apiError || errors?.[formFieldEnum.csvFile],
        })}
        disabled={!isEmpty(errors) || isEmpty(data)}
      >
        {loading && 'Uploading...'}
        {success && 'Successfully Uploaded'}
        {!loading && !success && 'Upload'}
      </button>

      <p className="text-error text-bold">{errors?.[formFieldEnum.csvFile]?.message}</p>
    </form>
  )
}

export { CallUploadForm }
