// components
import { CallInfo } from 'features/calls/components'
// types and utils
import { ICallDeepType, IProperty, CallInfoKindEnum } from 'features/calls'
import { formatAMPM, dateDiff, dateToHHMMSS } from 'utils'

interface IProps {
  call: ICallDeepType | Record<string, never>
}

const CallClosedDetails = ({ call }: IProps) => {
  const dateConducted = new Date(call.startTime).toLocaleDateString()
  const timeStarted = formatAMPM(new Date(call.startTime))
  const timeEnded = formatAMPM(new Date(call.endTime))
  // Get the time difference in HH:MM:SS format
  const duration = dateToHHMMSS(dateDiff(new Date(call.endTime), new Date(call.startTime)))

  const firsRow = {
    friendlyName: 'Date conducted',
    kind: CallInfoKindEnum.date,
    value: dateConducted,
    valueInit: dateConducted,
  }

  const secondRow = {
    friendlyName: 'Call duration',
    kind: CallInfoKindEnum.string,
    value: duration || '',
    valueInit: duration || '',
  }

  const thirdRow = {
    friendlyName: 'Call time',
    kind: CallInfoKindEnum.string,
    value: `${timeStarted} - ${timeEnded}`,
    valueInit: `${timeStarted} - ${timeEnded}`,
  }

  const rows: IProperty[] = [firsRow, secondRow, thirdRow]

  return <CallInfo title={'CALL DETAILS'} rows={rows} callState={call.state} />
}

export { CallClosedDetails }
