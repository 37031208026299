import cx from 'classnames'
// styles and assets
import styles from './Message.module.scss'
// types and utils
import { IItem, IMessage, messageActionNamesEnum } from 'typings'
import { removeXMLTags, getParticipantName } from 'utils'

type Props = {
  message: IItem
  toPhoneNumber: string
  maxMessageBoxWidth?: string | number
  isTransferMessage: boolean
  isEdited: boolean
  isPending: boolean
  showAllMessages?: boolean
  agent: string
  plan: string
  planAgentName: string
  virtualAgentDisplay: string
}

const Message = ({
  message,
  toPhoneNumber,
  isTransferMessage,
  isEdited,
  isPending,
  showAllMessages,
  agent,
  plan,
  planAgentName,
  virtualAgentDisplay,
  maxMessageBoxWidth = 50,
}: Props) => {
  // props and utils
  let messageClassName = message.fromParticipantIndex === 1 ? '' : styles.agent
  // check if the message is from the user
  if (isTransferMessage || message.fromParticipantIndex > 1) messageClassName = styles.user
  if (isEdited) messageClassName = styles.aborted

  // variables
  /** strips tags or replace with the intent */
  const messageItem: IMessage = message?.messages?.[message?.effectiveMessageIndex]
  let { text = '' } = messageItem
  const { action } = messageItem
  let isIntent = false

  if (removeXMLTags(text) === '') {
    isIntent = true
  }
  // Hide intents on regular display mode
  if (!showAllMessages && isIntent) return <></>

  // Hide xml tags on regular display mode
  if (!showAllMessages) {
    text = removeXMLTags(text)
  }  
  
  /** bot and user messages are on the right side */
  const textClass = [0, 2].includes(message.fromParticipantIndex) ? 'text-right' : 'text-left'
  const isDTMF = action?.name === messageActionNamesEnum.sendDTMF

  return (
    <div
      className={cx(`fade-in ${styles.message} ${messageClassName}`, {
        [styles.pending]: isPending,
        [styles.dtmf]:  isDTMF
      })}
      id={message._id}
      data-test="chatMessage"
    >
      <p className={textClass}>
        {getParticipantName({
          message,
          isTransferMessage,
          plan,
          agent,
          toPhoneNumber,
          planAgentName,
          virtualAgentDisplay,
        })}
      </p>

      <div
        // Flaggable will be used in future implementation of flagging messages
        style={{ maxWidth: `${maxMessageBoxWidth}%` }}
      >
        <div className={styles.text}>{text}</div>
      </div>
    </div>
  )
}

export { Message }
