import { useState, useEffect, useRef } from 'react'
import { get } from 'lodash'
import cx from 'classnames'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
// components
import { ConfirmationModal, Loading } from 'components'
// styles and assets
import ArrowUp from 'assets/images/polygon_up.svg'
import ArrowDown from 'assets/images/polygon_down.svg'
import Close from 'assets/images/close.svg'
import DragIcon from 'assets/images/drag-icon.svg'
import Filter from 'assets/images/filter_active.svg'
import styles from './CallListColumnSelectionModal.module.scss'
// hooks
import { useModal } from 'hooks/useModal'
// typescript and utils
import { ResetSuccessEnum } from 'typings'
import { IGetCalls, IInfoFilter, IInfoSort, SortOptionsEnum } from 'features/calls'
import { queryStateColumnsFilter } from 'features/calls/utils/callUtils'
// state
import { useAppDispatch, useAppSelector } from 'app/store'
import { updateProfile, resetUserSuccess } from 'features/users'
import { getCallsAndReplace } from 'features/calls'

interface IProps {
  hideSelectionModal: () => void
}

const CallListColumnSelectionModal = ({ hideSelectionModal }: IProps) => {
  // global state
  const { callsQuery, templates } = useAppSelector(
    (state) =>
      state.users.profile?.configuration || {
        callsQuery: {},
        templates: [],
      },
  )
  const { limit } = useAppSelector((state) => state.calls.pagination)
  const { activeQueryState } = useAppSelector((state) => state.calls)
  const { filters, sorting } = useAppSelector((state) => state.users.profile?.configuration?.callsQuery)
  const loading = useAppSelector((state) => state.users.loading.updateProfile)
  const error = useAppSelector((state) => state.users.error.updateProfile)

  // props and utils
  const dispatch = useAppDispatch()
  const selectionModalRef = useRef<HTMLDivElement>(null)
  const { isShown, toggle } = useModal()

  // local state
  const [checkedColumns, setCheckedColumns] = useState(callsQuery?.columns)
  const curQueries = checkedColumns?.map((el) => el.path)
  const [localCallInfos, setLocalCallInfos] = useState(templates?.call_info?._default.root)

  // effects
  useEffect(() => {
    setCheckedColumns(callsQuery?.columns)
  }, [callsQuery?.columns])

  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      const togglerEl = document.getElementById('selection-toggler')
      if (
        selectionModalRef.current &&
        !selectionModalRef?.current?.contains(e.target as Node) &&
        e.target !== togglerEl
      ) {
        hideSelectionModal()
      }
    }
    // add when mounted
    document.addEventListener('mousedown', handleClickOutside)
    // return function to be called when unmounted
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  useEffect(() => {
    setLocalCallInfos(templates?.call_info?._default.root)
  }, [templates?.call_info?._default.root])

  // handlers
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, checked } = e.target as HTMLInputElement

    if (!checked) {
      const hasActiveFilter = filters?.[activeQueryState]?.path === id
      const hasActiveSort = sorting?.[activeQueryState]?.path === id

      if (hasActiveFilter || hasActiveSort) {
        toggle(id)
      } else {
        setCheckedColumns((prev) => {
          const index = curQueries.indexOf(id)
          const columns = [...prev.slice(0, index), ...prev.slice(index + 1)]

          dispatch(updateProfile({ configuration: { callsQuery: { columns } } }))
          setTimeout(() => dispatch(resetUserSuccess(ResetSuccessEnum.updateProfile)), 1000)

          return columns
        })
      }
    } else {
      setCheckedColumns((prev) => {
        const columns = [...prev, { path: id }]

        dispatch(updateProfile({ configuration: { callsQuery: { columns } } }))
        setTimeout(() => dispatch(resetUserSuccess(ResetSuccessEnum.updateProfile)), 1000)

        return columns
      })
    }
  }

  const onDragEnd = async (result: any) => {
    // early return
    if (!result.destination || result.destination.index === result.source.index) return

    const prevItems = [...checkedColumns]
    const columns = [...checkedColumns]
    const [reorderedItem] = columns.splice(result.source.index, 1)
    columns.splice(result.destination.index, 0, reorderedItem)
    setCheckedColumns(columns)

    await dispatch(updateProfile({ configuration: { callsQuery: { columns } } })).then((res) => {
      if (res.meta.requestStatus === 'rejected') {
        setCheckedColumns(prevItems)
      } else {
        setTimeout(() => dispatch(resetUserSuccess(ResetSuccessEnum.updateProfile)), 1)
      }
    })
  }

  const onConfirm = async (propPath: string) => {
    const index = curQueries.indexOf(propPath)
    const columns = [...checkedColumns.slice(0, index), ...checkedColumns.slice(index + 1)]
    const hasActiveFilter = filters?.[activeQueryState]?.path === propPath
    const hasActiveSort: false | keyof typeof SortOptionsEnum =
      sorting?.[activeQueryState]?.path === propPath && sorting?.[activeQueryState]?.sort

    // Remove the column from the user profile
    await dispatch(updateProfile({ configuration: { callsQuery: { columns } } }))
    setTimeout(() => dispatch(resetUserSuccess(ResetSuccessEnum.updateProfile)), 1000)

    toggle(propPath)
    hideSelectionModal()

    const body: IGetCalls = {
      startIndex: 0,
      limit,
      state: [activeQueryState],
    }

    if (hasActiveFilter) {
      const filter: IInfoFilter = {
        state: activeQueryState,
        values: [],
        path: propPath,
      }
      if (!body.info) body.info = {}
      body.info.filter = filter
    }

    if (hasActiveSort) {
      const sort: IInfoSort = {
        state: activeQueryState,
        path: propPath,
        sort: hasActiveSort,
      }
      if (!body.info) body.info = {}
      body.info.sort = sort
    }

    await dispatch(getCallsAndReplace(body))
  }

  const onCancel = (propPath: string) => toggle(propPath)

  return (
    <div className={styles.wrapper}>
      <div
        className={cx(styles.options, { disabled: loading })}
        id="selection-modal"
        ref={selectionModalRef}
        data-test="selectionModal"
      >
        {loading && <Loading width="100" marginTop="0" />}

        <h3 className="mb-2">
          Columns
          <span onClick={hideSelectionModal} className="text-danger">
            <img src={Close} alt="Close icon" width="16px" height="16px" />
          </span>
        </h3>

        {error && <p className="text-danger text-center mt-4 mb-2">{error}</p>}

        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId={`callListColumnSelectionModal`}>
            {(provided) => (
              <ul {...provided.droppableProps} ref={provided.innerRef}>
                {checkedColumns
                  // Removes fields like conductedDate, assignetTo on the Pending calls query
                  ?.filter((column) => queryStateColumnsFilter(column.path, activeQueryState))
                  .map((column, i) => {
                    const template = templates?.call_info?._default || {}
                    const groupPath = column.path.split('.').slice(0, -1)
                    const hasActiveFilter = filters?.[activeQueryState]?.path === column.path
                    // Check if has active sort
                    const hasActiveSort: false | keyof typeof SortOptionsEnum =
                      sorting?.[activeQueryState]?.path === column.path && sorting?.[activeQueryState]?.sort
                    const propFriendlyName = get(template, column.path, { friendlyName: 'Missing' }).friendlyName
                    return i === 0 ? (
                      <li key={column.path}>
                        <div className="checkbox-container">
                          <label htmlFor={column.path}>
                            <input
                              type="checkbox"
                              name={column.path}
                              id={column.path}
                              checked={curQueries.includes(column.path)}
                              /* onChange={handleChange} */
                            />
                            <span className="checkmark" />
                            {get(template, groupPath, { friendlyName: 'Missing' })._friendlyName} - {propFriendlyName}
                          </label>
                        </div>

                        <div className={styles.controls}>
                          <span className={styles['icon']}>
                            {hasActiveSort && (
                              <img
                                src={hasActiveSort === 'ascending' ? ArrowUp : ArrowDown}
                                alt="Arrow up"
                                width="18px"
                                height="18px"
                                title="This column has an active sort"
                              />
                            )}
                          </span>
                          <span className={styles['icon']}>
                            {hasActiveFilter && (
                              <img
                                src={Filter}
                                alt="Filter Icon"
                                width="18px"
                                height="18px"
                                title="This column has an active filter"
                              />
                            )}
                          </span>

                          {curQueries.includes(column.path) && (
                            <span className={cx(styles.handle, { inactive: i === 0 })} data-test="dragHandle"></span>
                          )}
                        </div>

                        {(hasActiveFilter || hasActiveSort) && (
                          <ConfirmationModal
                            onConfirm={onConfirm}
                            onCancel={onCancel}
                            propPath={column.path}
                            visible={isShown[column.path]}
                            message={`This will also remove the active filter/sort for the "${propFriendlyName}" column. Are you sure?`}
                          />
                        )}
                      </li>
                    ) : (
                      <Draggable key={column.path} draggableId={column.path} index={i}>
                        {(provided) => {
                          return (
                            <li {...provided.draggableProps} ref={provided.innerRef}>
                              <div className="checkbox-container">
                                <label htmlFor={column.path}>
                                  <input
                                    type="checkbox"
                                    name={column.path}
                                    id={column.path}
                                    checked={curQueries.includes(column.path)}
                                    onChange={handleChange}
                                  />
                                  <span className="checkmark" />
                                  {get(template, groupPath, { friendlyName: 'Missing' })._friendlyName} -{' '}
                                  {propFriendlyName}
                                </label>
                              </div>

                              <div className={styles.controls}>
                                <span className={styles['icon']}>
                                  {hasActiveSort && (
                                    <img
                                      src={hasActiveSort === 'ascending' ? ArrowUp : ArrowDown}
                                      alt="Arrow up"
                                      width="18px"
                                      height="18px"
                                      title="This column has an active sort"
                                    />
                                  )}
                                </span>
                                <span className={styles['icon']}>
                                  {hasActiveFilter && (
                                    <img
                                      src={Filter}
                                      alt="Filter Icon"
                                      width="18px"
                                      height="18px"
                                      title="This column has an active filter"
                                    />
                                  )}
                                </span>

                                {curQueries.includes(column.path) && (
                                  <span {...provided.dragHandleProps} className={styles.handle} data-test="dragHandle">
                                    <img src={DragIcon} alt="Caret down" width="20px" height="20px" />
                                  </span>
                                )}
                              </div>

                              {(hasActiveFilter || hasActiveSort) && (
                                <ConfirmationModal
                                  onConfirm={onConfirm}
                                  onCancel={onCancel}
                                  propPath={column.path}
                                  visible={isShown[column.path]}
                                  message={`This will also remove the active filter/sort for the "${propFriendlyName}" column. Are you sure?`}
                                />
                              )}
                            </li>
                          )
                        }}
                      </Draggable>
                    )
                  })}
                {provided.placeholder}
              </ul>
            )}
          </Droppable>
        </DragDropContext>

        <ul>
          {localCallInfos?._order
            ?.filter((groupName: string) => !['followUp', 'self'].includes(groupName))
            .map((groupName: string) => {
              const group = localCallInfos?.[groupName]
              const groupFriendlyName = group._friendlyName
              const curPathEls = ['root', groupName]

              return (
                localCallInfos?.[groupName]?._order
                  ?.filter((prop: string) => {
                    // Creating the full path, ex: root.claim.claimId
                    curPathEls.push(prop)
                    const curPath = curPathEls.join('.')
                    // Removing the prop name because it will be used in the .map function below
                    curPathEls.pop()
                    const propObj = group[prop]
                    // Don't show the prop if it is already checked or is hidden by the Admin
                    return !curQueries.includes(curPath) && !propObj?.isHidden
                  })
                  // Removes fields like conductedDate, assignetTo on the Pending calls query
                  .filter((prop: string) => queryStateColumnsFilter(prop, activeQueryState))
                  .map((prop: string) => {
                    const propFriendlyName = group[prop].friendlyName
                    curPathEls.push(prop)
                    const curPath = curPathEls.join('.')
                    curPathEls.pop()

                    return (
                      <li key={curPath}>
                        <div className="checkbox-container">
                          <label htmlFor={curPath}>
                            <input type="checkbox" name={curPath} id={curPath} onChange={handleChange} />
                            <span className="checkmark" />
                            {groupFriendlyName} - {propFriendlyName}
                          </label>
                        </div>
                      </li>
                    )
                  })
              )
            })}
        </ul>
      </div>
    </div>
  )
}

export { CallListColumnSelectionModal }
