import { useState, useEffect } from 'react'
import cx from 'classnames'
import { isEmpty } from 'lodash'
// components
import { CallNotesForm } from 'components'
// styles and assets
import styles from './CallSummaryForm.module.scss'
// types and utils
import { ICallDeepType } from 'features/calls'
import { ErrorMessagesEnum } from 'typings'
import { scrollElementToBottom, isOwner, notifyError } from 'utils'
// state
import { useAppSelector } from 'app/store'
// services
import {updateCallSummaryApi} from 'features/calls'
import { signOutApi } from 'features/users'

interface IProps {
  call: ICallDeepType | Record<string, never>
  submitCallback?: () => void
}

const CallSummaryForm = ({ call, submitCallback }: IProps) => {
  // global state
  const { profile } = useAppSelector((state) => state.users)

  // local state
  const [data, setData] = useState({
    disposition: call?.summary?.disposition || '',
  })
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [success, setSuccess] = useState(false)

  // effects
  useEffect(() => {
    scrollElementToBottom('notes')
  })

  // handlers
  const handleChange = (e: React.ChangeEvent<HTMLSelectElement | HTMLTextAreaElement>) => {
    setData((prev) => ({ ...prev, [e.target.id]: e.target.value }))
    if (error) setError(false)
    if (success) setSuccess(false)
    if (loading) setLoading(false)
  }

  const handleSubmit = async (e: React.ChangeEvent<HTMLFormElement>) => {
    try {
      e.preventDefault()

      if (!loading) setLoading(true)
      if (error) setError(false)

      await updateCallSummaryApi({ summary: data, id: call._id })
      if (submitCallback) submitCallback()
      setSuccess(true)
      setLoading(false)
      setTimeout(() => setSuccess(false), 500)
    } catch (error: any) {
      setError(true)
      setLoading(false)
      setSuccess(false)

      if (error?.response?.status === 401) {
        signOutApi()
        notifyError(ErrorMessagesEnum.NotAuthenticated)
      } else {
        notifyError(ErrorMessagesEnum.SaveCallDisposition)
      }
    }
  }

  // variables
  const { disposition } = data
  const dispositions = profile?.configuration?.templates?.dispositions?._default

  return (
    <div className={styles.wrapper}>
      {/* Owner warning */}
      {!isOwner() && (
        <p className="text-danger text-center mb-2">Only the owner of the call can submit feedback.</p>
      )}

      {/* Call notes from with autosave */}
      <div className={cx('form-control', styles['interventions'])}>
        <label htmlFor="notes" className="form-label">
          Call Notes
        </label>

        <CallNotesForm disabled={!isOwner()} />
      </div>

      {/* Call disposition form */}
      <form onSubmit={handleSubmit}>
        <div className="form-control">
          <label htmlFor="disposition" className="form-label">
            Call Disposition
          </label>

          <select
            name="disposition"
            id="disposition"
            className={cx('form-input', { 'has-error': error })}
            value={disposition}
            onChange={handleChange}
            data-test="callDispositionSelect"
          >
            <option value="" disabled>
              Select call disposition
            </option>
            {dispositions.map((disposition: any) => (
              <option key={disposition.value} value={disposition.value}>
                {disposition.friendlyName}
              </option>
            ))}
          </select>
        </div>

        <button
          type="submit"
          className={cx('btn btn-secondary btn-md', {
            'btn-loading': loading || success || !isOwner() || isEmpty(disposition),
          })}
          data-test="callDispositionSubmit"
        >
          {loading && 'Updating...'}
          {success && 'Successfully Updated'}
          {!loading && !success && 'Save'}
        </button>
      </form>
    </div>
  )
}

export { CallSummaryForm }
