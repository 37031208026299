// components
import { CallInfo } from 'features/calls/components'
// styles and assets
// import styles from './CallDetailsBlocks.module.scss'
// types and utils
import { ICallDeepType, IProperty } from 'features/calls'
import { useAppSelector } from 'app/store'

interface IProps {
  call: ICallDeepType | Record<string, never>
}

const CallDetailsBlocks = ({ call }: IProps) => {
  // global state
  const { profile } = useAppSelector((state) => state.users)

  // variables
  const infoGroups = profile?.configuration?.templates?.call_info?._default?.root || call?.info?.root
  const callInfoRoot = call?.info?.root

  return (
    <>
      {infoGroups?._order?.map((infoGroup: string) => {
        const groupFriendlyName = infoGroups?.[infoGroup]?._friendlyName?.toUpperCase() || 'Group name unavailable'
        const groupRows: IProperty[] = infoGroups?.[infoGroup]?._order?.map((infoName: string) => {
          const prop = callInfoRoot?.[infoGroup]?.[infoName]
          const propDetails = infoGroups[infoGroup][infoName]

          return {
            name: propDetails?.friendlyName || `Unavailable friendly name - ${infoName}`,
            isHidden: propDetails?.isHidden || false,
            friendlyName: propDetails?.friendlyName || `Unavailable friendly name - ${infoName}`,
            kind: propDetails?.kind || '',
            value: prop?.value ?? 'Unavailable',
            valueInit: prop?.valueInit ?? 'Unavailable',
            format: propDetails?.format,
          }
        })

        return <CallInfo key={infoGroup} title={groupFriendlyName} rows={groupRows} callState={call.state} />
      })}
    </>
  )
}

export { CallDetailsBlocks }
