import { useState } from 'react'
import { isEmpty, get } from 'lodash'
import cx from 'classnames'
import { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd'
// components
import { Loading } from 'components'
import { AdminEditCallInfoForm, EditCallInfoTogglers } from 'features/users/components'
// styles and assets
import DragIcon from 'assets/images/drag-icon.svg'
import ImportantIcon from 'assets/images/star-icon.svg'
// import PinnedIcon from 'assets/images/pinned-icon.svg'
import NotVisibleIcon from 'assets/images/not-visible-icon.svg'
import styles from './AdminCallInfo.module.scss'
// types and utils
import { UsedValuesEnum } from 'utils'
// state
import { useAppSelector } from 'app/store'

interface IProps {
  propPath: string
  prop: string
  groupName: string
  dragHandleProps: DraggableProvidedDragHandleProps | undefined
  alreadyUsedValues: {
    [kind in UsedValuesEnum]: string[]
  }
}

const AdminCallInfo = ({ propPath, prop, groupName, alreadyUsedValues, dragHandleProps }: IProps) => {
  // global state
  const { profile } = useAppSelector((state) => state.users)
  const { root, importantFields } = profile?.configuration?.templates?.call_info?._default || { root: {} }

  // local state
  const [loading, setLoading] = useState(false)

  // early returns
  if (isEmpty(root)) return <></>

  // variables
  const fieldData = get(root, propPath, { kind: '', friendlyName: '', slotMapping: '' })
  const isMandatory = importantFields.includes(`root.${propPath}`)
  const isHidden = !!fieldData.isHidden
  // const isPinned = Math.random() < 0.25

  return (
    <details className={cx(styles.wrapper, { [styles['not-visible']]: false })}>
      {loading && <Loading width="70" marginTop="0" />}

      <summary className={cx('text-capitalize', { [styles['not-visible']]: isHidden })}>
        <p>{fieldData.friendlyName}</p>

        <div className={styles.actions}>
          {/* {true && (
            <span onClick={togglePinned} title="Pinned">
              <img src={PinnedIcon} alt="Visible icon" width="20" height="20" />
            </span>
          )} */}

          {isHidden && (
            <img
              src={NotVisibleIcon}
              alt="Visible icon"
              width="20"
              height="20"
              title="This field will not appear in the call details"
            />
          )}

          {isMandatory && (
            <img
              src={ImportantIcon}
              alt="Visible icon"
              width="20"
              height="20"
              title="This is a mandatory field when adding a new call"
            />
          )}

          <span className={`${styles.drag} handle`} {...dragHandleProps}>
            <img src={DragIcon} alt="Drag icon" width="20" height="20" title="Click and hold to start dragging" />
          </span>
        </div>
      </summary>

      <div className={cx({ inactive: prop === 'claimId' })}>
        <EditCallInfoTogglers
          isHidden={isHidden}
          isMandatory={isMandatory}
          propPath={propPath}
          importantFields={importantFields}
          loading={loading}
          setLoading={setLoading}
        />

        <AdminEditCallInfoForm
          field={prop}
          fieldData={fieldData}
          groupName={groupName}
          loading={loading}
          setLoading={setLoading}
          alreadyUsedValues={alreadyUsedValues}
        />
      </div>
    </details>
  )
}

export { AdminCallInfo }
