import { Fragment, useState, useRef, useEffect } from 'react'
import { get } from 'lodash'
import cx from 'classnames'
import { useHistory } from 'react-router-dom'
import { isEmpty } from 'lodash'
import { FaRobot } from 'react-icons/fa'
// components
import { Loading, NoContentBlock, Error, Label, ErrorMessage } from 'components'
// styles and assets
import ConversationIcon from 'assets/images/conversation.svg'
// types and utils
import { ICallDeepType, IGetCalls, queryStateColumnsFilter } from 'features/calls'
import { ErrorMessagesEnum } from 'typings'
import { formatValue } from 'utils'
// state
import store from 'app/store'
import { useAppDispatch, useAppSelector } from 'app/store'
import { getCallsAndAppend, incrementPageQuery } from 'features/calls'
// hooks
import useIntersectionObserver from 'hooks/useIntersectionObserver'

interface IProps {
  calls: ICallDeepType[]
  active: number
  initialCallsLoaded: boolean
  changeActiveCallHandler: (index: number) => void
}

const CallListTbody = ({ calls, active, initialCallsLoaded, changeActiveCallHandler }: IProps) => {
  // global state
  // On initial page render getCallsAndReplace is called
  const initialCallsError = useAppSelector<boolean | string>((state) => state.calls.error.getCallsAndReplace)
  const initialCallsLoading = useAppSelector<boolean>((state) => state.calls.loading.getCallsAndReplace)
  // Infinite scroll calls getCallsAndAppend when needed to load more
  const loadingMore = useAppSelector<boolean>((state) => state.calls.loading.getCallsAndAppend)
  const error = useAppSelector<boolean | string>((state) => state.calls.error.getCallsAndAppend)
  const { limit, hasMore } = useAppSelector((state) => state.calls.pagination)
  const { activeQueryState } = useAppSelector((state) => state.calls)
  const { callsQuery, templates } = useAppSelector(
    (state) =>
      state.users.profile?.configuration || {
        callsQuery: {},
      },
  )
  const callInfoTemplate = templates?.call_info?._default

  // local state
  const [loading, setLoading] = useState(false)

  // props and utils
  const dispatch = useAppDispatch()
  const loadMoreRef = useRef<HTMLDivElement | null>(null)
  const rootRef = useRef<HTMLDivElement | null>(null)
  const history = useHistory()
  // Used to abort the api request on page change
  let getCallsAndAppendPromise: any

  const loadMoreHandler = async () => {
    const { page, hasMore } = store.getState().calls.pagination

    if (!loading && hasMore && initialCallsLoaded) {
      setLoading(true)
      const body: IGetCalls = {
        startIndex: page * limit,
        limit,
        state: [activeQueryState],
      }

      getCallsAndAppendPromise = dispatch(getCallsAndAppend(body))

      await getCallsAndAppendPromise
      dispatch(incrementPageQuery())
      setLoading(false)
    }
  }

  useIntersectionObserver({
    target: loadMoreRef,
    onIntersect: loadMoreHandler,
    enabled: store.getState().calls.pagination.hasMore && !loading && !error,
  })

  useEffect(() => {
    return () => getCallsAndAppendPromise?.abort()
  })

  // variables
  const hasNoCalls = isEmpty(calls) && !loadingMore && !initialCallsLoading && !error && !initialCallsError

  // early returns
  if (initialCallsError)
    return <Error title={ErrorMessagesEnum.SomethingWentWrong} message={ErrorMessagesEnum.ReportToAdministrator} />

  if (initialCallsLoading)
    return <Loading message={`Fetching ${activeQueryState} calls from the databse`} width="100" />

  if (hasNoCalls)
    return (
      <NoContentBlock
        title={`No calls are currently ${activeQueryState}`}
        subTitle={activeQueryState === 'active' ? 'Select a call from the Pending tab to start a call' : ''}
        imgSrc={ConversationIcon}
        className="top-50"
      />
    )

  return (
    <div className="tbody" id="calllist-tbody" data-test="callListTbody" ref={rootRef}>
      <>
        {calls.map((call, index) => {
          const isAutomatedCall = call?.assignedTo?.alias === 'virtual.agent' || call?.results
          const isNotClosedCalls = activeQueryState !== 'closed'

          return (
            <div
              key={call._id}
              className={cx('tr', activeQueryState, {
                selected: call._id === calls[active]?._id,
              })}
              onClick={() => changeActiveCallHandler(index)}
              onDoubleClick={() => (isAutomatedCall && isNotClosedCalls ? null : history.push(`/calls/${call._id}`))}
              data-test={call._id}
            >
              <div className={`td ${call.state}`}>
                <Label text={call.state} />

                {isAutomatedCall && <FaRobot className="ml-2" color="#1c528988" size="2rem" />}
              </div>

              {callsQuery?.columns
                ?.filter((column) => queryStateColumnsFilter(column.path, activeQueryState))
                .map((column) => {
                  const value = get(call.info, column.path, { value: '' }).value
                  const callInfoObj = { ...get(callInfoTemplate, column.path, {}) }
                  callInfoObj.value = value

                  return (
                    <Fragment key={`${column.path}-${index}`}>
                      <div className="td" title={formatValue(callInfoObj)}>
                        {formatValue(callInfoObj)}
                      </div>
                    </Fragment>
                  )
                })}
              <div className="td" />
            </div>
          )
        })}
        <div ref={loadMoreRef} />
      </>

      <div className="callList-bottom-container">
        {!error ? (
          hasMore ? (
            <Loading key="loading-bar-0" width="50" marginBottom="15px" marginTop="15px" />
          ) : (
            <p className="mt-4 mb-4 text-center">No more entries</p>
          )
        ) : (
          <ErrorMessage message={`${ErrorMessagesEnum.SomethingWentWrong}. ${ErrorMessagesEnum.FetchMoreCalls} `} />
        )}
      </div>
    </div>
  )
}

export { CallListTbody }
