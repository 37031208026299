import { Auth } from 'aws-amplify'
import styled from 'styled-components'
import { ToastContainer } from 'react-toastify'
// components
import Router from './pages/Router'
// styles and assets
import '@szhsin/react-menu/dist/index.css'
import '@szhsin/react-menu/dist/transitions/slide.css'
import 'react-toastify/dist/ReactToastify.min.css'
// types and utils
import { awsConfig } from './services/aws/amplify'

Auth.configure(awsConfig)

const App = () => {
  return (
    <Wrapper>
      <ToastContainer
        position="bottom-right"
        autoClose={6000}
        // hideProgressBar={true}
        theme="colored"
        closeOnClick
        pauseOnFocusLoss={false}
        pauseOnHover
      />
      <Router />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  margin: 0 auto;
`
export default App
