import { useState, useEffect } from 'react'
import { isEmpty } from 'lodash'
// components
import { VerticalTabs, NoContentBlock } from 'components'
import { InterventionForm } from 'features/calls/components'
// styles and assets
import CheckmarkImage from 'assets/images/checkmark-big.svg'
import styles from './Interventions.module.scss'
// types and utils
import { IIntervention } from 'features/calls'
import { dateDiff, dateToHHMMSS } from 'utils'
// state
import { useAppSelector, useAppDispatch } from 'app/store'
import { updateConversationStatus } from 'features/conversation'

interface IProps {
  isActive: boolean
}

const Interventions = ({ isActive }: IProps) => {
  // props and utils
  const dispatch = useAppDispatch()
  // global state
  const { scrollChatTo } = useAppSelector((state) => state.conversation.status)
  const { templates } = useAppSelector((state) => state.users.profile?.configuration)
  const template = templates?.interventions?._default || []

  const { call } = useAppSelector((state) => state.calls)
  const { interventions } = call.summary

  // local state
  const [tab, setTab] = useState(0)

  // effects
  // Scroll to first intervention on initial visit of Technical notes
  useEffect(() => {
    if (isActive && !scrollChatTo)
      dispatch(updateConversationStatus({ scrollChatTo: interventions?.[0]?.conversationItemId || '' }))
  }, [isActive, scrollChatTo])

  // handlers
  const scrollToItem = (id: string) => {
    dispatch(updateConversationStatus({ scrollChatTo: id }))
  }

  const handleSubmit = (tabIndex: number, itemId: string) => {
    setTab(tabIndex)
    scrollToItem(itemId)
  }

  return (
    <div className={styles.wrapper}>
      {isEmpty(interventions) ? (
        <div className={styles['no-interventions']}>
          <NoContentBlock title={'No interventions on this call'} imgSrc={CheckmarkImage} />
        </div>
      ) : (
        <VerticalTabs
          title="Call Interventions"
          tabsCustomClass="border-right"
          initialTab={interventions[tab] ? interventions[tab]._id : ''}
          callback={scrollToItem}
        >
          {interventions.map((intervention: IIntervention, index: number) => {
            const { _id, kind, issueKind, issueNote, conversationItemId, interventionAt, friendlyName } = intervention

            /** Get the time difference in HH:MM:SS format */
            const time =
              dateToHHMMSS(
                dateDiff(new Date(interventionAt), new Date(call.startTime || call.conversations[0].startTime)),
              ) || ''

            return (
              <div
                key={_id}
                title={friendlyName}
                data-time={time}
                id={_id}
                data-intervention={intervention}
                data-item-id={conversationItemId}
                className={styles['no-interventions']}
              >
                {/* Some events don't have a template */}
                {template[kind] ? (
                  <InterventionForm
                    type={friendlyName}
                    time={time}
                    issueKind={issueKind}
                    issueNote={issueNote}
                    interventionId={_id}
                    itemId={interventions[index + 1] ? interventions[index + 1].conversationItemId : ''}
                    tabIndex={index}
                    lastTab={index === interventions.length - 1}
                    template={template[kind]}
                    submitCallback={handleSubmit}
                  />
                ) : (
                  <NoContentBlock title={'No feedback required for this intervention'} imgSrc={CheckmarkImage} />
                )}
              </div>
            )
          })}
        </VerticalTabs>
      )}
    </div>
  )
}

export { Interventions }
