import { useState, useEffect } from 'react'
import cx from 'classnames'
// components
import { FormGroup } from 'components'
// styles and assets
import styles from './CallForm.module.scss'
// types and utils
import { ErrorMessagesEnum } from 'typings'
import { ErrorHandler } from 'utils'
import { scrollIntoView } from 'utils/dom'
import { convertCallInfoToTemplate } from 'features/calls/utils/callUtils'
// state
import { useAppSelector } from 'app/store'
// services
import { addCallsApi, convertRootToFormData } from 'features/calls'

const CallForm = () => {
  // global state
  const { profile } = useAppSelector((state) => state.users)
  const { root, importantFields } = profile?.configuration?.templates?.call_info?._default || { root: {} }
  //TODO Add types on the profile to remove any
  const groups = root?._order
  const formData: { [key: string]: string } = convertRootToFormData(root)

  // formData.phoneNumber = '1'
  // formData.planName = 'XX'
  // formData.claimId = 'XX'
  // formData.memberId = 'XX'
  // formData.dateSubmitted = '2021-10-07'
  // formData.amount = '15234.333'
  // formData.claimDateOfService = '2021-10-14'
  // formData.provider = 'provider'
  // formData.plan = 'plan'

  // local state
  const [data, setData] = useState(formData)
  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(false)
  const [errors, setErrors] = useState<{ [key: string]: string }>({})
  const hasErrors = Object.keys(errors).length > 0

  // effects
  useEffect(() => {
    if (hasErrors) scrollIntoView(Object.keys(errors)[0], 'center')
  }, [hasErrors])

  // handlers
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (success) setSuccess(false)
    if (errors[e.target.id]) delete errors[e.target.id]

    setData((prev) => ({ ...prev, [e.target.id]: e.target.value }))
  }

  const handleSubmit = async (e: React.ChangeEvent<HTMLFormElement>) => {
    try {
      e.preventDefault()
      setLoading(true)
      if (success) setSuccess(false)
      if (hasErrors) setErrors({})

      const body = {
        from: {
          bot: {
            alias: 'Marvin',
            service: profile?.configuration?.calls?.defaultBotService || 'ivr_nav',
          },
        },
        to: {
          contact: {
            phoneNumber: data.phoneNumber,
          },
        },
        info: root ? convertCallInfoToTemplate(data, root) : data,
      }

      await addCallsApi({ calls: [body], bulkProcessing: false })

      setSuccess(true)
      setTimeout(() => {
        setSuccess(false)
      }, 600)
    } catch (error: any) {
      if (error?.response?.data?.errors?.length > 0) {
        const { hasFieldErrors, fieldsWithErrors } = ErrorHandler.getFieldErrors(
          Object.keys(formData),
          error.response.data.errors,
        )
        hasFieldErrors ? setErrors(fieldsWithErrors) : ErrorHandler.handleError(error, ErrorMessagesEnum.BadRequest)
      } else {
        ErrorHandler.handleError(error, ErrorMessagesEnum.AddCall)
      }
    }
    setLoading(false)
  }

  return (
    <form className={styles.form} onSubmit={handleSubmit}>
      {groups.map((group: string) => {
        const groupFriendlyName = root?.[group]?._friendlyName?.toUpperCase() || 'Group name unavailable'
        const groupPropsOrder = root?.[group]?._order

        return (
          <FormGroup key={group} title={groupFriendlyName}>
            {groupPropsOrder.map((prop: string) => {
              const { friendlyName, kind, format } = root?.[group]?.[prop] || {
                friendlyName: 'Unavailable friendly name',
                kind: 'string',
              }
              const propPath = `root.${group}.${prop}`
              const isMandatory = importantFields.includes(propPath)

              const extraProps: { [prop: string]: string } = {}
              if (kind === 'number') {
                extraProps['min'] = '0'
                extraProps['step'] = '0.001'
              }

              // Date call uploaded and Conducted time have dateWithTime format, we want to hide this fields when adding a new call
              return ['dateWithTime'].includes(format) ? null : (
                <div className="form-control" key={`${group}-${prop}`}>
                  <label className="form-label" htmlFor={prop}>
                    {friendlyName} {isMandatory && '*'}
                  </label>
                  <input
                    required={isMandatory}
                    onChange={handleChange}
                    className={cx('form-input', { 'has-error': errors?.[prop] })}
                    type={kind}
                    name={prop}
                    id={prop}
                    value={data[prop]}
                    {...extraProps}
                  />
                  {errors?.[prop] && <p className="text-danger">{errors?.[prop]}</p>}
                </div>
              )
            })}
          </FormGroup>
        )
      })}

      <button
        type="submit"
        className={cx('btn btn-success', { 'btn-loading': loading || success })}
        disabled={loading || success}
        data-test="call-form-button"
      >
        {loading && 'Adding call...'}
        {success && 'Successfully added'}
        {!loading && !success && 'Add to Call Queue'}
      </button>
    </form>
  )
}

export { CallForm }
