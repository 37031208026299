import cx from 'classnames'
import { useDialogState, Dialog, DialogDisclosure } from 'reakit/Dialog'
// styles and assets
import TranferCallIcon from 'assets/images/transfer_call.svg'
// types and utils
import { EventKindEnum, IEventLocalState, ErrorMessagesEnum } from 'typings'
import { createLocalStateEvent, notifyError, axios, getAccessToken } from 'utils'
// state
import { useAppDispatch, useAppSelector } from 'app/store'
import { updateConversationStatus, addItem } from 'features/conversation/conversationSlice'
// services
import { signOutApi } from 'features/users'

const TransferCallButton = () => {
  // props and utils
  const dialog = useDialogState({ modal: false })
  const dispatch = useAppDispatch()

  // global state
  const { lastAgentItem } = useAppSelector((state) => state.conversation)
  const { _id: id } = useAppSelector((state) => state.calls.call)
  const { transferingCall } = useAppSelector((state) => state.conversation.status)
  const { username: userName } = useAppSelector((state) => state.users.user)
  const { transfer: transferPhoneNumber, main: mainPhoneNumber } = useAppSelector((state) => state.users.profile.phones)

  // handlers
  const transferCallHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()

    const body = {
      command: 'replace_participant',
      participantIndex: 2,
      participant: {
        user: {
          // phoneNumber: '12137849686', // This is our DEMO bot phone number
          alias: userName,
          phoneNumber: transferPhoneNumber || mainPhoneNumber,
        },
      },
    }

    dispatch(updateConversationStatus({ transferingCall: true, conversationStatus: 'transfering' }))
    dialog.hide()

    axios
      .post(`/calls/${id}`, body, { headers: { Authorization: getAccessToken() } })
      .then(() => {
        // Updating the conversation state
        dispatch(
          updateConversationStatus({
            transferingCall: false,
            callTransferred: true,
            conversationStatus: 'ongoing',
            // callTakenOver should be false to fix bug where not showing the incoming messages with participantIndex 0 after a call is transferred
            callTakenOver: false,
          }),
        )

        const localStateEvent: IEventLocalState = {
          kind: EventKindEnum.transfer_call,
          refItem: lastAgentItem?._id || '',
        }

        dispatch(addItem(createLocalStateEvent(localStateEvent)))
      })
      .catch((error: any) => {
        if (error?.response?.status === 401) {
          signOutApi()
          notifyError(ErrorMessagesEnum.NotAuthenticated)
        } else {
          notifyError(ErrorMessagesEnum.TransferCall)
          dispatch(
            updateConversationStatus({
              transferingCall: false,
              callTransferred: false,
              conversationStatus: 'transferFailed',
            }),
          )
        }

        setTimeout(() => dispatch(updateConversationStatus({ conversationStatus: 'ongoing' })), 2500)
      })
  }

  return (
    <>
      <DialogDisclosure
        className={cx('btn btn-danger btn-just-icon', { inactive: transferingCall })}
        id="transfer-voice"
        as="button"
        data-test="transferCallToggler"
        {...dialog}
      >
        <img src={TranferCallIcon} alt="Transfer call icon" width="22" height="15" />
      </DialogDisclosure>

      <div className={cx({ 'modal-container': dialog.visible })}>
        <Dialog className="modal" {...dialog} aria-label="Transfer call modal">
          <p>Are you sure you want to transfer the call?</p>

          <div>
            <button
              className="btn btn-danger mr-2"
              onClick={transferCallHandler}
              id="transfer-voice"
              data-test="transferCallButton"
            >
              <span>Transfer Voice</span>
            </button>
            <button className="btn btn-secondary" onClick={dialog.hide}>
              Cancel
            </button>
          </div>
        </Dialog>
      </div>
    </>
  )
}

export { TransferCallButton }
