import { useState } from 'react'
import cx from 'classnames'
import Toggle from 'react-toggle'
import { pull } from 'lodash'
// styles and assets
import 'react-toggle/style.css'
import styles from './EditCallInfoTogglers.module.scss'
// types and utils
import { IUpdateCallInfo, IUpdateCallInfoTypes, ErrorMessagesEnum } from 'typings'
import { ErrorHandler } from 'utils'
// state
import { useAppDispatch } from 'app/store'
import { setProfile } from 'features/users'
// services
import { updateMasterProfileApi } from 'features/users'

interface IProps {
  isHidden: boolean
  isMandatory: boolean
  propPath: string
  importantFields: string[]
  loading: boolean
  setLoading: React.Dispatch<React.SetStateAction<boolean>>
}

const HIDDEN_MESSAGE = 'Field must be unhidden to make it mandatory'
const MANDATORY_MESSAGE = 'Mandatory toggle must be disabled in order to proceed'

const EditCallInfoTogglers = ({ isHidden, isMandatory, propPath, importantFields, loading, setLoading }: IProps) => {
  // props and utils
  const dispatch = useAppDispatch()

  // local state
  const [error, setError] = useState(false)
  const [isHiddenChecked, setIsHiddenChecked] = useState(isHidden)
  const [hasHiddenMessage, setHasHiddenMessage] = useState<false | string>(false)
  const [isMandatoryChecked, setIsMandatoryChecked] = useState(isMandatory)
  const [hasMandatoryMessage, setHasMandatoryMessage] = useState<false | string>(false)

  const toggleIsHidden = async () => {
    if (isMandatoryChecked) {
      setHasMandatoryMessage(MANDATORY_MESSAGE)
    } else {
      try {
        if (hasMandatoryMessage) setHasMandatoryMessage(false)
        if (hasHiddenMessage) setHasHiddenMessage(false)

        const updateCallInfo: IUpdateCallInfo = {
          types: [IUpdateCallInfoTypes.values],
          path: propPath,
          updates: { isHidden: !isHiddenChecked },
        }
        if (error) setError(false)
        setLoading(true)
        const updatedProfile = await updateMasterProfileApi({ updateCallInfo })

        dispatch(setProfile(updatedProfile))
        setIsHiddenChecked((prev) => !prev)
      } catch (error: any) {
        setError(true)
        ErrorHandler.handleError(error, ErrorMessagesEnum.Unknown)
      }
      setLoading(false)
    }
  }

  const handleMandatoryChange = async () => {
    if (isHidden) {
      setHasHiddenMessage(HIDDEN_MESSAGE)
    } else {
      try {
        if (hasHiddenMessage) setHasHiddenMessage(false)
        if (hasMandatoryMessage) setHasMandatoryMessage(false)

        const newImportantFields = [...importantFields]
        const fullPath = `root.${propPath}`

        if (isMandatoryChecked) {
          pull(newImportantFields, fullPath)
        } else {
          newImportantFields.push(fullPath)
        }

        const updateCallInfo: IUpdateCallInfo = {
          types: [IUpdateCallInfoTypes.changeImportantFields],
          path: propPath,
          updates: { importantFields: newImportantFields },
        }

        if (error) setError(false)
        setLoading(true)
        const updatedProfile = await updateMasterProfileApi({ updateCallInfo })

        dispatch(setProfile(updatedProfile))
        setIsMandatoryChecked((prev) => !prev)
      } catch (error: any) {
        setError(true)
        ErrorHandler.handleError(error, ErrorMessagesEnum.Unknown)
      }
      setLoading(false)
    }
  }

  return (
    <div className={cx(styles.wrapper, { inactive: loading })}>
      <label>
        <span>Hide call info from display</span>
        <Toggle checked={isHiddenChecked} icons={{ unchecked: null }} onChange={toggleIsHidden} />
      </label>

      {hasMandatoryMessage && <p className="text-danger text-center mb-2">{hasMandatoryMessage}</p>}

      <label>
        <span>Make field mandatory when adding new calls</span>
        <Toggle checked={isMandatoryChecked} icons={{ unchecked: null }} onChange={handleMandatoryChange} />
      </label>

      {hasHiddenMessage && <p className="text-danger text-center">{hasHiddenMessage}</p>}
    </div>
  )
}

export { EditCallInfoTogglers }
