import { useState, useEffect } from 'react'
import cx from 'classnames'
// styles and assets
import styles from './VerticalTabs.module.scss'

type Props = {
  children: JSX.Element[] | JSX.Element
  title: string
  tabsCustomClass: string
  navCustomClass?: string
  contentCustomClass?: string
  initialTab: string
  callback?: (id: string) => void
}

const VerticalTabs = ({
  children,
  title,
  initialTab,
  tabsCustomClass,
  navCustomClass,
  contentCustomClass,
  callback,
}: Props) => {
  // props and utils
  const childrenItems = Array.isArray(children) ? children : children === undefined ? [] : [children]
  // local state
  const [activeTab, setActiveTab] = useState(initialTab)
  // effects
  useEffect(() => {
    if (initialTab) {
      setActiveTab(initialTab)
    }
  }, [initialTab])

  return (
    <div className={cx(styles.wrapper, tabsCustomClass)}>
      <div className={cx(styles.header, navCustomClass)}>
        <h2>{title}</h2>
        <nav>
          <ul>
            {childrenItems.map((child) => {
              const { id, title, 'data-time': time, 'data-item-id': itemId } = child.props

              return (
                <li
                  key={id}
                  className={cx(styles.link, { [styles.active]: activeTab === id })}
                  onClick={() => {
                    setActiveTab(id)
                    if (callback) callback(itemId)
                  }}
                  data-test={`verticalTab`}
                >
                  <span>{time}</span>
                  {title}
                </li>
              )
            })}
          </ul>
        </nav>
      </div>

      <div className={cx(styles.body, contentCustomClass)}>
        {childrenItems.map((child) => (
          <div
            key={`${child.props.title}-${child.props.id}`}
            className={cx(styles.tab, { [styles.active]: activeTab === child.props.id })}
          >
            {child}
          </div>
        ))}
      </div>
    </div>
  )
}

export { VerticalTabs }
