import { useEffect, useState } from 'react'
// components
import { Message, ChatEvent } from 'components'
import { ScrollToBottomButton } from 'components'
// styles and assets
import styles from './ChatDisplay.module.scss'
// types and utils
import {
  IConversationDeep,
  IConversationLocalState,
  IItem,
  IParticipant,
  ILocalStateParticipant,
  ConversationParticipantRoleEnum,
  ConversationParticipantPartyTypeEnum,
  ConversationItemStateEnum,
} from 'typings'
import { scrollElementToBottom, scrollIntoView, getVirtualAgentDisplay } from 'utils'
// state
import { useAppDispatch, useAppSelector } from 'app/store'
import { setConversations, resetConversation } from 'features/conversation'

type Props = {
  maxMessageBoxWidth?: number | string
  showAllMessages?: boolean
}

const ChatDisplay = ({ showAllMessages, maxMessageBoxWidth = 50 }: Props) => {
  // props and utils
  const dispatch = useAppDispatch()

  // global state
  const call = useAppSelector((state) => state.calls.call)
  const conversations = useAppSelector((state) => state.conversation.conversations)
  const { scrollChatTo, callStarted } = useAppSelector((state) => state.conversation.status)
  const toPhoneNumber = call?.requestData?.to?.contact?.phoneNumber || 'Missing info'
  const plan = call?.info?.root?.callInformation?.plan.value || 'Missing info'
  const planAgentName = call?.info?.root?.callInformation?.planAgentName?.value

  // local state
  const [isAutoScroll, setIsAutoScroll] = useState(true)

  // effects
  // Update conversations feature with the current conversations
  useEffect(() => {
    dispatch(setConversations(call.conversations))

    return () => {
      dispatch(resetConversation())
    }
  }, [])

  // When intervention links are clicked scroll the chat to the message with the id of scrollChatTo value
  useEffect(() => {
    if (scrollChatTo) {
      scrollIntoView(scrollChatTo as string)
    } else if (isAutoScroll) {
      scrollElementToBottom('chat')
    }
  })

  // variables
  const agent = `${call?.assignedTo?.firstName || 'First name'} ${call?.assignedTo?.lastName || 'Last name'}`

  return (
    <div className={styles.chatDisplay} id="chat" data-test="chatDisplay">
      {conversations.map((conversation: IConversationDeep | IConversationLocalState, i: number) => {
        const { startTime: callStartTime } = call
        const participants = conversation.participants.map(
          (participant: IParticipant | ILocalStateParticipant) => participant?.service?.provider?.name || '',
        )
        const virtualAgentDisplay = getVirtualAgentDisplay(participants)

        return (
          <div key={conversation._id}>
            {conversation.items.map((item: IItem) => {
              if (item.kind === 'message') {
                if (!showAllMessages) {
                  /** Hide aborted messages */
                  if (item.state === 'aborted') return
                  /** Hide pending message when the conversation ends (using callStarted because is much faster then the API request to end the call)  */
                  if (!callStarted && ['pending'].includes(item.state)) return
                  /** Hide messages that have an action property or an empty text */
                  if (
                    item.messages[item.effectiveMessageIndex].action &&
                    item.messages[item.effectiveMessageIndex].action?.name !== 'senddtmf'
                  )
                    return
                }

                const isTransferMessage =
                  conversation.participants.some(
                    (participant: IParticipant | ILocalStateParticipant) =>
                      participant.partyType === ConversationParticipantPartyTypeEnum.user &&
                      participant.role === ConversationParticipantRoleEnum.agent,
                  ) && item.fromParticipantIndex !== 1
                const isEdited = [ConversationItemStateEnum.ignored, ConversationItemStateEnum.replaced].includes(
                  item.state,
                )

                return (
                  <Message
                    key={item._id}
                    message={item}
                    toPhoneNumber={toPhoneNumber}
                    plan={plan}
                    planAgentName={planAgentName}
                    virtualAgentDisplay={virtualAgentDisplay}
                    maxMessageBoxWidth={maxMessageBoxWidth}
                    isTransferMessage={isTransferMessage}
                    showAllMessages={showAllMessages}
                    isEdited={isEdited}
                    isPending={call.state === 'closed' && item.state === 'pending'}
                    agent={agent}
                  />
                )
              } else if (item.kind === 'event') {
                return <ChatEvent key={item._id} item={item} callStartTime={callStartTime} />
              }
            })}

            {/* If this was the last conversation and the call is not currently getting transferred then the call ended */}
            {i === conversations.length - 1 && !callStarted && (
              <p className={styles.issueLabel}>
                <span>Call Ended</span>
              </p>
            )}
          </div>
        )
      })}

      <ScrollToBottomButton isAutoScroll={isAutoScroll} setIsAutoScroll={setIsAutoScroll} />
    </div>
  )
}

export { ChatDisplay }
