import { IUserConfiguration, UserRoleEnum } from './userTypes'

export interface IItem {
  state: ConversationItemStateEnum
  kind: 'event' | 'message'
  effectiveMessageIndex: number
  _id: string
  fromParticipantIndex: number
  messages: IMessage[]
  createdAt: string
  updatedAt: string
  metadata?: any
  event?: {
    kind: keyof typeof EventKindEnum
    refItem?: string
    data?: {
      messageText: string
    }
  }
}

export interface IConversationDeep {
  kind: keyof typeof ConversationKindEnum
  state: keyof typeof ConversationStateEnum
  mode?: keyof typeof ConversationModeEnum
  items: IItem[]
  _id: string
  call: string
  startTime: string
  endTime?: string
  participants: IParticipant[]
  createdAt: string
  updatedAt: string
}

export interface IConversationLocalState {
  items: IItem[]
  mode?: string
  _id: string
  endTime: string
  startTime: string
  state: keyof typeof ConversationStateEnum
  localState?: boolean
  participants: ILocalStateParticipant[]
}

export interface ILocalStateParticipant {
  service?: {
    provider: {
      name: string
      kind: string
    }
  }
  partyType: string
  role: keyof typeof ConversationParticipantRoleEnum
}
export interface IEventLocalState {
  kind: keyof typeof EventKindEnum
  refItem: string
}

export interface IMessage {
  confidence: number
  isSSML: boolean
  isSanitized: boolean
  isReducted: boolean
  createdAt: string
  text: string
  action?: IMessageAction
}

export interface IMessageActionParams {
  digits: string
  [param: string]: string
}

export interface IMessageAction {
  name: messageActionNamesEnum
  params: IMessageActionParams
}

export interface IParticipant {
  party: IUserConfig | IContact | IBot
  partyType: keyof typeof ConversationParticipantPartyTypeEnum
  permissions?: string
  isInitiator: boolean
  role: keyof typeof ConversationParticipantRoleEnum
  service: {
    provider: {
      name: string
      kind: keyof typeof ServiceProviderKindEnum
    }
  }
}

export interface IBot {
  alias: string
  voice?: string
  language?: string
}

export interface IContact {
  alias: string

  firstName?: string
  middleName?: string
  lastName?: string
  companyName?: string

  emails?: { work: string }
  phones?: { main: string; transfer?: string }
}

export interface IUserConfig {
  alias: string

  firstName: string
  middleName?: string
  lastName: string

  emails?: { work: string }
  phones?: { main: string; transfer?: string }

  passwordHash?: string

  role: UserRoleEnum
  configuration?: IUserConfiguration

  isDefault?: boolean
}

export enum messageActionNamesEnum {
  gather = 'gather', // aka gatherdtmf; params: none
  gatherDTMF = 'gatherdtmf', // params: none
  sendDTMF = 'senddtmf', // params: digits="123"
  listen = 'listen', // params: none
  transfer = 'transfer', // aka handoff; params: phone="15556667777" | service="conifer"
  handOff = 'handoff', // params: phone="15556667777" | service="conifer"
  hangUp = 'hangup', // params: none
  setVoice = 'setvoice', // params: voice="Matthew"
}

export enum ServiceProviderKindEnum {
  nlu = 'nlu',
  cpaas = 'cpaas',
}

export enum BotServiceEnum {
  dev = 'dev',
  ivr_nav = 'ivr_nav',
  conifer = 'conifer',
  onlu = 'onlu',
}

export enum ConversationItemStateEnum {
  pending = 'pending',
  approved = 'approved',
  sent = 'sent',
  aborted = 'aborted',
  replaced = 'replaced',
  ignored = 'ignored',
  transfer_call = 'transfer_call',
}

export enum ConversationModeEnum {
  flow = 'flow',
  flow_with_delay = 'flow_with_delay',
  confidence_threshold = 'confidence_threshold',
  require_approval = 'require_approval',
  override = 'override',
}

export enum ConversationStateEnum {
  pending = 'pending',
  active = 'active',
  closed = 'closed',
}

export enum ConversationKindEnum {
  audio = 'audio',
  video = 'video',
  text = 'text',
  api = 'api',
}

export enum ItemStateEnum {
  pending = 'pending',
  sent = 'sent',
  aborted = 'aborted',
  replaced = 'replaced',
  ignored = 'ignored',
  transfer_call = 'transfer_call',
}

export enum EventKindEnum {
  approve_message = 'approve_message',
  ignore_message = 'ignore_message',
  replace_message = 'replace_message',
  new_message = 'new_message',
  editing_message = 'editing_message',
  take_over = 'take_over',
  transfer_back = 'transfer_back',
  transfer_call = 'transfer_call',
  set_confidence_threshold = 'set_confidence_threshold',
  set_message_delay = 'set_message_delay',
  change_execution_mode = 'change_execution_mode',
  new_current_claim = 'new_current_claim',
  call_info_updated = 'call_info_updated',
  switch_va = 'switch_va',
}

export enum ConversationParticipantPartyTypeEnum {
  user = 'user',
  bot = 'bot',
  contact = 'contact',
}

export enum ConversationParticipantRoleEnum {
  target = 'target',
  agent = 'agent',
  supervisor = 'supervisor',
  listener = 'listener',
}
