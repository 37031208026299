import { useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { isEmpty } from 'lodash'
// components
import { Loading, Error, Layout } from 'components'
import { CallDetails, CallDisplay } from 'features/calls/components'
// styles and assets
import styles from './Call.module.scss'
// types and utils
import { ErrorMessagesEnum } from 'typings'
// state
import { useAppDispatch, useAppSelector } from 'app/store'
import { getCall } from 'features/calls'

type ParamTypes = {
  id: string
}

const Call = () => {
  // global state
  const loading = useAppSelector<boolean>((state) => state.calls.loading.getCall)
  const error = useAppSelector((state) => state.calls.error.getCall)
  const { call } = useAppSelector((state) => state.calls)

  // props and utils
  const { id } = useParams<ParamTypes>()
  const history = useHistory()
  const dispatch = useAppDispatch()

  // effects
  useEffect(() => {
    dispatch(getCall(id))
  }, [])

  useEffect(() => {
    if (!isEmpty(call)) {
      if (call.state === 'invalid') history.push(`/`)
      if (call.state === 'closed') history.push(`/calls/${call._id}/results`)
    }
  }, [call])

  return (
    <Layout>
      <div className={styles.wrapper}>
        <CallDetails call={call} showHeader={true} />

        <div className="p-relative">
          {error ? (
            <Error
              title={ErrorMessagesEnum.CallNotFound}
              message={ErrorMessagesEnum.ReportToAdministrator}
              btnLink="/"
              btnClassName="btn btn-ternary-reverse"
              btnText="Go to Call Queue"
            />
          ) : (
            <section>
              {loading && <Loading width="100" message="Fetching call details from the databse" />}

              {!isEmpty(call) && <CallDisplay call={call} />}
            </section>
          )}
        </div>
      </div>
    </Layout>
  )
}

export { Call }
